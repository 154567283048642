import {
  GameObject,
  Realm,
  System,
  Vector3,
  vector3DistanceToSqred,
} from "realms-engine";
import { SlaveInteractableGameObject } from "../gameObjects/interactable/slaveInteractable.gameObject";
import { PlayerSystem } from "./playerSystem";
import { InteractableData, MinimalInteractableData } from "mmo-common";

export class InteractableSystem extends System<{
  onCurrentInteractableChange: MinimalInteractableData | null;
}> {
  private interactableGameObjects = new Set<SlaveInteractableGameObject>();
  private lastMinimalInteractable: null | MinimalInteractableData = null;

  constructor() {
    super([SlaveInteractableGameObject], [PlayerSystem]);
  }

  onTargetGameObjectExit(gameObject: GameObject<{}>): void {
    if (gameObject instanceof SlaveInteractableGameObject) {
      this.interactableGameObjects.delete(gameObject);
    }
  }

  step(delta: number, gameObject: GameObject<{}>, realm: Realm): void {
    if (gameObject instanceof SlaveInteractableGameObject) {
      const player = realm.systems.get(PlayerSystem).getPlayerGameObject();
      if (!player) {
        return;
      }

      const isInteractable = player.inRangeInteractableIds.includes(
        gameObject.getState().interactableId
      );

      if (isInteractable) {
        this.interactableGameObjects.add(gameObject);
      } else {
        this.interactableGameObjects.delete(gameObject);
      }
    }
  }

  processAfterStep(delta: number, realm: Realm): void {
    const player = realm.systems.get(PlayerSystem).getPlayerGameObject();
    if (!player) {
      return;
    }
    this.calculateCurrentInteractable(player.getPosition());
  }

  private updateInteractable(interactable: InteractableData | null) {
    let minimalInteractable = null;
    if (interactable) {
      const { position, ...rest } = interactable;
      minimalInteractable = rest;
    }
    if (
      (interactable === null && this.lastMinimalInteractable !== null) ||
      (interactable !== null && this.lastMinimalInteractable === null)
    ) {
      this.dispatch("onCurrentInteractableChange", minimalInteractable);
    } else if (
      interactable !== null &&
      this.lastMinimalInteractable !== null &&
      JSON.stringify(minimalInteractable) !==
        JSON.stringify(this.lastMinimalInteractable)
    ) {
      this.dispatch("onCurrentInteractableChange", minimalInteractable);
    }
    this.lastMinimalInteractable = minimalInteractable;
  }

  private calculateCurrentInteractable(playerPosition: Vector3) {
    // No interactable
    if (this.interactableGameObjects.size === 0) {
      this.updateInteractable(null);
      return;
    }

    // Get closes one
    let closestDist: null | number = null;
    let closestInteractable: null | SlaveInteractableGameObject = null;
    for (const interactable of this.interactableGameObjects) {
      const distance = vector3DistanceToSqred(
        interactable.getState().position,
        playerPosition
      );
      if (closestDist === null || distance < closestDist) {
        closestDist = distance;
        closestInteractable = interactable;
      }
    }

    if (closestInteractable) {
      this.updateInteractable(closestInteractable.getState());
    }
  }
}
