export const YellowSlashEffectsAssetMap = {
  slashEffectYellowArcLarge_frame_1: {
    fileName: "/assets/effects/slash_effects/yellow/1/Classic_01.png",
  },
  slashEffectYellowArcLarge_frame_2: {
    fileName: "/assets/effects/slash_effects/yellow/1/Classic_02.png",
  },
  slashEffectYellowArcLarge_frame_3: {
    fileName: "/assets/effects/slash_effects/yellow/1/Classic_03.png",
  },
  slashEffectYellowArcLarge_frame_4: {
    fileName: "/assets/effects/slash_effects/yellow/1/Classic_04.png",
  },
  slashEffectYellowArcLarge_frame_5: {
    fileName: "/assets/effects/slash_effects/yellow/1/Classic_05.png",
  },
  slashEffectYellowArcLarge_frame_6: {
    fileName: "/assets/effects/slash_effects/yellow/1/Classic_06.png",
  },
  slashEffectYellowArcMedium_frame_1: {
    fileName: "/assets/effects/slash_effects/yellow/2/Classic_07.png",
  },
  slashEffectYellowArcMedium_frame_2: {
    fileName: "/assets/effects/slash_effects/yellow/2/Classic_08.png",
  },
  slashEffectYellowArcMedium_frame_3: {
    fileName: "/assets/effects/slash_effects/yellow/2/Classic_09.png",
  },
  slashEffectYellowArcMedium_frame_4: {
    fileName: "/assets/effects/slash_effects/yellow/2/Classic_10.png",
  },
  slashEffectYellowArcMedium_frame_5: {
    fileName: "/assets/effects/slash_effects/yellow/2/Classic_11.png",
  },
  slashEffectYellowArcMedium_frame_6: {
    fileName: "/assets/effects/slash_effects/yellow/2/Classic_12.png",
  },
  slashEffectYellowArcSmall_frame_1: {
    fileName: "/assets/effects/slash_effects/yellow/3/Classic_13.png",
  },
  slashEffectYellowArcSmall_frame_2: {
    fileName: "/assets/effects/slash_effects/yellow/3/Classic_14.png",
  },
  slashEffectYellowArcSmall_frame_3: {
    fileName: "/assets/effects/slash_effects/yellow/3/Classic_15.png",
  },
  slashEffectYellowArcSmall_frame_4: {
    fileName: "/assets/effects/slash_effects/yellow/3/Classic_16.png",
  },
  slashEffectYellowArcSmall_frame_5: {
    fileName: "/assets/effects/slash_effects/yellow/3/Classic_17.png",
  },
  slashEffectYellowArcSmall_frame_6: {
    fileName: "/assets/effects/slash_effects/yellow/3/Classic_18.png",
  },
  slashEffectYellowBolt_frame_1: {
    fileName: "/assets/effects/slash_effects/yellow/4/Classic_19.png",
  },
  slashEffectYellowBolt_frame_2: {
    fileName: "/assets/effects/slash_effects/yellow/4/Classic_20.png",
  },
  slashEffectYellowBolt_frame_3: {
    fileName: "/assets/effects/slash_effects/yellow/4/Classic_21.png",
  },
  slashEffectYellowBolt_frame_4: {
    fileName: "/assets/effects/slash_effects/yellow/4/Classic_22.png",
  },
  slashEffectYellowBolt_frame_5: {
    fileName: "/assets/effects/slash_effects/yellow/4/Classic_23.png",
  },
  slashEffectYellowBolt_frame_6: {
    fileName: "/assets/effects/slash_effects/yellow/4/Classic_24.png",
  },
  slashEffectYellowStraight_frame_1: {
    fileName: "/assets/effects/slash_effects/yellow/5/Classic_25.png",
  },
  slashEffectYellowStraight_frame_2: {
    fileName: "/assets/effects/slash_effects/yellow/5/Classic_26.png",
  },
  slashEffectYellowStraight_frame_3: {
    fileName: "/assets/effects/slash_effects/yellow/5/Classic_27.png",
  },
  slashEffectYellowStraight_frame_4: {
    fileName: "/assets/effects/slash_effects/yellow/5/Classic_28.png",
  },
  slashEffectYellowStraight_frame_5: {
    fileName: "/assets/effects/slash_effects/yellow/5/Classic_29.png",
  },
  slashEffectYellowStraight_frame_6: {
    fileName: "/assets/effects/slash_effects/yellow/5/Classic_30.png",
  },
} as const;
