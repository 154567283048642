"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBehaviorSubject = void 0;
var react_1 = require("react");
var rxjs_1 = require("rxjs");
/*
 * Subscribes to the given behavior subject
 * and converts it to a state
 */
var useBehaviorSubject = function (behaviorSubject) {
    var _a = __read((0, react_1.useState)(behaviorSubject.getValue()), 2), state = _a[0], setState = _a[1];
    var setBehaviorSubjectState = (0, react_1.useCallback)(function (state) {
        behaviorSubject.next(state);
    }, [behaviorSubject]);
    (0, react_1.useEffect)(function () {
        var subscription = behaviorSubject
            .pipe((0, rxjs_1.tap)(function (state) { return setState(function () { return state; }); }))
            .subscribe();
        return function () {
            subscription.unsubscribe();
        };
    }, [behaviorSubject]);
    return [state, setBehaviorSubjectState, behaviorSubject];
};
exports.useBehaviorSubject = useBehaviorSubject;
