import { createContext, useContext, useEffect, useState } from "react";

const PointerLockContext = createContext({
  isLocked: false,
});

/**
 * Provides pointer lock information
 */
export const PointerLockProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isLocked, setIsLocked] = useState(false);

  // Change lock
  useEffect(() => {
    const onLockChange = async () => {
      setIsLocked(window.document.pointerLockElement === window.document.body);
    };
    window.document.addEventListener("pointerlockchange", onLockChange);
    return () => {
      window.document.removeEventListener("pointerlockchange", onLockChange);
    };
  }, [setIsLocked]);

  return (
    <PointerLockContext.Provider value={{ isLocked }}>
      {children}
    </PointerLockContext.Provider>
  );
};

/**
 * Gets the pointer lock values
 */
export const usePointerLock = () => {
  return useContext(PointerLockContext);
};
