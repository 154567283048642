import { MMOAssetManager } from "../../../config";
import { TextureFrame } from "../../utils/animatedPlaneTexture.gameObject";
import { SlashEffectColor, SlashEffectType } from "./SlashEffect.gameObject";

/**
 * Gets the slash effect frames
 */
export const getSlashEffectFrames = ({
  color,
  type,
  frameDuration,
}: {
  color: SlashEffectColor;
  type: SlashEffectType;
  frameDuration: number;
}): TextureFrame[] => {
  switch (color) {
    case SlashEffectColor.Yellow: {
      return getYellowSlashEffectFrames({ frameDuration, type });
    }
    case SlashEffectColor.Purple: {
      return getPurpleSlashEffectFrames({ frameDuration, type });
    }
    case SlashEffectColor.Blue: {
      return getBlueSlashEffectFrames({ frameDuration, type });
    }
    case SlashEffectColor.Orange: {
      return getOrangeSlashEffectFrames({ frameDuration, type });
    }
  }
};

const getYellowSlashEffectFrames = ({
  type,
  frameDuration,
}: {
  type: SlashEffectType;
  frameDuration: number;
}): TextureFrame[] => {
  switch (type) {
    case SlashEffectType.ArcLarge: {
      return [
        MMOAssetManager.getTexture("slashEffectYellowArcLarge_frame_1"),
        MMOAssetManager.getTexture("slashEffectYellowArcLarge_frame_2"),
        MMOAssetManager.getTexture("slashEffectYellowArcLarge_frame_3"),
        MMOAssetManager.getTexture("slashEffectYellowArcLarge_frame_4"),
        MMOAssetManager.getTexture("slashEffectYellowArcLarge_frame_5"),
        MMOAssetManager.getTexture("slashEffectYellowArcLarge_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
    case SlashEffectType.ArcMedium: {
      return [
        MMOAssetManager.getTexture("slashEffectYellowArcMedium_frame_1"),
        MMOAssetManager.getTexture("slashEffectYellowArcMedium_frame_2"),
        MMOAssetManager.getTexture("slashEffectYellowArcMedium_frame_3"),
        MMOAssetManager.getTexture("slashEffectYellowArcMedium_frame_4"),
        MMOAssetManager.getTexture("slashEffectYellowArcMedium_frame_5"),
        MMOAssetManager.getTexture("slashEffectYellowArcMedium_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
    case SlashEffectType.ArcSmall: {
      return [
        MMOAssetManager.getTexture("slashEffectYellowArcSmall_frame_1"),
        MMOAssetManager.getTexture("slashEffectYellowArcSmall_frame_2"),
        MMOAssetManager.getTexture("slashEffectYellowArcSmall_frame_3"),
        MMOAssetManager.getTexture("slashEffectYellowArcSmall_frame_4"),
        MMOAssetManager.getTexture("slashEffectYellowArcSmall_frame_5"),
        MMOAssetManager.getTexture("slashEffectYellowArcSmall_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
    case SlashEffectType.Bolt: {
      return [
        MMOAssetManager.getTexture("slashEffectYellowBolt_frame_1"),
        MMOAssetManager.getTexture("slashEffectYellowBolt_frame_2"),
        MMOAssetManager.getTexture("slashEffectYellowBolt_frame_3"),
        MMOAssetManager.getTexture("slashEffectYellowBolt_frame_4"),
        MMOAssetManager.getTexture("slashEffectYellowBolt_frame_5"),
        MMOAssetManager.getTexture("slashEffectYellowBolt_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
    case SlashEffectType.Straight: {
      return [
        MMOAssetManager.getTexture("slashEffectYellowStraight_frame_1"),
        MMOAssetManager.getTexture("slashEffectYellowStraight_frame_2"),
        MMOAssetManager.getTexture("slashEffectYellowStraight_frame_3"),
        MMOAssetManager.getTexture("slashEffectYellowStraight_frame_4"),
        MMOAssetManager.getTexture("slashEffectYellowStraight_frame_5"),
        MMOAssetManager.getTexture("slashEffectYellowStraight_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
  }
};

const getPurpleSlashEffectFrames = ({
  type,
  frameDuration,
}: {
  type: SlashEffectType;
  frameDuration: number;
}): TextureFrame[] => {
  switch (type) {
    case SlashEffectType.ArcLarge: {
      return [
        MMOAssetManager.getTexture("slashEffectPurpleArcLarge_frame_1"),
        MMOAssetManager.getTexture("slashEffectPurpleArcLarge_frame_2"),
        MMOAssetManager.getTexture("slashEffectPurpleArcLarge_frame_3"),
        MMOAssetManager.getTexture("slashEffectPurpleArcLarge_frame_4"),
        MMOAssetManager.getTexture("slashEffectPurpleArcLarge_frame_5"),
        MMOAssetManager.getTexture("slashEffectPurpleArcLarge_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
    case SlashEffectType.ArcMedium: {
      return [
        MMOAssetManager.getTexture("slashEffectPurpleArcMedium_frame_1"),
        MMOAssetManager.getTexture("slashEffectPurpleArcMedium_frame_2"),
        MMOAssetManager.getTexture("slashEffectPurpleArcMedium_frame_3"),
        MMOAssetManager.getTexture("slashEffectPurpleArcMedium_frame_4"),
        MMOAssetManager.getTexture("slashEffectPurpleArcMedium_frame_5"),
        MMOAssetManager.getTexture("slashEffectPurpleArcMedium_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
    case SlashEffectType.ArcSmall: {
      return [
        MMOAssetManager.getTexture("slashEffectPurpleArcSmall_frame_1"),
        MMOAssetManager.getTexture("slashEffectPurpleArcSmall_frame_2"),
        MMOAssetManager.getTexture("slashEffectPurpleArcSmall_frame_3"),
        MMOAssetManager.getTexture("slashEffectPurpleArcSmall_frame_4"),
        MMOAssetManager.getTexture("slashEffectPurpleArcSmall_frame_5"),
        MMOAssetManager.getTexture("slashEffectPurpleArcSmall_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
    case SlashEffectType.Bolt: {
      return [
        MMOAssetManager.getTexture("slashEffectPurpleBolt_frame_1"),
        MMOAssetManager.getTexture("slashEffectPurpleBolt_frame_2"),
        MMOAssetManager.getTexture("slashEffectPurpleBolt_frame_3"),
        MMOAssetManager.getTexture("slashEffectPurpleBolt_frame_4"),
        MMOAssetManager.getTexture("slashEffectPurpleBolt_frame_5"),
        MMOAssetManager.getTexture("slashEffectPurpleBolt_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
    case SlashEffectType.Straight: {
      return [
        MMOAssetManager.getTexture("slashEffectPurpleStraight_frame_1"),
        MMOAssetManager.getTexture("slashEffectPurpleStraight_frame_2"),
        MMOAssetManager.getTexture("slashEffectPurpleStraight_frame_3"),
        MMOAssetManager.getTexture("slashEffectPurpleStraight_frame_4"),
        MMOAssetManager.getTexture("slashEffectPurpleStraight_frame_5"),
        MMOAssetManager.getTexture("slashEffectPurpleStraight_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
  }
};

const getBlueSlashEffectFrames = ({
  type,
  frameDuration,
}: {
  type: SlashEffectType;
  frameDuration: number;
}): TextureFrame[] => {
  switch (type) {
    case SlashEffectType.ArcLarge: {
      return [
        MMOAssetManager.getTexture("slashEffectBlueArcLarge_frame_1"),
        MMOAssetManager.getTexture("slashEffectBlueArcLarge_frame_2"),
        MMOAssetManager.getTexture("slashEffectBlueArcLarge_frame_3"),
        MMOAssetManager.getTexture("slashEffectBlueArcLarge_frame_4"),
        MMOAssetManager.getTexture("slashEffectBlueArcLarge_frame_5"),
        MMOAssetManager.getTexture("slashEffectBlueArcLarge_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
    case SlashEffectType.ArcMedium: {
      return [
        MMOAssetManager.getTexture("slashEffectBlueArcMedium_frame_1"),
        MMOAssetManager.getTexture("slashEffectBlueArcMedium_frame_2"),
        MMOAssetManager.getTexture("slashEffectBlueArcMedium_frame_3"),
        MMOAssetManager.getTexture("slashEffectBlueArcMedium_frame_4"),
        MMOAssetManager.getTexture("slashEffectBlueArcMedium_frame_5"),
        MMOAssetManager.getTexture("slashEffectBlueArcMedium_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
    case SlashEffectType.ArcSmall: {
      return [
        MMOAssetManager.getTexture("slashEffectBlueArcSmall_frame_1"),
        MMOAssetManager.getTexture("slashEffectBlueArcSmall_frame_2"),
        MMOAssetManager.getTexture("slashEffectBlueArcSmall_frame_3"),
        MMOAssetManager.getTexture("slashEffectBlueArcSmall_frame_4"),
        MMOAssetManager.getTexture("slashEffectBlueArcSmall_frame_5"),
        MMOAssetManager.getTexture("slashEffectBlueArcSmall_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
    case SlashEffectType.Bolt: {
      return [
        MMOAssetManager.getTexture("slashEffectBlueBolt_frame_1"),
        MMOAssetManager.getTexture("slashEffectBlueBolt_frame_2"),
        MMOAssetManager.getTexture("slashEffectBlueBolt_frame_3"),
        MMOAssetManager.getTexture("slashEffectBlueBolt_frame_4"),
        MMOAssetManager.getTexture("slashEffectBlueBolt_frame_5"),
        MMOAssetManager.getTexture("slashEffectBlueBolt_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
    case SlashEffectType.Straight: {
      return [
        MMOAssetManager.getTexture("slashEffectBlueStraight_frame_1"),
        MMOAssetManager.getTexture("slashEffectBlueStraight_frame_2"),
        MMOAssetManager.getTexture("slashEffectBlueStraight_frame_3"),
        MMOAssetManager.getTexture("slashEffectBlueStraight_frame_4"),
        MMOAssetManager.getTexture("slashEffectBlueStraight_frame_5"),
        MMOAssetManager.getTexture("slashEffectBlueStraight_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
  }
};

const getOrangeSlashEffectFrames = ({
  type,
  frameDuration,
}: {
  type: SlashEffectType;
  frameDuration: number;
}): TextureFrame[] => {
  switch (type) {
    case SlashEffectType.ArcLarge: {
      return [
        MMOAssetManager.getTexture("slashEffectOrangeArcLarge_frame_1"),
        MMOAssetManager.getTexture("slashEffectOrangeArcLarge_frame_2"),
        MMOAssetManager.getTexture("slashEffectOrangeArcLarge_frame_3"),
        MMOAssetManager.getTexture("slashEffectOrangeArcLarge_frame_4"),
        MMOAssetManager.getTexture("slashEffectOrangeArcLarge_frame_5"),
        MMOAssetManager.getTexture("slashEffectOrangeArcLarge_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
    case SlashEffectType.ArcMedium: {
      return [
        MMOAssetManager.getTexture("slashEffectOrangeArcMedium_frame_1"),
        MMOAssetManager.getTexture("slashEffectOrangeArcMedium_frame_2"),
        MMOAssetManager.getTexture("slashEffectOrangeArcMedium_frame_3"),
        MMOAssetManager.getTexture("slashEffectOrangeArcMedium_frame_4"),
        MMOAssetManager.getTexture("slashEffectOrangeArcMedium_frame_5"),
        MMOAssetManager.getTexture("slashEffectOrangeArcMedium_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
    case SlashEffectType.ArcSmall: {
      return [
        MMOAssetManager.getTexture("slashEffectOrangeArcSmall_frame_1"),
        MMOAssetManager.getTexture("slashEffectOrangeArcSmall_frame_2"),
        MMOAssetManager.getTexture("slashEffectOrangeArcSmall_frame_3"),
        MMOAssetManager.getTexture("slashEffectOrangeArcSmall_frame_4"),
        MMOAssetManager.getTexture("slashEffectOrangeArcSmall_frame_5"),
        MMOAssetManager.getTexture("slashEffectOrangeArcSmall_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
    case SlashEffectType.Bolt: {
      return [
        MMOAssetManager.getTexture("slashEffectOrangeBolt_frame_1"),
        MMOAssetManager.getTexture("slashEffectOrangeBolt_frame_2"),
        MMOAssetManager.getTexture("slashEffectOrangeBolt_frame_3"),
        MMOAssetManager.getTexture("slashEffectOrangeBolt_frame_4"),
        MMOAssetManager.getTexture("slashEffectOrangeBolt_frame_5"),
        MMOAssetManager.getTexture("slashEffectOrangeBolt_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
    case SlashEffectType.Straight: {
      return [
        MMOAssetManager.getTexture("slashEffectOrangeStraight_frame_1"),
        MMOAssetManager.getTexture("slashEffectOrangeStraight_frame_2"),
        MMOAssetManager.getTexture("slashEffectOrangeStraight_frame_3"),
        MMOAssetManager.getTexture("slashEffectOrangeStraight_frame_4"),
        MMOAssetManager.getTexture("slashEffectOrangeStraight_frame_5"),
        MMOAssetManager.getTexture("slashEffectOrangeStraight_frame_6"),
      ].map((texture) => ({
        texture,
        duration: frameDuration,
      }));
    }
  }
};
