import { Box, Heading } from "@chakra-ui/react";
import { Overlay } from "mmo-ui";
import { useEffect } from "react";
import { usePointerLock } from "../../utils/PointerLockProvider";
import { useObservable } from "react-rxjs-toolkit";
import { useService } from "../../services";
import { requestPointerLock } from "../../utils/requestPointerLock";

export const PointerLock = () => {
  const { isLocked } = usePointerLock();
  const dialogueService = useService("dialogue");
  const chatService = useService("chat");
  const [inDialogue] = useObservable(dialogueService.inDialogue$, true);
  const [chatIsFocused] = useObservable(chatService.chatIsFocused$, true);

  useEffect(() => {
    if (inDialogue) {
      return;
    }
    if (chatIsFocused) {
      return;
    }
    window.document.body.addEventListener("click", requestPointerLock);
    return () => {
      window.document.body.removeEventListener("click", requestPointerLock);
    };
  }, [chatIsFocused, inDialogue]);

  return isLocked || inDialogue ? null : (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      position={"fixed"}
      top={0}
      bottom={0}
      left={0}
      right={0}
      zIndex={10}
      animation={
        "fade-in-fwd 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both"
      }
    >
      <Heading color={"gray.50"} zIndex={1}>
        Click to control
      </Heading>
      <Overlay />
    </Box>
  );
};
