var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ChakraProvider } from "@chakra-ui/react";
import { AuthProvider } from "realmsbase-sdk";
import { RealmsbaseTheme } from "./theme";
export var AppProvider = function (_a) {
    var children = _a.children;
    return (_jsx(ChakraProvider, __assign({ theme: RealmsbaseTheme }, { children: _jsx(AuthProvider, { children: children }) })));
};
