"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RealmsEngineClient = void 0;
var slaveStateManager_1 = require("./slaveStateManager");
var uuid_1 = require("uuid");
var realms_utils_1 = require("realms-utils");
var RealmsEngineClient = /** @class */ (function () {
    function RealmsEngineClient(connection, syncSchemaMap, options) {
        if (options === void 0) { options = {}; }
        var _this = this;
        this.connection = connection;
        this.options = options;
        // Interval in ms
        this.PING_INTERVAL = 1000;
        this.eventManager = new realms_utils_1.EventManager();
        this.addListener = this.eventManager.addListener;
        this.removeListener = this.eventManager.removeListener;
        this.once = this.eventManager.once;
        // Callbacks to call on connect
        this.onConnectCallbacks = [];
        // this.connection = new UWebsocketsServerConnection(url);
        this.slaveStateManager = new slaveStateManager_1.SlaveStateManager(syncSchemaMap);
        this.connection.addListener("MasterNetworkRealmState", function (state) {
            _this.slaveStateManager.pushState(state);
        });
        this.connection.addListener("MasterNetworkRealmStateChange", function (change) {
            _this.slaveStateManager.pushChange(change);
        });
        this.connection.addAnyListener(function (event, data) {
            if (_this.options.onServerEvent) {
                _this.options.onServerEvent(event, data);
            }
        });
        this.connection.addListener("connect", function () {
            var e_1, _a;
            try {
                for (var _b = __values(_this.onConnectCallbacks), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var callback = _c.value;
                    callback();
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            _this.onConnectCallbacks = [];
        });
    }
    RealmsEngineClient.prototype.getCurrentState = function () {
        return this.slaveStateManager.getCurrentState();
    };
    RealmsEngineClient.prototype.isConnected = function () {
        return this.connection.connected;
    };
    RealmsEngineClient.prototype.getConnection = function () {
        return this.connection;
    };
    /**
     * Makes a remote procedure call
     */
    RealmsEngineClient.prototype.rpc = function (procedure, params) {
        var _this = this;
        if (!this.isConnected()) {
            return new Promise(function (resolve) {
                _this.onConnectCallbacks.push(function () { return __awaiter(_this, void 0, void 0, function () {
                    var response;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, this.rpc(procedure, params)];
                            case 1:
                                response = _a.sent();
                                resolve(response);
                                return [2 /*return*/];
                        }
                    });
                }); });
            });
        }
        var id = (0, uuid_1.v4)();
        // Promise to handle response
        var response = new Promise(function (resolve) {
            var listener = function (e) {
                if (e.procedure === procedure && e.ackId === id) {
                    _this.connection.removeListener("RemoteProcedureCallResponse", listener);
                    resolve(e.response);
                }
            };
            _this.connection.addListener("RemoteProcedureCallResponse", listener);
        });
        // Make procedure call
        this.connection.emit("RemoteProcedureCallInput", { id: id, procedure: procedure, params: params });
        return response;
    };
    RealmsEngineClient.prototype.connect = function (auth) {
        if (auth === void 0) { auth = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var ping_1;
            var _this = this;
            return __generator(this, function (_a) {
                this.connection.connect(auth);
                if (this.options.ping) {
                    ping_1 = function () { return __awaiter(_this, void 0, void 0, function () {
                        var id, startPing;
                        var _this = this;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    id = Math.random();
                                    startPing = performance.now();
                                    this.connection.emit("Ping", id);
                                    return [4 /*yield*/, new Promise(function (resolve) {
                                            return _this.connection.once("Pong", function (returnedId) {
                                                if (returnedId === id) {
                                                    var roundtrip = performance.now() - startPing;
                                                    _this.eventManager.dispatch("pingUpdate", roundtrip);
                                                    resolve();
                                                }
                                            });
                                        })];
                                case 1:
                                    _a.sent();
                                    if (this.connection.connected) {
                                        setTimeout(ping_1, this.PING_INTERVAL);
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    ping_1();
                }
                return [2 /*return*/];
            });
        });
    };
    RealmsEngineClient.prototype.disconnect = function () {
        this.connection.disconnect();
    };
    return RealmsEngineClient;
}());
exports.RealmsEngineClient = RealmsEngineClient;
