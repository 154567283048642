"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PluginMessageType = void 0;
/**
 * All the types of messages sent by a plugin
 */
var PluginMessageType;
(function (PluginMessageType) {
    /**
     * Emitted by the plugin when first loaded
     */
    PluginMessageType["LoadedAck"] = "loadedAck";
    /**
     * Emitted to register to the editor all the plugins
     * custom dataobjects
     */
    PluginMessageType["RegisterPluginDataObjects"] = "registerPluginDataObjects";
    /**
     * Registers a custom plugin scene widget
     */
    PluginMessageType["RegisterPluginSceneWidget"] = "registerPluginSceneWidget";
    /**
     * Response to function call
     */
    PluginMessageType["FunctionCallResponse"] = "functionCallResponse";
    /**
     * Calls a function for the sdk
     */
    PluginMessageType["SDKFunctionCall"] = "sdkFunctionCall";
    /**
     * Queues a set plugin state in the editor
     */
    PluginMessageType["QueueTransformState"] = "queueSetState";
    /**
     * Emitted when a hotkey event is detected
     */
    PluginMessageType["HotkeyEvent"] = "hotkeyEvent";
})(PluginMessageType = exports.PluginMessageType || (exports.PluginMessageType = {}));
