var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Logo } from "./Logo";
export var APP_BAR_HEIGHT = 16;
export var RealmsAppBar = function (_a) {
    var childrenBefore = _a.childrenBefore;
    var navigate = useNavigate();
    var onLogoClick = function () {
        navigate("/scripts/search");
    };
    return (_jsxs(Box, __assign({ top: "0px", right: "0px", position: "fixed", display: "flex", alignItems: "center", width: "100vw", backgroundColor: "primary.500", zIndex: "banner", minHeight: APP_BAR_HEIGHT }, { children: [childrenBefore, _jsx(Logo, { onClick: onLogoClick })] })));
};
