var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AuthContext, LOGGED_OUT_DEFAULT_ROUTE } from "../providers";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAPI } from "./useAPI";
/*
 * Use Hook for interacting with auth
 */
export var useAuth = function (_a) {
    var _b = _a === void 0 ? {} : _a, redirectIfLoggedOut = _b.redirectIfLoggedOut, apiOptions = _b.apiOptions;
    var _c = useContext(AuthContext), sessionToken = _c.sessionToken, saveSessionToken = _c.saveSessionToken, clearSessionToken = _c.clearSessionToken;
    var _d = useAPI("auth/account", "get", __assign({}, apiOptions)), fetch = _d.fetch, account = _d.data, clearData = _d.clearData, status = _d.status;
    var navigate = useNavigate();
    // Navigate to logged out route
    useEffect(function () {
        if (redirectIfLoggedOut && sessionToken === undefined) {
            navigate(LOGGED_OUT_DEFAULT_ROUTE);
        }
    }, [sessionToken]);
    // Fetch account details
    useEffect(function () {
        if (sessionToken && !account) {
            fetch({}, sessionToken);
        }
    }, [sessionToken]);
    // Clears data if session token is removed
    useEffect(function () {
        if (!sessionToken) {
            clearData();
        }
    }, [sessionToken]);
    // Return session token
    return {
        sessionToken: sessionToken,
        account: account,
        saveSessionToken: saveSessionToken,
        clearSessionToken: clearSessionToken,
        status: status,
    };
};
