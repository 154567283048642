import { useEffect, useRef } from "react";
import { Subject } from "rxjs";
/*
 * Converts a react state to an Observable
 */
export function useToObservable(state) {
    var subjectRef = useRef(new Subject());
    useEffect(function () {
        subjectRef.current.next(state);
    }, [state]);
    return subjectRef.current;
}
