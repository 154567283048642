"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DialogueNodeType = void 0;
var DialogueNodeType;
(function (DialogueNodeType) {
    DialogueNodeType["Start"] = "dialogueStart";
    DialogueNodeType["Choice"] = "dialogueChoice";
    DialogueNodeType["Quest"] = "dialogueQuest";
    DialogueNodeType["QuestComplete"] = "dialogueQuestComplete";
})(DialogueNodeType || (exports.DialogueNodeType = DialogueNodeType = {}));
