"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MMOServerEvent = void 0;
var models_1 = require("./models");
var MMOServerEvent;
(function (MMOServerEvent) {
    /*
     * Emitted when the client should create the given world
     */
    MMOServerEvent["CreateRealmBlueprint"] = "createRealmBlueprint";
    /**
     * Emitted after a function call returns a value
     */
    MMOServerEvent["MMOFunctionCallResponse"] = "mmoFunctionCallResponse";
    /**
     * Emitted when a quest state has changed
     */
    MMOServerEvent["QuestStateChange"] = "questStateChange";
    /**
     * When a new message is received
     */
    MMOServerEvent["ChatMessage"] = "chatMessage";
})(MMOServerEvent || (exports.MMOServerEvent = MMOServerEvent = {}));
