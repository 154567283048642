"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RealmBlueprintRenderBuilder = void 0;
var realms_engine_1 = require("realms-engine");
var uuid_1 = require("uuid");
var DataObjectBuilder_1 = require("./DataObjectBuilder");
var realms_engine_browser_1 = require("realms-engine-browser");
var utils_1 = require("../utils");
var RealmBlueprintRenderBuilder = /** @class */ (function () {
    function RealmBlueprintRenderBuilder(props) {
        var _a;
        this.gameObjectGroup = (_a = props.gameObjectGroup) !== null && _a !== void 0 ? _a : "realmBlueprint_".concat((0, uuid_1.v4)());
        this.getAssetTexture = props.getAssetTexture;
    }
    /**
     * Builds the realm blueprint
     */
    RealmBlueprintRenderBuilder.prototype.buildRealmBlueprint = function (realm, realmBlueprint) {
        return __awaiter(this, void 0, void 0, function () {
            var dataObjectBuilder, promises, renderObjMap, rootDataObjects, getAssetIdTexture, rootDataObjects_1, rootDataObjects_1_1, dataObject, threeObj, render3DObject, e_1_1, rootDataObjects_2, rootDataObjects_2_1, dataObject, renderObj, parentObj, renderSystem;
            var e_1, _a, e_2, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        dataObjectBuilder = new DataObjectBuilder_1.DataObjectBuilder();
                        promises = [];
                        renderObjMap = new Map();
                        rootDataObjects = realmBlueprint.dataObjects.filter(function (dataObject) { return dataObject.blueprintId === realmBlueprint.rootBlueprintId; });
                        getAssetIdTexture = function (assetId) { return __awaiter(_this, void 0, void 0, function () {
                            var asset;
                            return __generator(this, function (_a) {
                                asset = realmBlueprint.assets.find(function (asset) { return asset.id === assetId; });
                                if (!asset) {
                                    return [2 /*return*/, null];
                                }
                                return [2 /*return*/, this.getAssetTexture(asset)];
                            });
                        }); };
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 6, 7, 8]);
                        rootDataObjects_1 = __values(rootDataObjects), rootDataObjects_1_1 = rootDataObjects_1.next();
                        _c.label = 2;
                    case 2:
                        if (!!rootDataObjects_1_1.done) return [3 /*break*/, 5];
                        dataObject = rootDataObjects_1_1.value;
                        return [4 /*yield*/, dataObjectBuilder.buildThreeObject(dataObject, realmBlueprint.dataObjects, getAssetIdTexture)];
                    case 3:
                        threeObj = _c.sent();
                        // Set material if exists
                        if ("material" in dataObject && dataObject.material) {
                            promises.push((0, utils_1.setThreeObjectMaterial)(threeObj, dataObject.material, getAssetIdTexture));
                        }
                        render3DObject = new realms_engine_browser_1.Render3DGameObject(threeObj);
                        render3DObject.addGroup(this.gameObjectGroup);
                        // Set transformation
                        render3DObject.setPosition(dataObject.transformation.position);
                        render3DObject.setRotation(dataObject.transformation.rotation);
                        render3DObject.setScale({
                            x: dataObject.transformation.scale,
                            y: dataObject.transformation.scale,
                            z: dataObject.transformation.scale,
                        });
                        // Visibility
                        render3DObject.setVisible(dataObject.visible);
                        // Add to map
                        renderObjMap.set(dataObject.id, render3DObject);
                        _c.label = 4;
                    case 4:
                        rootDataObjects_1_1 = rootDataObjects_1.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_1_1 = _c.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (rootDataObjects_1_1 && !rootDataObjects_1_1.done && (_a = rootDataObjects_1.return)) _a.call(rootDataObjects_1);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 8:
                        try {
                            // Build the structure of render objects here
                            for (rootDataObjects_2 = __values(rootDataObjects), rootDataObjects_2_1 = rootDataObjects_2.next(); !rootDataObjects_2_1.done; rootDataObjects_2_1 = rootDataObjects_2.next()) {
                                dataObject = rootDataObjects_2_1.value;
                                renderObj = renderObjMap.get(dataObject.id);
                                if (!renderObj) {
                                    throw new Error("Cannot find render object with uuid : ".concat(dataObject.id));
                                }
                                // Root render object so add to root scene
                                if (dataObject.parentId === null) {
                                    realm.scene.add(renderObj);
                                }
                                else {
                                    parentObj = renderObjMap.get(dataObject.parentId);
                                    if (!parentObj) {
                                        throw new Error("Cannot find parent object with uuid: ".concat(dataObject.id));
                                    }
                                    parentObj.addChild(renderObj);
                                }
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (rootDataObjects_2_1 && !rootDataObjects_2_1.done && (_b = rootDataObjects_2.return)) _b.call(rootDataObjects_2);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        renderSystem = realm.systems.get(realms_engine_browser_1.Render3DSystem);
                        return [4 /*yield*/, (0, utils_1.setThreeSkybox)({
                                scene: renderSystem.getThreeScene(),
                                skybox: realmBlueprint.skybox,
                                getTexture: getAssetIdTexture,
                            })];
                    case 9:
                        _c.sent();
                        // Wait for all materials to be laoded
                        return [4 /*yield*/, Promise.all(promises)];
                    case 10:
                        // Wait for all materials to be laoded
                        _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Clears the realm of blueprint objects
     */
    RealmBlueprintRenderBuilder.prototype.clearBlueprint = function (realm) {
        return __awaiter(this, void 0, void 0, function () {
            var blueprintObjects, blueprintObjects_1, blueprintObjects_1_1, obj;
            var e_3, _a;
            return __generator(this, function (_b) {
                blueprintObjects = realm.systems
                    .get(realms_engine_1.GroupSystem)
                    .getGameObjectsInGroup(this.gameObjectGroup);
                try {
                    for (blueprintObjects_1 = __values(blueprintObjects), blueprintObjects_1_1 = blueprintObjects_1.next(); !blueprintObjects_1_1.done; blueprintObjects_1_1 = blueprintObjects_1.next()) {
                        obj = blueprintObjects_1_1.value;
                        obj.removeFromParent();
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (blueprintObjects_1_1 && !blueprintObjects_1_1.done && (_a = blueprintObjects_1.return)) _a.call(blueprintObjects_1);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
                /*
                 * Remove skybox
                 */
                realm.systems.get(realms_engine_browser_1.Render3DSystem).getThreeScene().background = null;
                return [2 /*return*/];
            });
        });
    };
    return RealmBlueprintRenderBuilder;
}());
exports.RealmBlueprintRenderBuilder = RealmBlueprintRenderBuilder;
