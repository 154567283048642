"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidationError = exports.ValidationParamConstraint = exports.ValidationParamConstraintType = exports.ValidationParamType = void 0;
var ValidationParamType;
(function (ValidationParamType) {
    ValidationParamType["String"] = "string";
    ValidationParamType["Number"] = "number";
    ValidationParamType["Email"] = "email";
    ValidationParamType["Object"] = "object";
    ValidationParamType["Array"] = "array";
})(ValidationParamType = exports.ValidationParamType || (exports.ValidationParamType = {}));
var ValidationParamConstraintType;
(function (ValidationParamConstraintType) {
    ValidationParamConstraintType["IsLength"] = "isLength";
    ValidationParamConstraintType["IsOptional"] = "isOptional";
    ValidationParamConstraintType["IsNullable"] = "isNullable";
    ValidationParamConstraintType["Matches"] = "matches";
    ValidationParamConstraintType["IsIn"] = "isIn";
    ValidationParamConstraintType["IsGreaterEqualThan"] = "isGreaterEqualThan";
    ValidationParamConstraintType["IsLessEqualThan"] = "isLessEqualThan";
})(ValidationParamConstraintType = exports.ValidationParamConstraintType || (exports.ValidationParamConstraintType = {}));
var ValidationParamConstraint = /** @class */ (function () {
    function ValidationParamConstraint(type, data) {
        this.type = type;
        this.data = data;
    }
    return ValidationParamConstraint;
}());
exports.ValidationParamConstraint = ValidationParamConstraint;
var ValidationError = /** @class */ (function (_super) {
    __extends(ValidationError, _super);
    function ValidationError(msg) {
        var _this = _super.call(this, msg) || this;
        Object.setPrototypeOf(_this, ValidationError.prototype);
        return _this;
    }
    return ValidationError;
}(Error));
exports.ValidationError = ValidationError;
