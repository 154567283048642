"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.networkPhysicsOutlineSyncSchemaMap = void 0;
var sync_1 = require("./sync");
exports.networkPhysicsOutlineSyncSchemaMap = {
    networkPhysicsOutline: {
        position: {
            x: sync_1.SyncMethod.Linear,
            y: sync_1.SyncMethod.Linear,
            z: sync_1.SyncMethod.Linear,
        },
        quaternion: sync_1.SyncMethod.Linear,
        shapeMap: sync_1.SyncMethod.DiscreteMiddle,
    },
};
