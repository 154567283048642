"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuestState = exports.questConditionMatches = exports.QuestConditionType = void 0;
var QuestConditionType;
(function (QuestConditionType) {
    QuestConditionType["Kill"] = "kill";
})(QuestConditionType || (exports.QuestConditionType = QuestConditionType = {}));
/**
 * Checks if two quest conditions matches
 */
function questConditionMatches(q1, q2) {
    return q1.type === q2.type && q1.creatureConfigId === q2.creatureConfigId;
}
exports.questConditionMatches = questConditionMatches;
var QuestState;
(function (QuestState) {
    /**
     * Quest is accepted
     */
    QuestState["Active"] = "active";
    /**
     * All the quest requirements are achieved
     * but have not been handed in
     */
    QuestState["Achieved"] = "achieved";
    /**
     * Quest is turned in and reward given
     */
    QuestState["Completed"] = "completed";
})(QuestState || (exports.QuestState = QuestState = {}));
