import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ScriptType } from "realmsbase-common";
import { useAuth, useAPI, useToObservable, useSubscribeToObservable, } from "realmsbase-sdk";
import { merge } from "rxjs";
export function useScriptPage() {
    var scriptId = useParams().scriptId;
    var sessionToken = useAuth({ redirectIfLoggedOut: true }).sessionToken;
    var navigate = useNavigate();
    // Creates a new script
    var _a = useAPI("script", "post", { toastOnError: true, auth: sessionToken }), postScriptFetch = _a.fetch, postScriptData = _a.data, postScriptStatus = _a.status;
    var postScriptData$ = useToObservable(postScriptData);
    // Updates a script
    var _b = useAPI("script", "put", {
        toastOnError: true,
        auth: sessionToken,
    }), putScriptFetch = _b.fetch, putScriptData = _b.data;
    var putScriptData$ = useToObservable(putScriptData);
    // Gets the current script
    var _c = useAPI("script", "get", {
        auth: sessionToken,
        toastOnError: true,
    }), getScriptFetch = _c.fetch, getScriptData = _c.data;
    var getScriptData$ = useToObservable(getScriptData);
    var scriptData = useSubscribeToObservable(merge(postScriptData$, putScriptData$, getScriptData$));
    useEffect(function () {
        if (scriptId !== undefined &&
            getScriptData === undefined &&
            scriptId !== "new") {
            getScriptFetch({ scriptId: parseInt(scriptId) });
        }
    }, [getScriptData, getScriptFetch, scriptId]);
    var deleteScript = useAPI("script", "delete", {
        auth: sessionToken,
        toastOnError: true,
    }).fetch;
    var saveScript = function (scriptName, code) {
        if (scriptData) {
            putScriptFetch({
                scriptId: scriptData.id,
                name: scriptName,
                scriptType: ScriptType.Sql,
                code: code,
            });
        }
        else {
            postScriptFetch({
                name: scriptName,
                scriptType: ScriptType.Sql,
                code: code,
            });
        }
    };
    return {
        saveScript: saveScript,
        status: postScriptStatus,
        scriptData: scriptData,
        deleteScript: scriptId === undefined
            ? function () { }
            : function () {
                return deleteScript({ scriptId: parseInt(scriptId) }).then(function () {
                    navigate("/scripts/search");
                });
            },
    };
}
