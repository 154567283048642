"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.roundToDecimalPlaces = void 0;
/*
 * Rounds the value.
 * Decimal places of 0 means round to integer
 */
function roundToDecimalPlaces(n, decimalPlaces) {
    var scale = Math.pow(10, Math.max(decimalPlaces, 0));
    return Math.round((n + Number.EPSILON) * scale) / scale;
}
exports.roundToDecimalPlaces = roundToDecimalPlaces;
