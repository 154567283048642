"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UIDOM = void 0;
var types_1 = require("./types");
var canvasRenderer_1 = require("./canvasRenderer");
var hooks_1 = require("./hooks");
var UIDOM = /** @class */ (function () {
    function UIDOM(ctx, extra) {
        if (extra === void 0) { extra = {}; }
        var _this = this;
        this.ctx = ctx;
        this.extra = extra;
        this.aliasNodeTypeMap = new Map();
        this.renderer = new canvasRenderer_1.CanvasRenderer();
        this.currentTree = null;
        this.inLifeCycle = false;
        this.setStateQueue = [];
        this.requestAnimationFrameId = null;
        this.setState = function (nodeId, stateIndex, state) {
            if (_this.inLifeCycle) {
                _this.setStateQueue.push({
                    nodeId: nodeId,
                    stateIndex: stateIndex,
                    state: state,
                });
                return;
            }
            _this.updateState(nodeId, stateIndex, state);
            // Rerender because state changed
            if (_this.requestAnimationFrameId) {
                cancelAnimationFrame(_this.requestAnimationFrameId);
            }
            _this.requestAnimationFrameId = requestAnimationFrame(function () {
                _this.runLifeCycle();
            });
        };
    }
    /**
     * Declares a new custom component
     */
    UIDOM.prototype.declare = function (nodeType, component) {
        if (this.aliasNodeTypeMap.has(nodeType)) {
            throw new Error("Attempted to redeclare node ".concat(nodeType));
        }
        this.aliasNodeTypeMap.set(nodeType, component);
    };
    /**
     * Renders the component onto a canvas.
     */
    UIDOM.prototype.render = function (root) {
        this.root = root;
        this.runLifeCycle();
    };
    /**
     * Stops the current render
     */
    UIDOM.prototype.stopRender = function () {
        if (this.requestAnimationFrameId) {
            cancelAnimationFrame(this.requestAnimationFrameId);
        }
    };
    /**
     * Runs the life cycle
     */
    UIDOM.prototype.runLifeCycle = function () {
        var e_1, _a, e_2, _b, e_3, _c, e_4, _d, e_5, _e, e_6, _f, e_7, _g;
        var _this = this;
        if (!this.root) {
            return;
        }
        var rootNode = this.toUINode(this.root, "root");
        this.inLifeCycle = true;
        // Runs the components
        // Get new nodes
        var createdNodes = [];
        var deletedNodes = [];
        if (this.currentTree) {
            var oldMap = this.toNodeMap(this.currentTree);
            var newMap = rootNode ? this.toNodeMap(rootNode) : {};
            try {
                // Get deleted nodes
                for (var _h = __values(Object.values(oldMap)), _j = _h.next(); !_j.done; _j = _h.next()) {
                    var oldNode = _j.value;
                    var newNode = newMap[oldNode.id];
                    if (!newNode) {
                        deletedNodes.push(oldNode);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_j && !_j.done && (_a = _h.return)) _a.call(_h);
                }
                finally { if (e_1) throw e_1.error; }
            }
            try {
                // Get all deleted nodes
                for (var _k = __values(Object.values(newMap)), _l = _k.next(); !_l.done; _l = _k.next()) {
                    var newNode = _l.value;
                    var oldNode = oldMap[newNode.id];
                    if (!oldNode) {
                        createdNodes.push(newNode);
                        continue;
                    }
                    // Run effects for all changed dependencies
                    // of existing nodes
                    for (var i = 0; i < oldNode._useEffects.length; i++) {
                        var oldUseEffectSnapshot = oldNode._useEffects[i];
                        var newUseEffectSnapshot = newNode._useEffects[i];
                        if (!oldUseEffectSnapshot || !newUseEffectSnapshot) {
                            throw new Error("Node ".concat(oldNode.id, " does not call the same number of useEffects each render: old"));
                        }
                        var hasChanges = false;
                        for (var j = 0; j < oldUseEffectSnapshot.dependencies.length; j++) {
                            var oldDependency = oldUseEffectSnapshot.dependencies[j];
                            var newDependency = newUseEffectSnapshot.dependencies[j];
                            if (oldDependency !== newDependency) {
                                hasChanges = true;
                                break;
                            }
                        }
                        if (hasChanges) {
                            if (oldUseEffectSnapshot.effectCleanUp) {
                                oldUseEffectSnapshot.effectCleanUp();
                            }
                            newUseEffectSnapshot.effectCleanUp =
                                newUseEffectSnapshot.effect();
                        }
                    }
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_l && !_l.done && (_b = _k.return)) _b.call(_k);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        else if (rootNode) {
            // First render
            createdNodes.push.apply(createdNodes, __spreadArray([], __read(Object.values(this.toNodeMap(rootNode))), false));
        }
        try {
            // Run effects on newly created components
            for (var createdNodes_1 = __values(createdNodes), createdNodes_1_1 = createdNodes_1.next(); !createdNodes_1_1.done; createdNodes_1_1 = createdNodes_1.next()) {
                var createdNode = createdNodes_1_1.value;
                try {
                    for (var _m = (e_4 = void 0, __values(createdNode._useEffects)), _o = _m.next(); !_o.done; _o = _m.next()) {
                        var snapshot = _o.value;
                        snapshot.effectCleanUp = snapshot.effect();
                    }
                }
                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                finally {
                    try {
                        if (_o && !_o.done && (_d = _m.return)) _d.call(_m);
                    }
                    finally { if (e_4) throw e_4.error; }
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (createdNodes_1_1 && !createdNodes_1_1.done && (_c = createdNodes_1.return)) _c.call(createdNodes_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        try {
            // Run cleanup for all deleted components
            for (var deletedNodes_1 = __values(deletedNodes), deletedNodes_1_1 = deletedNodes_1.next(); !deletedNodes_1_1.done; deletedNodes_1_1 = deletedNodes_1.next()) {
                var deletedNode = deletedNodes_1_1.value;
                try {
                    for (var _p = (e_6 = void 0, __values(deletedNode._useEffects)), _q = _p.next(); !_q.done; _q = _p.next()) {
                        var snapshot = _q.value;
                        if (snapshot.effectCleanUp) {
                            snapshot.effectCleanUp();
                        }
                    }
                }
                catch (e_6_1) { e_6 = { error: e_6_1 }; }
                finally {
                    try {
                        if (_q && !_q.done && (_f = _p.return)) _f.call(_p);
                    }
                    finally { if (e_6) throw e_6.error; }
                }
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (deletedNodes_1_1 && !deletedNodes_1_1.done && (_e = deletedNodes_1.return)) _e.call(deletedNodes_1);
            }
            finally { if (e_5) throw e_5.error; }
        }
        this.currentTree = rootNode;
        // Renders on canvas
        if (rootNode) {
            // Clear canvas
            this.renderer.draw(this.ctx, rootNode, {
                widthPixels: this.ctx.canvas.width,
                heightPixels: this.ctx.canvas.height,
                rootX: 0,
                rootY: 0,
            });
        }
        try {
            for (var _r = __values(this.setStateQueue), _s = _r.next(); !_s.done; _s = _r.next()) {
                var _t = _s.value, nodeId = _t.nodeId, stateIndex = _t.stateIndex, state = _t.state;
                this.updateState(nodeId, stateIndex, state);
            }
        }
        catch (e_7_1) { e_7 = { error: e_7_1 }; }
        finally {
            try {
                if (_s && !_s.done && (_g = _r.return)) _g.call(_r);
            }
            finally { if (e_7) throw e_7.error; }
        }
        this.inLifeCycle = false;
        if (this.setStateQueue.length !== 0) {
            this.setStateQueue = [];
            // Rerender because state changed
            if (this.requestAnimationFrameId) {
                cancelAnimationFrame(this.requestAnimationFrameId);
            }
            this.requestAnimationFrameId = requestAnimationFrame(function () {
                _this.runLifeCycle();
            });
        }
        if (this.extra.onRender) {
            this.extra.onRender();
        }
    };
    UIDOM.prototype.updateState = function (nodeId, stateIndex, state) {
        if (!this.currentTree) {
            return;
        }
        var nodeMap = this.toNodeMap(this.currentTree);
        var node = nodeMap[nodeId];
        if (!node) {
            return;
        }
        node._useStates = node._useStates.map(function (oldState, i) {
            return i === stateIndex
                ? typeof state === "function"
                    ? { state: state(oldState.state) }
                    : { state: state }
                : oldState;
        });
    };
    UIDOM.prototype.toUINode = function (rawNode, nodeId) {
        var _this = this;
        var _a, _b;
        if (rawNode === null) {
            return null;
        }
        if (typeof rawNode === "string") {
            return {
                id: nodeId,
                type: types_1.UINodeType.View,
                props: null,
                textContent: rawNode,
                children: [],
                _useEffects: [],
                _useStates: [],
            };
        }
        var nodeType = rawNode.type;
        // Primative type so return
        if ((0, types_1.isUINodeType)(nodeType)) {
            // If all of the children are strings then just return the node
            // with its text content set
            if (rawNode.children.every(function (child) { return typeof child === "string"; })) {
                return __assign(__assign({}, rawNode), { id: nodeId, type: nodeType, children: [], textContent: rawNode.children.join(" "), _useEffects: [], _useStates: [] });
            }
            return __assign(__assign({}, rawNode), { id: nodeId, type: nodeType, children: rawNode.children
                    .flat()
                    .map(function (child, i) { return _this.toUINode(child, "".concat(nodeId, "[").concat(i, "]")); })
                    .filter(function (child) { return !!child; }), _useEffects: [], _useStates: [] });
        }
        var component = this.aliasNodeTypeMap.get(rawNode.type);
        if (!component) {
            throw new Error("Undeclared node type: ".concat(rawNode.type));
        }
        var componentNodeId = "".concat(nodeId, ".").concat(rawNode.type);
        var hooks = new hooks_1.Hooks(this.currentTree
            ? (_a = this.toNodeMap(this.currentTree)[componentNodeId]) !== null && _a !== void 0 ? _a : null
            : null, function (index, state) { return _this.setState(componentNodeId, index, state); });
        var uiNode = this.toUINode(component(hooks, (_b = rawNode.props) !== null && _b !== void 0 ? _b : {}), componentNodeId);
        if (uiNode === null) {
            return null;
        }
        return __assign(__assign({}, uiNode), { _useEffects: hooks.getUseEffectStates(), _useStates: hooks.getStates() });
    };
    UIDOM.prototype.toNodeMap = function (node) {
        var map = {};
        var traverse = function (n) {
            var e_8, _a;
            map[n.id] = n;
            try {
                for (var _b = __values(n.children), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var child = _c.value;
                    traverse(child);
                }
            }
            catch (e_8_1) { e_8 = { error: e_8_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_8) throw e_8.error; }
            }
        };
        traverse(node);
        return map;
    };
    return UIDOM;
}());
exports.UIDOM = UIDOM;
