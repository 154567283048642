"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GameObject = void 0;
var realms_utils_1 = require("realms-utils");
var groupSystem_1 = require("./groupSystem");
var utilsSystem_1 = require("./utilsSystem");
/*
 * Base gameobject class
 */
var GameObject = /** @class */ (function () {
    function GameObject(children) {
        var e_1, _a;
        if (children === void 0) { children = {}; }
        this.children = new Set();
        this.parent = null;
        this.scene = null;
        this.eventManager = new realms_utils_1.EventManager();
        this.addListener = this.eventManager.addListener;
        this.removeListener = this.eventManager.removeListener;
        this.once = this.eventManager.once;
        this.dispatch = this.eventManager.dispatch;
        this.groups = new Set();
        this.id = ++GameObject.idIncrement;
        try {
            for (var _b = __values(Object.entries(children)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), name_1 = _d[0], child = _d[1];
                this.addChildWithName(name_1, child);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.name = "GameObject_".concat(this.id);
    }
    /*
     * A utility method to build game objects
     */
    GameObject.build = function (template) {
        var e_2, _a;
        try {
            for (var _b = __values(Object.entries(template.children)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), childName = _d[0], childTemplate = _d[1];
                template.object.addChildWithName(childName, GameObject.build(childTemplate));
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return template.object;
    };
    Object.defineProperty(GameObject.prototype, "type", {
        get: function () {
            return Object.getPrototypeOf(this).constructor;
        },
        enumerable: false,
        configurable: true
    });
    GameObject.prototype.getName = function () {
        return this.name;
    };
    /*
     * Get all the groups that this game object is a part of
     */
    GameObject.prototype.getGroups = function () {
        return Array.from(this.groups);
    };
    /*
     * Add game object to group
     */
    GameObject.prototype.addGroup = function (group) {
        var _a;
        this.groups.add(group);
        var groupSystem = (_a = this.scene) === null || _a === void 0 ? void 0 : _a.realm.systems.get(groupSystem_1.GroupSystem);
        if (groupSystem) {
            groupSystem._addGameObjectToGroup(this, group);
        }
    };
    /*
     * Remove game object from group
     */
    GameObject.prototype.removeFromGroup = function (group) {
        var _a;
        this.groups.delete(group);
        var groupSystem = (_a = this.scene) === null || _a === void 0 ? void 0 : _a.realm.systems.get(groupSystem_1.GroupSystem);
        if (groupSystem) {
            groupSystem._removeGameObjectFromGroup(this, group);
        }
    };
    /*
     * Adds a child gameObject
     */
    GameObject.prototype.addChildWithName = function (name, child) {
        // Children must have unique names
        if (Array.from(this.children.values()).find(function (c) { return c.name === name; }) !==
            undefined) {
            throw new Error("child gameObject with name ".concat(name, " already exists"));
        }
        child.name = name;
        this.addChild(child);
        return child;
    };
    /*
     * Adds a child gameObject and returns the child
     */
    GameObject.prototype.addChild = function (child) {
        // Add to map
        this.children.add(child);
        child.parent = this;
        // If already in scene then add child to scene
        if (this.scene) {
            this.scene.add(child);
        }
        return child;
    };
    GameObject.prototype.getChildByName = function (name) {
        var _a;
        return ((_a = Array.from(this.children.values()).find(function (c) { return c.name === name; })) !== null && _a !== void 0 ? _a : null);
    };
    /*
     * Removes the child gameObject with the given name
     */
    GameObject.prototype.removeChildByName = function (name) {
        var child = Array.from(this.children.values()).find(function (c) { return c.name === name; });
        if (child) {
            this.removeChild(child);
        }
    };
    /*
     * Removes a child
     */
    GameObject.prototype.removeChild = function (child) {
        // Remove from scene if child is still in scene
        if (this.scene) {
            this.scene.remove(child);
        }
        this.children.delete(child);
        child.parent = null;
    };
    /*
     * Queue to remove child at the end of game step
     */
    GameObject.prototype.queueRemoveChild = function (child) {
        var _this = this;
        if (this.scene) {
            this.scene.realm.systems
                .get(utilsSystem_1.UtilsSystem)
                .queueAtGameStepEnd(function () { return _this.removeChild(child); });
        }
        else {
            this.removeChild(child);
        }
    };
    GameObject.prototype.getAllChildren = function () {
        return Array.from(this.children.values());
    };
    GameObject.prototype.getParent = function () {
        return this.parent;
    };
    /*
     * Gets all sub gameObjects including itself
     */
    GameObject.prototype.getGameObjectFamily = function () {
        return __spreadArray([
            this
        ], __read(this.getAllChildren()
            .map(function (c) { return c.getGameObjectFamily(); })
            .flat()), false);
    };
    /*
     * Remove this gameObject from its parent.
     * If it is the root gameObject, remove it from the scene
     */
    GameObject.prototype.removeFromParent = function () {
        if (this.parent) {
            this.parent.removeChild(this);
        }
        else if (this.scene) {
            this.scene.remove(this);
        }
    };
    /**
     * Queues remove from parent
     */
    GameObject.prototype.queueRemoveFromParent = function () {
        var _this = this;
        if (this.scene) {
            this.scene.realm.systems.get(utilsSystem_1.UtilsSystem).queueAtGameStepEnd(function () {
                _this.removeFromParent();
            });
        }
        else {
            this.removeFromParent();
        }
    };
    GameObject.prototype.getScene = function () {
        return this.scene;
    };
    // Called by the scene class on add
    // Don't call this manually
    GameObject.prototype._setScene = function (scene) {
        this.scene = scene;
    };
    /**
     * Returns the gameObjects name and all its super classes up until GameObject
     */
    GameObject.prototype.getAllInheritedTypes = function () {
        var getInheritedType = function (curr) {
            var currType = curr.constructor;
            if (currType === GameObject.prototype.constructor) {
                return [currType];
            }
            return __spreadArray([currType], __read(getInheritedType(Object.getPrototypeOf(curr))), false);
        };
        // We have to call Object.getPrototypeOf first since "this" refers
        // to the current class instance and the prototype itself will be the same class name
        return getInheritedType(Object.getPrototypeOf(this));
    };
    /*
     * Called when the gameObject first enters the scene, including via addChild
     */
    GameObject.prototype.onInit = function (scene) { };
    /*
     * Called when the gameObject is removed from the scene including via removeChild
     */
    GameObject.prototype.onRemove = function (scene) { };
    GameObject.idIncrement = 0;
    return GameObject;
}());
exports.GameObject = GameObject;
