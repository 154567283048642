"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFrom = void 0;
var react_1 = require("react");
var rxjs_1 = require("rxjs");
/*
 * Converts a react state to an observable
 */
var useFrom = function (state) {
    var subjectRef = (0, react_1.useRef)(new rxjs_1.ReplaySubject(1));
    (0, react_1.useEffect)(function () {
        subjectRef.current.next(state);
    }, [state]);
    return subjectRef.current.asObservable();
};
exports.useFrom = useFrom;
