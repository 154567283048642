import { ProcessGameObject, Scene, Vector3, vector3Zero } from "realms-engine";
import { Render3DGameObject } from "realms-engine-browser";
import THREE from "realms-three";

interface ThreeAnimation {
  clip: THREE.AnimationClip;
  timeScale?: number;
  clampWhenFinished?: boolean;
  offset?: Vector3;
}

type ThreeAnimationMap = {
  [animationName: string]: ThreeAnimation;
};

export class ThreeAnimationGameObject extends ProcessGameObject {
  private animationActionMap: Map<string, THREE.AnimationAction> = new Map();
  private activeAnimationAction: THREE.AnimationAction | null = null;
  private activeAnimationName: string | null = null;
  private mixer: THREE.AnimationMixer | null = null;

  constructor(
    private animationMap: ThreeAnimationMap = {},
    private options: { fadeOutDuration?: number } = {}
  ) {
    super();
  }

  override onInit(scene: Scene): void {
    const parent = this.getParent();
    if (!(parent instanceof Render3DGameObject)) {
      throw new Error(
        `Animation GameObject is not the child of a Render3DGameObject`
      );
    }
    this.setTarget(parent.getThreeObject());
    parent.addListener("onThreeObjectChange", this.setTarget);
  }

  override onRemove(scene: Scene): void {
    const parent = this.getParent();
    if (!(parent instanceof Render3DGameObject)) {
      throw new Error(
        `Animation GameObject is removed from a non Render3DGameObject`
      );
    }
    parent.removeListener("onThreeObjectChange", this.setTarget);
  }

  /*
   * Sets a new target for the animation component
   */
  private setTarget = (target: THREE.Object3D) => {
    if (this.mixer) {
      this.mixer.stopAllAction();
    }
    this.mixer = new THREE.AnimationMixer(target);
    this.loadAnimations(this.mixer, this.animationMap);
    if (this.activeAnimationName) {
      this.play(this.activeAnimationName);
    }
  };

  /*
   * Play the given animation
   */
  play(
    animation: string,
    options: {
      duration?: number | null;
      clampWhenFinished?: boolean;
    } = {}
  ) {
    const action = this.animationActionMap.get(animation);
    if (!action) {
      throw new Error(`No animation named ${animation} exists`);
    }
    if (this.activeAnimationAction === action) {
      return;
    }
    const fadeOutDuration = this.options.fadeOutDuration ?? 0.2;
    if (this.activeAnimationAction !== null) {
      this.activeAnimationAction.fadeOut(fadeOutDuration);
    }
    this.activeAnimationName = animation;
    this.activeAnimationAction = action
      .reset()
      .setEffectiveWeight(1)
      .fadeIn(fadeOutDuration);
    if (typeof options.duration === "number") {
      // Duration is in seconds so convert it to millisceconds
      const clipDuration = this.activeAnimationAction.getClip().duration * 1000;
      const timeScale = clipDuration / options.duration;
      this.activeAnimationAction.timeScale = timeScale;
    }
    if (options.clampWhenFinished) {
      this.activeAnimationAction.clampWhenFinished = true;
    }
    // Set offset
    const anim = this.animationMap[animation];
    if (anim.offset) {
      (this.getParent() as Render3DGameObject).setOffset(anim.offset);
    } else {
      (this.getParent() as Render3DGameObject).setOffset(vector3Zero());
    }

    this.activeAnimationAction.play();
  }

  private loadAnimations(
    mixer: THREE.AnimationMixer,
    animationMap: ThreeAnimationMap
  ) {
    Object.entries(animationMap).forEach(([animName, animation]) => {
      const action = mixer.clipAction(animation.clip);
      if (animation.timeScale !== undefined) {
        action.timeScale = animation.timeScale;
      }
      if (animation.clampWhenFinished) {
        action.clampWhenFinished = true;
        action.loop = THREE.LoopOnce;
      }
      this.animationActionMap.set(animName, action);
    });
  }

  override step(delta: number): void {
    if (this.mixer) {
      this.mixer.update(delta / 1000);
    }
  }
}
