import { Environment } from "realms-utils";
import { getEnvironment } from "./hooks/useEnvironment";

/*
 * Where backend is
 */
const serverUrl =
  getEnvironment() === Environment.Development
    ? window.location.origin.replace(window.location.port, "5555/")
    : "https://arcadian-realms-server.fly.dev/";

/*
 * Endpoint for API Requests
 */
const apiUrl = serverUrl + "api/";

export const MMOConfig = {
  serverUrl,
  apiUrl,
};
