"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnvironment = void 0;
var realms_utils_1 = require("realms-utils");
var getEnvironment = function () {
    if (process.env["REACT_APP_MODE"] === "dev") {
        return realms_utils_1.Environment.Development;
    }
    return realms_utils_1.Environment.Production;
};
exports.getEnvironment = getEnvironment;
