import { useState, useEffect } from "react";
import { Vector2 } from "realms-engine";
import { useDebounce } from "use-debounce";

interface DesktopControlsState {
  pressedKeys: { [key: string]: boolean };
  leftMouseDown: boolean;
  rightMouseDown: boolean;
  mousePosition: Vector2;
}

type DesktopControlTargets = Partial<{
  [target in keyof DesktopControlsState]: {
    debounceTime?: number;
  };
}>;

export const useDesktopControls = (
  targets: DesktopControlTargets = {}
): DesktopControlsState => {
  const [pressedKeys, setPressedKeys] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [debouncedSetPressedKeys] = useDebounce(
    setPressedKeys,
    targets["pressedKeys"]?.debounceTime ?? 0
  );

  const [leftMouseDown, setLeftMouseDown] = useState(false);
  const [debouncedSetLeftMouseDown] = useDebounce(
    setLeftMouseDown,
    targets["leftMouseDown"]?.debounceTime ?? 0
  );

  const [rightMouseDown, setRightMouseDown] = useState(false);
  const [debouncedSetRightMouseDown] = useDebounce(
    setRightMouseDown,
    targets["leftMouseDown"]?.debounceTime ?? 0
  );

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [debouncedSetMousePosition] = useDebounce(
    setMousePosition,
    targets["mousePosition"]?.debounceTime ?? 0
  );

  useEffect(() => {
    const keyDownListener = (e: KeyboardEvent) => {
      debouncedSetPressedKeys((pressedKeys) => {
        const key = e.key.toLowerCase();
        if (pressedKeys[key]) {
          return pressedKeys;
        }
        return {
          ...pressedKeys,
          [key]: true,
        };
      });
    };
    const keyUpListener = (e: KeyboardEvent) => {
      debouncedSetPressedKeys((pressedKeys) =>
        Object.fromEntries(
          Object.entries(pressedKeys).filter(
            (entry) => entry[0] !== e.key.toLowerCase()
          )
        )
      );
    };
    const mouseDownListener = (e: MouseEvent) => {
      if (e.button === 0) {
        debouncedSetLeftMouseDown(() => true);
      } else if (e.button === 2) {
        debouncedSetRightMouseDown(() => true);
      }
    };
    const mouseUpListener = (e: MouseEvent) => {
      if (e.button === 0) {
        debouncedSetLeftMouseDown(() => false);
      } else if (e.button === 2) {
        debouncedSetRightMouseDown(() => false);
      }
    };
    const mouseMoveListener = (e: MouseEvent) => {
      debouncedSetMousePosition(() => ({ x: e.clientX, y: e.clientY }));
    };
    if (targets["pressedKeys"]) {
      window.addEventListener("keydown", keyDownListener);
      window.addEventListener("keyup", keyUpListener);
    }
    if (targets["leftMouseDown"] || targets["rightMouseDown"]) {
      window.addEventListener("mousedown", mouseDownListener);
      window.addEventListener("mouseup", mouseUpListener);
    }
    if (targets["mousePosition"]) {
      window.addEventListener("mousemove", mouseMoveListener);
    }
    return () => {
      if (targets["pressedKeys"]) {
        window.removeEventListener("keydown", keyDownListener);
        window.removeEventListener("keyup", keyUpListener);
      }
      if (targets["leftMouseDown"]) {
        window.removeEventListener("mousedown", mouseDownListener);
        window.removeEventListener("mouseup", mouseUpListener);
      }
      if (targets["mousePosition"]) {
        window.removeEventListener("mousemove", mouseMoveListener);
      }
    };
  }, [
    debouncedSetLeftMouseDown,
    debouncedSetMousePosition,
    debouncedSetPressedKeys,
    debouncedSetRightMouseDown,
    targets,
  ]);
  return {
    pressedKeys,
    leftMouseDown,
    rightMouseDown,
    mousePosition,
  };
};
