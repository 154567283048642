"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NetworkSlaveGameObject = void 0;
var realms_engine_1 = require("realms-engine");
// A gameObject responsible for syncing with its master gameObject
// > Note: A slave gameObject should not be created manually! It will be created by the NetworkSlaveSystem
var NetworkSlaveGameObject = /** @class */ (function (_super) {
    __extends(NetworkSlaveGameObject, _super);
    function NetworkSlaveGameObject(slaveGameObject, 
    /*
     * The id of the master gameObject on the server
     * Note: Id of master gameObject is not necessarily the same as the id on the slave
     */
    masterGameObjectId) {
        var _this = _super.call(this) || this;
        _this.slaveGameObject = slaveGameObject;
        _this.masterGameObjectId = masterGameObjectId;
        _this.lastState = null;
        _this.addChild(slaveGameObject);
        return _this;
    }
    NetworkSlaveGameObject.prototype.setState = function (state) {
        if (this.lastState === null) {
            this.slaveGameObject.onSlaveChanges(state, state, state);
        }
        else {
            var changes = (0, realms_engine_1.getDataChanges)(this.lastState, state);
            if (Object.keys(changes).length !== 0) {
                this.slaveGameObject.onSlaveChanges(changes, state, this.lastState);
            }
        }
        this.lastState = state;
    };
    NetworkSlaveGameObject.prototype.onInit = function (scene) {
        if (this.getParent() !== null) {
            console.error(this.getParent());
            throw new Error("Slave gameObjects should not be placed as a child gameObject because it will not clean up parent gameObjects on removal");
        }
    };
    return NetworkSlaveGameObject;
}(realms_engine_1.GameObject));
exports.NetworkSlaveGameObject = NetworkSlaveGameObject;
