import {
  CharacterBeardType,
  CharacterGender,
  CharacterHairBackType,
  CharacterHairBaseType,
  CharacterHairFringeType,
  CharacterHairSideType,
  CharacterHairTailType,
  CharacterMoustacheType,
} from "mmo-common";
import { Vector3 } from "realms-engine";

export interface CharacterObjectConfig {
  beard: {
    [beardType in Exclude<CharacterBeardType, CharacterBeardType.None>]: {
      [gender in CharacterGender]: {
        position: Vector3;
      };
    };
  };
  hairBase: {
    [hairBaseType in Exclude<
      CharacterHairBaseType,
      CharacterHairBaseType.Bald
    >]: {
      [gender in CharacterGender]: {
        position: Vector3;
      };
    };
  };
  hairFringe: {
    [hairFringeType in Exclude<
      CharacterHairFringeType,
      CharacterHairFringeType.None
    >]: {
      [gender in CharacterGender]: {
        position: Vector3;
      };
    };
  };
  hairBack: {
    [hairBackType in Exclude<
      CharacterHairBackType,
      CharacterHairBackType.None
    >]: {
      [gender in CharacterGender]: {
        position: Vector3;
      };
    };
  };
  hairTail: {
    [hairTailType in Exclude<
      CharacterHairTailType,
      CharacterHairTailType.None
    >]: {
      [gender in CharacterGender]: {
        position: Vector3;
      };
    };
  };
  hairSide: {
    [hairSideType in Exclude<
      CharacterHairSideType,
      CharacterHairSideType.None
    >]: {
      [gender in CharacterGender]: {
        position: Vector3;
      };
    };
  };
  moustache: {
    [moustacheType in Exclude<
      CharacterMoustacheType,
      CharacterMoustacheType.None
    >]: {
      [gender in CharacterGender]: {
        position: Vector3;
      };
    };
  };
}

const beard: CharacterObjectConfig["beard"] = {
  [CharacterBeardType.Small]: {
    [CharacterGender.Male]: {
      position: {
        x: 0,
        y: -200,
        z: 100,
      },
    },
    [CharacterGender.Female]: {
      position: {
        x: 0,
        y: -200,
        z: 90,
      },
    },
  },
};

const hairBase: CharacterObjectConfig["hairBase"] = {
  [CharacterHairBaseType.Basic]: {
    [CharacterGender.Male]: {
      position: {
        x: 0,
        y: -30,
        z: -30,
      },
    },
    [CharacterGender.Female]: {
      position: {
        x: 0,
        y: -30,
        z: -40,
      },
    },
  },
  [CharacterHairBaseType.Spiky]: {
    [CharacterGender.Male]: {
      position: {
        x: 0,
        y: -30,
        z: -30,
      },
    },
    [CharacterGender.Female]: {
      position: {
        x: 0,
        y: -30,
        z: -40,
      },
    },
  },
  [CharacterHairBaseType.Braided]: {
    [CharacterGender.Male]: {
      position: {
        x: 0,
        y: -110,
        z: -10,
      },
    },
    [CharacterGender.Female]: {
      position: {
        x: 0,
        y: -110,
        z: -17,
      },
    },
  },
};

const hairFringe: CharacterObjectConfig["hairFringe"] = {
  [CharacterHairFringeType.Side]: {
    [CharacterGender.Male]: {
      position: {
        x: 0,
        y: -40,
        z: 90,
      },
    },
    [CharacterGender.Female]: {
      position: {
        x: 0,
        y: -40,
        z: 80,
      },
    },
  },
  [CharacterHairFringeType.SideLong]: {
    [CharacterGender.Male]: {
      position: {
        x: 50,
        y: 0,
        z: 90,
      },
    },
    [CharacterGender.Female]: {
      position: {
        x: 50,
        y: 0,
        z: 80,
      },
    },
  },
  [CharacterHairFringeType.MidPart]: {
    [CharacterGender.Male]: {
      position: {
        x: 0,
        y: -70,
        z: 65,
      },
    },
    [CharacterGender.Female]: {
      position: {
        x: 0,
        y: -70,
        z: 55,
      },
    },
  },
  [CharacterHairFringeType.CombedUp]: {
    [CharacterGender.Male]: {
      position: {
        x: 0,
        y: -50,
        z: 55,
      },
    },
    [CharacterGender.Female]: {
      position: {
        x: 0,
        y: -50,
        z: 45,
      },
    },
  },
  [CharacterHairFringeType.Straight]: {
    [CharacterGender.Male]: {
      position: {
        x: 0,
        y: -40,
        z: 80,
      },
    },
    [CharacterGender.Female]: {
      position: {
        x: 0,
        y: -40,
        z: 70,
      },
    },
  },
};

const hairBack: CharacterObjectConfig["hairBack"] = {
  [CharacterHairBackType.Short]: {
    [CharacterGender.Male]: {
      position: {
        x: 0,
        y: -180,
        z: -130,
      },
    },
    [CharacterGender.Female]: {
      position: {
        x: 0,
        y: -180,
        z: -140,
      },
    },
  },
  [CharacterHairBackType.Tied]: {
    [CharacterGender.Male]: {
      position: {
        x: 0,
        y: -180,
        z: -150,
      },
    },
    [CharacterGender.Female]: {
      position: {
        x: 0,
        y: -180,
        z: -160,
      },
    },
  },
};

const hairTail: CharacterObjectConfig["hairTail"] = {
  [CharacterHairTailType.Pony]: {
    [CharacterGender.Male]: {
      position: {
        x: 0,
        y: -180,
        z: -190,
      },
    },
    [CharacterGender.Female]: {
      position: {
        x: 0,
        y: -180,
        z: -200,
      },
    },
  },
  [CharacterHairTailType.Spiky]: {
    [CharacterGender.Male]: {
      position: {
        x: 0,
        y: -100,
        z: -155,
      },
    },
    [CharacterGender.Female]: {
      position: {
        x: 0,
        y: -100,
        z: -165,
      },
    },
  },
  [CharacterHairTailType.Twin]: {
    [CharacterGender.Male]: {
      position: {
        x: 0,
        y: -80,
        z: -135,
      },
    },
    [CharacterGender.Female]: {
      position: {
        x: 0,
        y: -80,
        z: -145,
      },
    },
  },
};

const hairSide: CharacterObjectConfig["hairSide"] = {
  [CharacterHairSideType.Short]: {
    [CharacterGender.Male]: {
      position: {
        x: 0,
        y: -100,
        z: -20,
      },
    },
    [CharacterGender.Female]: {
      position: {
        x: 0,
        y: -100,
        z: -30,
      },
    },
  },
};

const moustache: CharacterObjectConfig["moustache"] = {
  [CharacterMoustacheType.Small]: {
    [CharacterGender.Male]: {
      position: {
        x: 0,
        y: -190,
        z: 100,
      },
    },
    [CharacterGender.Female]: {
      position: {
        x: 0,
        y: -190,
        z: 90,
      },
    },
  },
};

export const characterConfig: CharacterObjectConfig = {
  beard,
  hairBase,
  hairFringe,
  hairBack,
  hairTail,
  hairSide,
  moustache,
};
