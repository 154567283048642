"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SystemManager = void 0;
// Determines the order to run systems
var SystemManager = /** @class */ (function () {
    function SystemManager(realm, gameObjectSystemManager) {
        this.gameObjectSystemManager = gameObjectSystemManager;
        this.systems = new Map();
        this.realm = realm;
    }
    SystemManager.prototype.add = function (system) {
        this.systems.set(system.type, system);
        system._setGameObjectSystemManager(this.gameObjectSystemManager);
        system.onAdd(this.realm);
        this.gameObjectSystemManager.addSystem(system);
    };
    /*
     * Gets a system safely without throwing error
     */
    SystemManager.prototype.getSafely = function (systemType) {
        var _a;
        var system = this.systems.get(systemType);
        return (_a = system) !== null && _a !== void 0 ? _a : null;
    };
    /*
     * Gets a given system and throws an error if it doesnt exist
     */
    SystemManager.prototype.get = function (systemType) {
        var system = this.getSafely(systemType);
        if (!system) {
            throw new Error("System ".concat(systemType.name, " does not exist"));
        }
        return system;
    };
    SystemManager.prototype.getAll = function () {
        return Array.from(this.systems.values());
    };
    // Runs 'step()' on all the systems considering their dependencies
    SystemManager.prototype.step = function (delta) {
        var _this = this;
        // Start game step
        this.getAll().forEach(function (s) { return s.onGameStepStart(delta, _this.realm); });
        // Loop through systems via depth first search
        var alreadyRunSystemNames = new Set();
        var runSystem = function (system) {
            var e_1, _a;
            // If already run in case where this is a dependency just ignore
            if (alreadyRunSystemNames.has(system.type)) {
                return;
            }
            try {
                // Run all required systems
                for (var _b = __values(system.getDependencies()), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var dep = _c.value;
                    if (!alreadyRunSystemNames.has(dep)) {
                        var depSystem = _this.systems.get(dep);
                        if (depSystem === undefined) {
                            throw new Error("System ".concat(system.type, " depends on non-existant system: ").concat(dep));
                        }
                        runSystem(depSystem);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            alreadyRunSystemNames.add(system.type);
            system.processBeforeStep(delta, _this.realm);
            // Run 'step()' on all gameObjects for that system
            _this.gameObjectSystemManager
                .getSystemGameObjects(system.type)
                .forEach(function (c) { return system.step(delta, c, _this.realm); });
            // Run system step
            system.processAfterStep(delta, _this.realm);
        };
        // Run step
        this.getAll().forEach(function (s) { return runSystem(s); });
        // On game step end
        this.getAll().forEach(function (s) { return s.onGameStepEnd(delta, _this.realm); });
    };
    /*
     * Cleans all systems up
     */
    SystemManager.prototype.clean = function () {
        this.getAll().forEach(function (system) { return system.clean(); });
    };
    return SystemManager;
}());
exports.SystemManager = SystemManager;
