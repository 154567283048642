"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMouseMove = void 0;
var react_1 = require("react");
var react_rxjs_toolkit_1 = require("react-rxjs-toolkit");
var rxjs_1 = require("rxjs");
var useMouseMove = function (elemRef, options) {
    if (options === void 0) { options = {}; }
    var debounceTime$ = (0, react_rxjs_toolkit_1.useFrom)(options === null || options === void 0 ? void 0 : options.debounceTime);
    var throttleTime$ = (0, react_rxjs_toolkit_1.useFrom)(options === null || options === void 0 ? void 0 : options.throttleTime);
    var mouseEventSubjectRef$ = (0, react_1.useRef)(new rxjs_1.BehaviorSubject(null));
    var mouseEventRef$ = (0, react_1.useRef)((0, rxjs_1.combineLatest)([debounceTime$, throttleTime$]).pipe((0, rxjs_1.switchMap)(function (_a) {
        var _b = __read(_a, 2), debounceTimeDuration = _b[0], throttleTimeDuration = _b[1];
        return mouseEventSubjectRef$.current.pipe((0, rxjs_1.debounceTime)(debounceTimeDuration !== null && debounceTimeDuration !== void 0 ? debounceTimeDuration : 0), (0, rxjs_1.throttleTime)(throttleTimeDuration !== null && throttleTimeDuration !== void 0 ? throttleTimeDuration : 0));
    })));
    var _a = __read((0, react_rxjs_toolkit_1.useObservable)(mouseEventRef$.current, null), 1), mouseEvent = _a[0];
    (0, react_1.useEffect)(function () {
        if (elemRef.current !== null) {
            var onMouseMove_1 = function (e) {
                return mouseEventSubjectRef$.current.next(e);
            };
            var elem_1 = elemRef.current;
            elem_1.addEventListener("mousemove", onMouseMove_1);
            return function () {
                elem_1.removeEventListener("mousemove", onMouseMove_1);
            };
        }
        return;
    }, [elemRef]);
    return mouseEvent;
};
exports.useMouseMove = useMouseMove;
