"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useReducerSteps = void 0;
var react_1 = require("react");
var useReducerSteps = function (_a) {
    var reducer = _a.reducer;
    var _b = __read((0, react_1.useState)(null), 2), stepStateEvent = _b[0], setStepStateEvent = _b[1];
    (0, react_1.useEffect)(function () {
        if (stepStateEvent === null) {
            return;
        }
        // Get next step state
        reducer(stepStateEvent.stepState).then(function (nextStepState) {
            // Update event
            setStepStateEvent(nextStepState
                ? __assign(__assign({}, stepStateEvent), { stepState: nextStepState }) : null);
            // Just finished process
            if (nextStepState === null && stepStateEvent.onComplete) {
                stepStateEvent.onComplete();
            }
        });
    }, [stepStateEvent]);
    var startSteps = (0, react_1.useCallback)(function (_a) {
        var initialState = _a.initialState, onComplete = _a.onComplete;
        setStepStateEvent(function (stepStateEvent) {
            return stepStateEvent === null
                ? {
                    stepState: initialState,
                    onComplete: onComplete,
                }
                : stepStateEvent;
        });
    }, []);
    return {
        startSteps: startSteps,
    };
};
exports.useReducerSteps = useReducerSteps;
