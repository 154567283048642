export const AnimationAssetMap = {
  baseCharacterIdleAnimation: {
    fileName: "/assets/animations/base_character_idle.fbx",
  },
  baseCharacterJumpAnimation: {
    fileName: "/assets/animations/base_character_jump.fbx",
  },
  baseCharacterWalkAnimation: {
    fileName: "/assets/animations/base_character_walk.fbx",
  },
  baseCharacterDeathAnimation: {
    fileName: "/assets/animations/base_character_death.fbx",
  },
  horizontalSlashAnimation: {
    fileName: "/assets/animations/horizontal_slash.fbx",
  },
  inwardSlashAnimation: {
    fileName: "/assets/animations/inward_slash.fbx",
  },
  goblinIdleAnimation: {
    fileName: "/assets/animations/goblin/idle.fbx",
  },
  goblinWalkAnimation: {
    fileName: "/assets/animations/goblin/walk.fbx",
  },
  goblinDeathAnimation: {
    fileName: "/assets/animations/goblin/death.fbx",
  },
  goblinTwoHandSlashAnimation: {
    fileName: "/assets/animations/goblin/two_hand_slash.fbx",
  },
  outwardSlashAnimation: {
    fileName: "/assets/animations/outward_slash.fbx",
  },
  slash360Animation: {
    fileName: "/assets/animations/slash_360.fbx",
  },
  verticalSlashAnimation: {
    fileName: "/assets/animations/vertical_slash.fbx",
  },
} as const;
