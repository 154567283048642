import { useToast } from "@chakra-ui/react";
import { useEffect } from "react";
export var useErrorToast = function (error) {
    var toast = useToast();
    useEffect(function () {
        if (error) {
            toast({
                title: "Error ".concat(error.statusCode),
                description: error.message,
                status: "error",
                position: "top",
            });
        }
    }, [error, toast]);
};
