import { ProcessGameObject, Quat, Vector3 } from "realms-engine";
import { Render3DGameObject } from "realms-engine-browser";
import THREE from "realms-three";

export interface TextureFrame {
  /**
   * Texture to display
   */
  texture: THREE.Texture;
  /**
   * Duration in millisceconds to show this texture frame
   */
  duration: number;
}

/**
 * Makes an animated plane texture
 */
export class AnimatedPlaneTextureGameObject extends ProcessGameObject<{
  onEnd: AnimatedPlaneTextureGameObject;
}> {
  frames: TextureFrame[];
  loop: boolean;

  /**
   * Stores the current time in the animation
   */
  private animationTimestamp: null | number = null;
  private render3DGameObject: Render3DGameObject;
  private endReached = false;

  constructor(config: {
    frames: TextureFrame[];
    loop?: boolean;
    autoPlay?: boolean;
    width: number;
    height: number;
  }) {
    super();
    const firstFrame = config.frames[0];
    if (!firstFrame) {
      throw new Error(`AnimatedPlaneTextureGameObject has no texture frames`);
    }
    this.render3DGameObject = this.addChild(
      new Render3DGameObject(
        new THREE.Mesh(
          new THREE.PlaneGeometry(config.width, config.height),
          new THREE.MeshBasicMaterial({
            side: THREE.DoubleSide,
            transparent: true,
            map: firstFrame.texture,
          })
        )
      )
    );
    this.loop = !!config.loop;
    this.frames = config.frames;
    if (config.autoPlay) {
      this.play();
    }
  }

  play() {
    this.animationTimestamp = 0;
    this.endReached = false;
  }

  getPosition() {
    return this.render3DGameObject.getPosition();
  }

  setPosition(v: Vector3) {
    this.render3DGameObject.setPosition(v);
  }

  getRotation() {
    return this.render3DGameObject.getRotation();
  }

  setRotation(r: Vector3) {
    this.render3DGameObject.setRotation(r);
  }

  getQuaternion() {
    return this.render3DGameObject.getQuaternion();
  }

  setQuaternion(q: Quat) {
    this.render3DGameObject.setQuaternion(q);
  }

  private renderFrame(frame: TextureFrame) {
    const mesh = this.render3DGameObject.getThreeObject();
    if (!(mesh instanceof THREE.Mesh)) {
      return;
    }
    (mesh.material as THREE.MeshBasicMaterial).map = frame.texture;
    (mesh.material as THREE.MeshBasicMaterial).needsUpdate = true;
  }

  override step(delta: number): void {
    if (this.animationTimestamp === null) {
      return;
    }

    const totalAnimationDuration = this.frames
      .map((f) => f.duration)
      .reduce((i, n) => i + n);

    // Get frame
    let frameEndTime = 0;
    let currentFrame: TextureFrame | null = null;
    for (const frame of this.frames) {
      frameEndTime += frame.duration;
      // Found the frame to display
      if (this.animationTimestamp <= frameEndTime) {
        currentFrame = frame;
        break;
      }
    }

    if (currentFrame) {
      this.renderFrame(currentFrame);
    }

    // Increment timestamp
    this.animationTimestamp += delta;
    if (this.animationTimestamp >= totalAnimationDuration) {
      if (this.loop) {
        // Loop
        this.animationTimestamp = 0;
        this.dispatch("onEnd", this);
      } else {
        // End reached
        this.animationTimestamp = totalAnimationDuration;
        if (!this.endReached) {
          this.dispatch("onEnd", this);
        }
        this.endReached = true;
      }
    }
  }
}
