var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAPI, APICallStatus, useAuth, useErrorToast } from "realmsbase-sdk";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Button, Container, Input, Text } from "@chakra-ui/react";
import { Logo } from "../components/Logo";
export var RegisterPage = function () {
    var _a = useState(""), email = _a[0], setEmail = _a[1];
    var _b = useState(""), password = _b[0], setPassword = _b[1];
    var _c = useAPI("register", "post"), fetch = _c.fetch, status = _c.status, data = _c.data, error = _c.error;
    var _d = useAuth(), sessionToken = _d.sessionToken, saveSessionToken = _d.saveSessionToken;
    var navigate = useNavigate();
    useErrorToast(error);
    var onSubmit = function (e) {
        e === null || e === void 0 ? void 0 : e.preventDefault();
        fetch({ email: email, password: password });
    };
    // Navigate to dashboard on logged in
    useEffect(function () {
        if (data) {
            saveSessionToken(data);
            navigate("/scripts/search");
        }
    }, [data, navigate, saveSessionToken]);
    // Navigate to dashboard if logged in
    useEffect(function () {
        if (sessionToken) {
            navigate("/scripts/search");
        }
    });
    return (_jsx(Container, __assign({ display: "flex", flexDirection: "column", justifyContent: "center", minHeight: "100vh" }, { children: _jsx("form", __assign({ onSubmit: onSubmit }, { children: _jsxs(Box, __assign({ display: "flex", flexDirection: "column" }, { children: [_jsx(Logo, {}), _jsx(Input, { autoComplete: "true", placeholder: "email", onChange: function (e) { return setEmail(e.target.value); }, value: email, marginBottom: 4 }), _jsx(Input, { autoComplete: "true", placeholder: "password", onChange: function (e) { return setPassword(e.target.value); }, value: password, type: "password", marginBottom: 4 }), _jsx(Button, __assign({ isLoading: status === APICallStatus.Loading, type: "submit", variant: "solid", marginBottom: 4, backgroundColor: "primary.500" }, { children: "Register" })), _jsxs(Text, { children: ["Already have an account?", _jsx(Box, __assign({ as: "span", color: "primary.500", marginLeft: 2 }, { children: _jsx(Link, __assign({ to: "/login" }, { children: "Login" })) }))] })] })) })) })));
};
