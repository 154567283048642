"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExponentialSmoothing = exports.ExponentialSmoothingVector3 = exports.ExponentialSmoothingQuat = void 0;
var quat_1 = require("./quat");
var ExponentialSmoothingQuat = /** @class */ (function () {
    function ExponentialSmoothingQuat(alpha) {
        this.alpha = alpha;
        this.previous = null;
        this.alpha = Math.max(0, Math.min(1, alpha));
    }
    ExponentialSmoothingQuat.prototype.smooth = function (q) {
        // First point
        if (this.previous === null) {
            this.previous = q;
        }
        else {
            this.previous = (0, quat_1.quatSlerp)(this.previous, q, this.alpha);
        }
        return this.previous;
    };
    return ExponentialSmoothingQuat;
}());
exports.ExponentialSmoothingQuat = ExponentialSmoothingQuat;
var ExponentialSmoothingVector3 = /** @class */ (function () {
    function ExponentialSmoothingVector3(alpha) {
        this.x = new ExponentialSmoothing(alpha);
        this.y = new ExponentialSmoothing(alpha);
        this.z = new ExponentialSmoothing(alpha);
    }
    ExponentialSmoothingVector3.prototype.smooth = function (v) {
        return {
            x: this.x.smooth(v.x),
            y: this.y.smooth(v.y),
            z: this.z.smooth(v.z),
        };
    };
    return ExponentialSmoothingVector3;
}());
exports.ExponentialSmoothingVector3 = ExponentialSmoothingVector3;
var ExponentialSmoothing = /** @class */ (function () {
    /*
     * Alpha is the degree at which the current
     * observation is considered
     */
    function ExponentialSmoothing(alpha) {
        this.alpha = alpha;
        this.previous = null;
        this.alpha = Math.max(0, Math.min(1, alpha));
    }
    ExponentialSmoothing.prototype.smooth = function (data) {
        // First point
        if (this.previous === null) {
            this.previous = data;
        }
        else {
            this.previous = this.alpha * data + (1 - this.alpha) * this.previous;
        }
        return this.previous;
    };
    return ExponentialSmoothing;
}());
exports.ExponentialSmoothing = ExponentialSmoothing;
