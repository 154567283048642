import {
  TouchEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Vector2 } from "realms-engine";
import { useClosestTouch } from "../hooks/useClosestTouch";

interface JoystickProps {
  size: number;
  knobSize?: number;
  knobFillColor?: string;
  knobStrokeColor?: string;
  onPositionChange?: (position: Vector2) => any;
}

export const Joystick = ({
  size,
  knobSize = size * 0.25,
  knobFillColor,
  knobStrokeColor,
  onPositionChange,
}: JoystickProps) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const { getClosestTouch } = useClosestTouch(canvasRef.current);
  const [currentTouchId, setCurrentTouchId] = useState<number | null>();

  const drawJoyStick = useCallback(
    (position: Vector2) => {
      if (canvasRef.current) {
        const ctx = canvasRef.current.getContext(
          "2d"
        ) as CanvasRenderingContext2D;
        ctx.clearRect(0, 0, size, size);

        // Draw base circle
        ctx.save();
        ctx.fillStyle = knobFillColor ?? "white";
        ctx.strokeStyle = knobStrokeColor ?? "white";
        ctx.beginPath();
        ctx.arc(
          size * 0.5,
          size * 0.5,
          size * 0.5 - ctx.lineWidth,
          0,
          Math.PI * 2
        );
        ctx.stroke();
        ctx.restore();

        // Draw Knob
        ctx.save();
        ctx.fillStyle = knobFillColor ?? "white";
        ctx.strokeStyle = knobStrokeColor ?? "white";
        ctx.beginPath();
        ctx.arc(
          size * 0.5 + position.x,
          size * 0.5 + position.y,
          knobSize,
          0,
          Math.PI * 2
        );
        ctx.stroke();
        ctx.restore();
      }
    },
    [knobFillColor, knobSize, knobStrokeColor, size]
  );

  const touchToJoystickPosition = useCallback(
    (touch: React.Touch): Vector2 | null => {
      const canvas = canvasRef.current;
      if (!canvas) {
        return null;
      }
      const bbRect = canvas.getBoundingClientRect();
      const joyStickCenter: Vector2 = {
        x: bbRect.x + 0.5 * bbRect.width,
        y: bbRect.y + 0.5 * bbRect.height,
      };
      // Clamp between the two offset values
      const clamp = (pos: number, offset: number): number => {
        return Math.max(-1 * Math.abs(offset), Math.min(pos, Math.abs(offset)));
      };
      const pos = {
        x: clamp(
          touch.clientX - joyStickCenter.x,
          0.5 * canvas.width - knobSize
        ),
        y: clamp(
          touch.clientY - joyStickCenter.y,
          0.5 * canvas.height - knobSize
        ),
      };
      return pos;
    },
    [knobSize]
  );

  useEffect(() => {
    drawJoyStick(position);
    if (onPositionChange) {
      onPositionChange(position);
    }
  }, [drawJoyStick, onPositionChange, position]);

  const onTouchStart: TouchEventHandler<HTMLCanvasElement> = (e) => {
    e.stopPropagation();
    if (currentTouchId !== null) {
      return;
    }
    const touch = getClosestTouch(e.touches);
    if (!touch) {
      return;
    }
    setCurrentTouchId(touch.identifier);
    const newPos = touchToJoystickPosition(touch);
    if (newPos) {
      setPosition(newPos);
    }
  };

  const onTouchEnd: TouchEventHandler<HTMLCanvasElement> = (e) => {
    e.stopPropagation();
    setPosition({ x: 0, y: 0 });
    // Remove current touch
    if (
      setCurrentTouchId !== null &&
      !Array.from(e.touches).find(
        (touch) => touch.identifier === currentTouchId
      )
    ) {
      setCurrentTouchId(null);
    }
  };

  const onTouchMove: TouchEventHandler<HTMLCanvasElement> = (e) => {
    e.stopPropagation();
    if (currentTouchId === null) {
      return;
    }
    const touch = Array.from(e.touches).find(
      (touch) => touch.identifier === currentTouchId
    );
    if (!touch) {
      return;
    }
    const newPos = touchToJoystickPosition(touch);
    if (newPos) {
      setPosition(newPos);
    }
  };

  return (
    <canvas
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
      ref={canvasRef}
      height={size}
      width={size}
    />
  );
};
