"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAssetFileExtension = exports.AssetFileType = void 0;
var AssetFileType;
(function (AssetFileType) {
    AssetFileType["FBX"] = "fbx";
    AssetFileType["PNG"] = "png";
    AssetFileType["JPG"] = "jpg";
    AssetFileType["GLTF"] = "gltf";
})(AssetFileType = exports.AssetFileType || (exports.AssetFileType = {}));
/*
 * A utility function to get the file extension of a filename
 */
function getAssetFileExtension(fileName) {
    var ext = fileName.split(".").slice(-1)[0];
    if (!Object.values(AssetFileType).includes(ext)) {
        throw new Error("Invalid file extension ".concat(ext));
    }
    return ext;
}
exports.getAssetFileExtension = getAssetFileExtension;
