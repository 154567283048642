"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRotatable = exports.rotatableSchema = void 0;
var realms_utils_1 = require("realms-utils");
exports.rotatableSchema = {
    getQuaternion: "function",
    setQuaternion: "function",
};
/**
 * Checks if rotatable
 */
exports.isRotatable = (0, realms_utils_1.isInterface)(exports.rotatableSchema);
