var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Heading } from "@chakra-ui/react";
import { SiPlanetscale } from "react-icons/si";
export var Logo = function (_a) {
    var onClick = _a.onClick;
    return (_jsxs(Box, __assign({ display: "flex", alignItems: "center", justifyContent: "center", padding: 4 }, { children: [_jsx(SiPlanetscale, { size: "2em" }), _jsx(Heading, __assign({ cursor: onClick ? "pointer" : "auto", size: "lg", onClick: onClick !== null && onClick !== void 0 ? onClick : (function () { }), variant: "h4", marginLeft: 4, marginRight: "1em" }, { children: "Realmsbase" }))] })));
};
