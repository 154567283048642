import { UIComponent, html } from "canvas-ui";
import { CreatureUIGameObject } from "./creatureUI.gameObject";
import { ChatMessage } from "mmo-common";

export interface CreatureUIConfig {
  fontColor?: string;
}

export const CreatureUI: UIComponent = (
  hooks,
  {
    gameObject,
    config = {},
  }: { gameObject: CreatureUIGameObject; config: CreatureUIConfig }
) => {
  const [health, setHealth] = hooks.useState(gameObject.getHealth());
  const [maxHealth, setMaxHealth] = hooks.useState(gameObject.getMaxHealth());
  const [lagHealth, setLagHealth] = hooks.useState(gameObject.getLagHealth());
  const [creatureName, setCreatureName] = hooks.useState(
    gameObject.getCreatureName()
  );
  const [showName, setShowName] = hooks.useState(gameObject.getShowName());
  const [showHealth, setShowHealth] = hooks.useState(
    gameObject.getShowHealth()
  );
  const [chatMessages, setChatMessages] = hooks.useState<ChatMessage[]>([]);

  hooks.useEffect(() => {
    const onHealthChange = (health: number) => {
      setHealth(health);
    };
    const onMaxHealthChange = (maxHealth: number) => setMaxHealth(maxHealth);
    const onLagHealthChange = (lagHealth: number) => setLagHealth(lagHealth);
    const onCreatureNameChange = (creatureName: string) =>
      setCreatureName(creatureName);
    const onChatMessage = (chatMessage: ChatMessage) => {
      setChatMessages((m) => [...m.slice(-2), chatMessage]);

      // Remove message later
      setTimeout(() => {
        setChatMessages((m) => m.filter((m) => m.id !== chatMessage.id));
      }, 3000);
    };
    const onShowNameChange = (v: boolean) => setShowName(v);
    const onShowHealthChange = (v: boolean) => setShowHealth(v);

    gameObject.addListener("healthChange", onHealthChange);
    gameObject.addListener("maxHealthChange", onMaxHealthChange);
    gameObject.addListener("lagHealthChange", onLagHealthChange);
    gameObject.addListener("creatureNameChange", onCreatureNameChange);
    gameObject.addListener("onChatMessage", onChatMessage);
    gameObject.addListener("showNameChange", onShowNameChange);
    gameObject.addListener("showHealthChange", onShowHealthChange);

    return () => {
      gameObject.removeListener("healthChange", onHealthChange);
      gameObject.removeListener("maxHealthChange", onMaxHealthChange);
      gameObject.removeListener("lagHealthChange", onLagHealthChange);
      gameObject.removeListener("creatureNameChange", onCreatureNameChange);
      gameObject.removeListener("onChatMessage", onChatMessage);
      gameObject.removeListener("showNameChange", onShowNameChange);
      gameObject.removeListener("showHealthChange", onShowHealthChange);
    };
  }, [gameObject]);

  return html`
    <view height="100%" width="100%" alignItems="center" justifyContent="end">
      <view height="100%" width="100%" />
      ${chatMessages.map(
        (message) => html`
          <view
            font="bold 16px arial"
            backgroundColor="#F7FAFC"
            borderColor="#171923"
            borderWidth="1px"
            padding="15px"
            borderRadius="5px"
            margin="5px"
          >
            ${message.message}
          </view>
        `
      )}
      ${showName
        ? html`
            <view
              width="100%"
              height="50px"
              alignItems="center"
              textAlign="center"
              font="bold 40px serif"
              color=${config.fontColor ?? "#C05621"}
            >
              ${creatureName}
            </view>
          `
        : null}
      ${showHealth
        ? html`
            <view
              borderWidth="2px"
              borderColor="#171923"
              height="15px"
              width="170px"
              alignItems="center"
              justifyContent="start"
              textAlign="center"
              flexDirection="row"
              backgroundColor="#cbd5e080"
            >
              <view
                backgroundColor="#FF1111"
                height="100%"
                width=${`${(100 * health) / maxHealth}%`}
              />
              <view
                backgroundColor="#ECC94B"
                height="100%"
                width=${`${
                  (100 * Math.max(lagHealth - health, 0)) / maxHealth
                }%`}
              />
            </view>
          `
        : null}
    </view>
  `;
};
