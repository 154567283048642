import { CharacterEyesType } from "mmo-common";
import { YellowSlashEffectsAssetMap } from "./slashEffects/yellowSlashEffectsAssetMap";
import { PurpleSlashEffectsAssetMap } from "./slashEffects/purpleSlashEffectsAssetMap";
import { BlueSlashEffectsAssetMap } from "./slashEffects/blueSlashEffectsAssetMap";
import { OrangeSlashEffectsAssetMap } from "./slashEffects/orangeSlashEffectsAssetMap";

export const TextureAssetMap = {
  [CharacterEyesType.Normal]: {
    fileName: "/assets/base_character/eyes/eyes_normal.png",
  },
  [CharacterEyesType.Cute]: {
    fileName: "/assets/base_character/eyes/eyes_cute.png",
  },
  [CharacterEyesType.Angry]: {
    fileName: "/assets/base_character/eyes/eyes_angry.png",
  },
  [CharacterEyesType.Sullen]: {
    fileName: "/assets/base_character/eyes/eyes_sullen.png",
  },
  ...YellowSlashEffectsAssetMap,
  ...PurpleSlashEffectsAssetMap,
  ...BlueSlashEffectsAssetMap,
  ...OrangeSlashEffectsAssetMap,
} as const;
