"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.viewNodePropsSchema = exports.isUINodeType = exports.UINodeType = void 0;
var realms_utils_1 = require("realms-utils");
var UINodeType;
(function (UINodeType) {
    UINodeType["View"] = "view";
})(UINodeType = exports.UINodeType || (exports.UINodeType = {}));
function isUINodeType(e) {
    return Object.values(UINodeType).includes(e);
}
exports.isUINodeType = isUINodeType;
var positionTypes = ["static", "relative", "absolute", "fixed"];
var flexDirectionTypes = ["column", "row"];
var textAlignTypes = ["center", "left", "right"];
var alignItemsTypes = ["center", "start", "end"];
var justifyContentTypes = [
    "center",
    "start",
    "end",
    "space-evenly",
    "space-between",
];
exports.viewNodePropsSchema = {
    position: realms_utils_1.v.string([(0, realms_utils_1.isOptional)(), (0, realms_utils_1.isIn)(positionTypes)]),
    flexDirection: realms_utils_1.v.string([(0, realms_utils_1.isIn)(flexDirectionTypes), (0, realms_utils_1.isOptional)()]),
    height: realms_utils_1.v.string([(0, realms_utils_1.isOptional)()]),
    width: realms_utils_1.v.string([(0, realms_utils_1.isOptional)()]),
    backgroundColor: realms_utils_1.v.string([(0, realms_utils_1.isOptional)()]),
    borderColor: realms_utils_1.v.string([(0, realms_utils_1.isOptional)()]),
    borderWidth: realms_utils_1.v.string([(0, realms_utils_1.isOptional)()]),
    borderRadius: realms_utils_1.v.string([(0, realms_utils_1.isOptional)()]),
    padding: realms_utils_1.v.string([(0, realms_utils_1.isOptional)()]),
    margin: realms_utils_1.v.string([(0, realms_utils_1.isOptional)()]),
    font: realms_utils_1.v.string([(0, realms_utils_1.isOptional)()]),
    color: realms_utils_1.v.string([(0, realms_utils_1.isOptional)()]),
    strokeColor: realms_utils_1.v.string([(0, realms_utils_1.isOptional)()]),
    strokeWidth: realms_utils_1.v.number([(0, realms_utils_1.isOptional)()]),
    textAlign: realms_utils_1.v.string([(0, realms_utils_1.isOptional)(), (0, realms_utils_1.isIn)(textAlignTypes)]),
    alignItems: realms_utils_1.v.string([(0, realms_utils_1.isOptional)(), (0, realms_utils_1.isIn)(alignItemsTypes)]),
    justifyContent: realms_utils_1.v.string([(0, realms_utils_1.isOptional)(), (0, realms_utils_1.isIn)(justifyContentTypes)]),
};
