var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { APICallStatus, useAPI, useAuth, useErrorToast } from "realmsbase-sdk";
import { useNavigate } from "react-router-dom";
import { Box, Button, Card, Table, TableContainer, Tbody, Th, Thead, Tr, } from "@chakra-ui/react";
import { APP_BAR_HEIGHT, RealmsAppBar } from "../components/RealmsbaseAppBar";
export var ScriptsSearchPage = function () {
    var sessionToken = useAuth({ redirectIfLoggedOut: true }).sessionToken;
    var navigate = useNavigate();
    var _a = useAPI("scriptSearch", "get", {
        auth: sessionToken,
        autoFetch: true,
    }), data = _a.data, error = _a.error, status = _a.status;
    useErrorToast(error);
    return (_jsxs(Box, __assign({ display: "flex", flexDir: "column", width: "100%", marginTop: APP_BAR_HEIGHT, padding: 4 }, { children: [_jsx(RealmsAppBar, {}), _jsx(Box, __assign({ width: "100%", display: "flex", justifyContent: "flex-end", padding: 4 }, { children: _jsx(Button, __assign({ onClick: function () { return navigate("/script/new"); } }, { children: "Create Script" })) })), status === APICallStatus.Idle && data ? (_jsx(Card, __assign({ width: "100%" }, { children: _jsx(TableContainer, { children: _jsxs(Table, __assign({ variant: "simple" }, { children: [_jsx(Thead, { children: _jsxs(Tr, { children: [_jsx(Th, { children: "Id" }), _jsx(Th, { children: "Script Name" })] }) }), _jsx(Tbody, { children: data.scripts.map(function (script) {
                                    return (_jsxs(Tr, __assign({ cursor: "pointer", onClick: function () { return navigate("/script/".concat(script.id)); }, _hover: {
                                            backgroundColor: "gray.100",
                                        } }, { children: [_jsx(Th, { children: script.id }), _jsx(Th, { children: script.name })] }), script.id));
                                }) })] })) }) }))) : null] })));
};
