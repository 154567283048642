import { CharacterGender } from "mmo-common";
import { threeFindByName } from "realms-three-utils";
import { smoothCharacterGeometry } from "./characterUtils";

export interface CharacterPartsMap {
  EarLeft: THREE.SkinnedMesh;
  EarRight: THREE.SkinnedMesh;
  Head: THREE.SkinnedMesh;
  Neck: THREE.SkinnedMesh;
  Nose: THREE.SkinnedMesh;
  FeetLeft: THREE.SkinnedMesh;
  LowerLegLeft: THREE.SkinnedMesh;
  UpperLegLeft: THREE.SkinnedMesh;
  FeetRight: THREE.SkinnedMesh;
  LowerLegRight: THREE.SkinnedMesh;
  UpperLegRight: THREE.SkinnedMesh;
  LowerBody: THREE.SkinnedMesh;
  HandLeft: THREE.SkinnedMesh;
  LowerArmLeft: THREE.SkinnedMesh;
  UpperArmLeft: THREE.SkinnedMesh;
  HandRight: THREE.SkinnedMesh;
  LowerArmRight: THREE.SkinnedMesh;
  UpperArmRight: THREE.SkinnedMesh;
  UpperBody: THREE.SkinnedMesh;
  mixamorigHips: THREE.Object3D;
}

/*
 * Build the character parts map
 */
export function buildCharacterPartsMap(
  baseCharacterMesh: THREE.Object3D,
  gender: CharacterGender
): CharacterPartsMap {
  // Build character map
  const suffix = gender === CharacterGender.Male ? "Male" : "Female";
  const partsMap: CharacterPartsMap = {
    EarLeft: threeFindByName(baseCharacterMesh, "EarLeft") as THREE.SkinnedMesh,
    EarRight: threeFindByName(
      baseCharacterMesh,
      "EarRight"
    ) as THREE.SkinnedMesh,
    Nose: threeFindByName(baseCharacterMesh, "Nose") as THREE.SkinnedMesh,
    Head: threeFindByName(baseCharacterMesh, "Head") as THREE.SkinnedMesh,
    Neck: threeFindByName(
      baseCharacterMesh,
      `${suffix}Neck`
    ) as THREE.SkinnedMesh,
    UpperBody: threeFindByName(
      baseCharacterMesh,
      `${suffix}UpperBody`
    ) as THREE.SkinnedMesh,
    LowerBody: threeFindByName(
      baseCharacterMesh,
      `${suffix}LowerBody`
    ) as THREE.SkinnedMesh,
    UpperArmLeft: threeFindByName(
      baseCharacterMesh,
      `${suffix}UpperArmLeft`
    ) as THREE.SkinnedMesh,
    UpperArmRight: threeFindByName(
      baseCharacterMesh,
      `${suffix}UpperArmRight`
    ) as THREE.SkinnedMesh,
    LowerArmLeft: threeFindByName(
      baseCharacterMesh,
      `${suffix}LowerArmLeft`
    ) as THREE.SkinnedMesh,
    LowerArmRight: threeFindByName(
      baseCharacterMesh,
      `${suffix}LowerArmRight`
    ) as THREE.SkinnedMesh,
    HandLeft: threeFindByName(
      baseCharacterMesh,
      `${suffix}HandLeft`
    ) as THREE.SkinnedMesh,
    HandRight: threeFindByName(
      baseCharacterMesh,
      `${suffix}HandRight`
    ) as THREE.SkinnedMesh,
    UpperLegLeft: threeFindByName(
      baseCharacterMesh,
      `${suffix}UpperLegLeft`
    ) as THREE.SkinnedMesh,
    UpperLegRight: threeFindByName(
      baseCharacterMesh,
      `${suffix}UpperLegRight`
    ) as THREE.SkinnedMesh,
    LowerLegLeft: threeFindByName(
      baseCharacterMesh,
      `${suffix}LowerLegLeft`
    ) as THREE.SkinnedMesh,
    LowerLegRight: threeFindByName(
      baseCharacterMesh,
      `${suffix}LowerLegRight`
    ) as THREE.SkinnedMesh,
    FeetLeft: threeFindByName(
      baseCharacterMesh,
      `${suffix}FeetLeft`
    ) as THREE.SkinnedMesh,
    FeetRight: threeFindByName(
      baseCharacterMesh,
      `${suffix}FeetRight`
    ) as THREE.SkinnedMesh,
    mixamorigHips: threeFindByName(
      baseCharacterMesh,
      `mixamorigHips`
    ) as THREE.SkinnedMesh,
  };

  // Smooth it
  smoothCharacterGeometry(partsMap);
  return partsMap;
}
