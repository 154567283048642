"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IndexedDBManager = void 0;
var IndexedDBManager = /** @class */ (function () {
    function IndexedDBManager(databaseName, storeSchema) {
        this.databaseName = databaseName;
        this.storeSchema = storeSchema;
        this.db = null;
    }
    /*
     * Adds a value to the store
     */
    IndexedDBManager.prototype.addToStore = function (storeName, value) {
        return __awaiter(this, void 0, void 0, function () {
            var db, transaction, store;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDB()];
                    case 1:
                        db = _a.sent();
                        transaction = db.transaction(storeName, "readwrite");
                        store = transaction.objectStore(storeName);
                        store.add(value);
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                transaction.oncomplete = resolve;
                                transaction.onerror = reject;
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /*
     * Puts to the store
     */
    IndexedDBManager.prototype.putToStore = function (storeName, value) {
        return __awaiter(this, void 0, void 0, function () {
            var db, transaction, store;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDB()];
                    case 1:
                        db = _a.sent();
                        transaction = db.transaction(storeName, "readwrite");
                        store = transaction.objectStore(storeName);
                        store.put(value);
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                transaction.oncomplete = resolve;
                                transaction.onerror = reject;
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /*
     * Updates the store
     */
    IndexedDBManager.prototype.updateStore = function (storeName, value) {
        return __awaiter(this, void 0, void 0, function () {
            var db, transaction, store;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDB()];
                    case 1:
                        db = _a.sent();
                        transaction = db.transaction(storeName, "readwrite");
                        store = transaction.objectStore(storeName);
                        store.put(value);
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                transaction.oncomplete = resolve;
                                transaction.onerror = reject;
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /*
     * Get from store
     */
    IndexedDBManager.prototype.getFromStore = function (storeName, key) {
        return __awaiter(this, void 0, void 0, function () {
            var db, transaction, store;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDB()];
                    case 1:
                        db = _a.sent();
                        transaction = db.transaction(storeName, "readwrite");
                        store = transaction.objectStore(storeName);
                        return [4 /*yield*/, new Promise(function (resolve) {
                                var getRequest = store.get(key);
                                getRequest.onsuccess = function (e) {
                                    resolve(getRequest.result);
                                };
                            })];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /*
     * Delete from store
     */
    IndexedDBManager.prototype.deleteFromStore = function (storeName, key) {
        return __awaiter(this, void 0, void 0, function () {
            var db, transaction, store;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDB()];
                    case 1:
                        db = _a.sent();
                        transaction = db.transaction(storeName, "readwrite");
                        store = transaction.objectStore(storeName);
                        return [4 /*yield*/, new Promise(function (resolve, reject) {
                                store.delete(key);
                                transaction.oncomplete = function () { return resolve(); };
                                transaction.onerror = function () { return reject(); };
                            })];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /*
     * Get all store keys
     */
    IndexedDBManager.prototype.getAllStoreKeys = function (storeName) {
        return __awaiter(this, void 0, void 0, function () {
            var db, transaction, store;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDB()];
                    case 1:
                        db = _a.sent();
                        transaction = db.transaction(storeName, "readwrite");
                        store = transaction.objectStore(storeName);
                        return [4 /*yield*/, new Promise(function (resolve) {
                                var req = store.getAllKeys();
                                req.onsuccess = function (e) {
                                    resolve(req.result);
                                };
                            })];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /*
     * Get all from store
     */
    IndexedDBManager.prototype.getAllFromStore = function (storeName) {
        return __awaiter(this, void 0, void 0, function () {
            var db, transaction, store;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getDB()];
                    case 1:
                        db = _a.sent();
                        transaction = db.transaction(storeName, "readwrite");
                        store = transaction.objectStore(storeName);
                        return [4 /*yield*/, new Promise(function (resolve) {
                                var req = store.getAll();
                                req.onsuccess = function (e) {
                                    resolve(req.result);
                                };
                            })];
                    case 2: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /*
     * Connects to indexedDB
     */
    IndexedDBManager.prototype.getDB = function () {
        var _this = this;
        return new Promise(function (resolve) {
            // Already exists
            if (_this.db !== null) {
                resolve(_this.db);
                return;
            }
            var request = indexedDB.open(_this.databaseName, 1);
            /*
             * Success
             */
            request.onsuccess = function (e) {
                _this.db = e.target.result;
                resolve(e.target.result);
            };
            /*
             * Update
             */
            request.onupgradeneeded = function (e) {
                var e_1, _a;
                var db = e.target.result;
                try {
                    for (var _b = __values(Object.entries(_this.storeSchema)), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var _d = __read(_c.value, 2), store = _d[0], options = _d[1];
                        db.createObjectStore(store, options);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            };
        });
    };
    return IndexedDBManager;
}());
exports.IndexedDBManager = IndexedDBManager;
