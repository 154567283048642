"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSyncMethod = exports.isSyncSchema = exports.SyncMethod = void 0;
var SyncMethod;
(function (SyncMethod) {
    // Linearly interpolates
    // ie: If value is from 0 to 1 it will go somewhere in between linearly based on time
    SyncMethod["Linear"] = "linear";
    // Same as linear except it ensures that the interpolated values are whole numbers
    SyncMethod["LinearWhole"] = "linearWhole";
    // Interpolates discretely
    // ie: if value is from 0 to 1, if time is greater than 0.5 it will 1 otherwise 0.
    SyncMethod["DiscreteMiddle"] = "discreteMiddle";
    // Similar to DiscreteMiddle except the value will be 1 if time isn't 0
    SyncMethod["DiscreteStart"] = "discreteStart";
    // Opposite of DiscreteStart: value will be 0 unless time is 1
    SyncMethod["DiscreteEnd"] = "discreteEnd";
})(SyncMethod = exports.SyncMethod || (exports.SyncMethod = {}));
function isSyncSchema(s) {
    var e_1, _a;
    if (typeof s !== "object" || Array.isArray(s) || s === null) {
        return false;
    }
    try {
        for (var _b = __values(Object.values(s)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var value = _c.value;
            if (!isSyncMethod(value) && !isSyncSchema(value)) {
                return false;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return true;
}
exports.isSyncSchema = isSyncSchema;
function isSyncMethod(s) {
    return Object.values(SyncMethod).includes(s);
}
exports.isSyncMethod = isSyncMethod;
