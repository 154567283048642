"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventManager = void 0;
var EventManager = /** @class */ (function () {
    function EventManager() {
        var _this = this;
        /*
         * Maps the event to the set of listeners
         */
        this.eventListener = new Map();
        this.anyEventListeners = new Set();
        /*
         * Adds an event listener
         */
        this.addListener = function (event, listener) {
            var listenerSet = _this.eventListener.get(event);
            if (listenerSet) {
                listenerSet.add(listener);
            }
            else {
                _this.eventListener.set(event, new Set([listener]));
            }
        };
        /*
         * Removes an event listener
         */
        this.removeListener = function (event, listener) {
            var _a;
            (_a = _this.eventListener.get(event)) === null || _a === void 0 ? void 0 : _a.delete(listener);
        };
        /*
         * Calls on all events
         */
        this.addAnyListener = function (listener) {
            _this.anyEventListeners.add(listener);
        };
        /**
         * Listens to event only once
         */
        this.once = function (event, listener) {
            var wrappedListener = function (v) {
                listener(v);
                _this.removeListener(event, wrappedListener);
            };
            _this.addListener(event, wrappedListener);
        };
        /*
         * Removes an any listener
         */
        this.removeAnyListener = function (listener) {
            _this.anyEventListeners.delete(listener);
        };
        /*
         * Dispatches an event
         */
        this.dispatch = function (event, value) {
            var _a;
            (_a = _this.eventListener.get(event)) === null || _a === void 0 ? void 0 : _a.forEach(function (listener) { return listener(value); });
            _this.anyEventListeners.forEach(function (listener) { return listener(event, value); });
        };
    }
    /*
     * Casts as an event emitter
     */
    EventManager.prototype.asEventEmitter = function () {
        return this;
    };
    return EventManager;
}());
exports.EventManager = EventManager;
