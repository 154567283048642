"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mergeChanges = void 0;
var lodash_1 = require("lodash");
function mergeChanges(state, changes) {
    return (0, lodash_1.mergeWith)((0, lodash_1.cloneDeep)(state), changes, function (_, srcValue) {
        if ((0, lodash_1.isArray)(srcValue)) {
            return srcValue;
        }
        return;
    });
}
exports.mergeChanges = mergeChanges;
