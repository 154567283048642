"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDataObjectTypeConfig = void 0;
var realmsEditor_model_1 = require("./realmsEditor.model");
function getDataObjectTypeConfig(type) {
    switch (type) {
        case realmsEditor_model_1.DataObjectType.Box: {
            return {
                canSetMaterial: true,
                canSetGeometry: true,
                canSetShapeProperties: true,
                canHaveChildren: true,
                bubblesClick: false,
            };
        }
        case realmsEditor_model_1.DataObjectType.Sphere: {
            return {
                canSetMaterial: true,
                canSetGeometry: true,
                canSetShapeProperties: true,
                canHaveChildren: true,
                bubblesClick: false,
            };
        }
        case realmsEditor_model_1.DataObjectType.Cylinder: {
            return {
                canSetMaterial: true,
                canSetGeometry: true,
                canSetShapeProperties: true,
                canHaveChildren: true,
                bubblesClick: false,
            };
        }
        case realmsEditor_model_1.DataObjectType.Group: {
            return {
                canSetMaterial: false,
                canSetGeometry: false,
                canSetShapeProperties: false,
                canHaveChildren: true,
                bubblesClick: false,
            };
        }
        case realmsEditor_model_1.DataObjectType.BlueprintRef: {
            return {
                canSetMaterial: false,
                canSetGeometry: false,
                canSetShapeProperties: false,
                canHaveChildren: false,
                bubblesClick: true,
            };
        }
        case realmsEditor_model_1.DataObjectType.HemisphereLight: {
            return {
                canSetMaterial: false,
                canSetGeometry: false,
                canSetShapeProperties: false,
                canHaveChildren: true,
                bubblesClick: true,
            };
        }
        case realmsEditor_model_1.DataObjectType.AmbientLight: {
            return {
                canSetMaterial: false,
                canSetGeometry: false,
                canSetShapeProperties: false,
                canHaveChildren: true,
                bubblesClick: true,
            };
        }
        case realmsEditor_model_1.DataObjectType.DirectionalLight: {
            return {
                canSetMaterial: false,
                canSetGeometry: false,
                canSetShapeProperties: false,
                canHaveChildren: true,
                bubblesClick: true,
            };
        }
        case realmsEditor_model_1.DataObjectType.PluginDataObject: {
            return {
                canSetMaterial: false,
                canSetGeometry: false,
                canSetShapeProperties: false,
                canHaveChildren: false,
                bubblesClick: true,
            };
        }
        case realmsEditor_model_1.DataObjectType.Sprite: {
            return {
                canSetMaterial: false,
                canSetGeometry: false,
                canSetShapeProperties: false,
                canHaveChildren: true,
                bubblesClick: false,
            };
        }
    }
}
exports.getDataObjectTypeConfig = getDataObjectTypeConfig;
