import { extendTheme } from "@chakra-ui/react";

export const MMOTheme = extendTheme({
  colors: {
    primary: {
      500: "#D45079",
    },
    secondary: {
      500: "#F5C246",
    },
    secondaryAlpha: {
      50: "rgba(245,194,70,0.05)",
      500: "rgba(245,194,70,0.5)",
    },
    card: {
      100: "#867369",
      300: "#535144",
      500: "#321E1E",
      800: "#120D0A",
    },
  },

  gameObjects: {
    Input: {
      defaultProps: {
        focusBorderColor: "secondary.500",
      },
    },
  },
});
