"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isEmptyNetworkRealmStateChange = void 0;
/*
 * Returns whether a realm change is empty or not
 */
function isEmptyNetworkRealmStateChange(change) {
    return (change.creates.length === 0 &&
        change.changes.length === 0 &&
        change.deletes.length === 0);
}
exports.isEmptyNetworkRealmStateChange = isEmptyNetworkRealmStateChange;
