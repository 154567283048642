"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Overlay = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@chakra-ui/react");
/**
 * Overlays the parent component. The parent component
 * must be set to relative
 */
var Overlay = function (_a) {
    var zIndex = _a.zIndex, color = _a.color, props = __rest(_a, ["zIndex", "color"]);
    return ((0, jsx_runtime_1.jsx)(react_1.Box, __assign({ backgroundColor: color !== null && color !== void 0 ? color : "blackAlpha.700", bottom: "0px", left: "0px", position: "absolute", right: "0px", top: "0px", zIndex: zIndex !== null && zIndex !== void 0 ? zIndex : 0 }, props)));
};
exports.Overlay = Overlay;
