"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScreenSize = void 0;
var react_1 = require("react");
var react_rxjs_toolkit_1 = require("react-rxjs-toolkit");
var utils_1 = require("../utils");
/*
 * Gets the current screen size
 */
var useScreenSize = function () {
    var screenSizeListenerRef = (0, react_1.useRef)(new utils_1.ScreenSizeListener());
    var _a = __read((0, react_rxjs_toolkit_1.useObservable)(screenSizeListenerRef.current.screenSize$, screenSizeListenerRef.current.calculateScreenSize(window.innerWidth, window.innerHeight)), 1), screenSize = _a[0];
    (0, react_1.useEffect)(function () {
        var listener = screenSizeListenerRef.current;
        listener.subscribe();
        return function () {
            listener.unsubscribe();
        };
    }, []);
    return { screenSize: screenSize, isDesktop: screenSize === utils_1.ScreenSize.Desktop };
};
exports.useScreenSize = useScreenSize;
