import { useCallback } from "react";
import { Vector2 } from "realms-engine";

/*
 * Gets closest touch to the given domelement
 */
export const useClosestTouch = (domElement: HTMLElement | null) => {
  const getClosestTouch = useCallback(
    (touches: React.TouchList): React.Touch | undefined => {
      if (domElement === null) {
        return;
      }
      const rect = domElement.getBoundingClientRect();
      const center: Vector2 = {
        x: (rect.right + rect.left) / 2,
        y: (rect.top + rect.bottom) / 2,
      };
      let closestDistance: number | undefined;
      let closestTouch = touches[0];
      for (let i = 0; i < touches.length; i++) {
        const touch = touches.item(i)!;
        const distance =
          Math.pow(touch.clientX - center.x, 2) +
          Math.pow(touch.clientY - center.y, 2);
        if (closestDistance === undefined || distance < closestDistance) {
          closestDistance = distance;
          closestTouch = touch;
        }
      }
      return closestTouch;
    },
    [domElement]
  );
  return { getClosestTouch };
};
