"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DesktopComponent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var hooks_1 = require("../hooks");
var utils_1 = require("../utils");
/*
 * A component that only shows up on desktop
 */
var DesktopComponent = function (_a) {
    var children = _a.children;
    var screenSize = (0, hooks_1.useScreenSize)().screenSize;
    if (screenSize === utils_1.ScreenSize.Desktop) {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children });
    }
    return null;
};
exports.DesktopComponent = DesktopComponent;
