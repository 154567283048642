"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createNetworkPhysicsOutlineFactoryMap = void 0;
var networkPhysicsOutlineGameObject_1 = require("./networkPhysicsOutlineGameObject");
/*
 * Creates the network slave factory map for the network physics outline gameObject
 */
function createNetworkPhysicsOutlineFactoryMap() {
    return {
        networkPhysicsOutline: function (state) {
            return new networkPhysicsOutlineGameObject_1.NetworkPhysicsOutlineGameObject(state);
        },
    };
}
exports.createNetworkPhysicsOutlineFactoryMap = createNetworkPhysicsOutlineFactoryMap;
