import {
  GameObject,
  Quat,
  Scene,
  TimerGameObject,
  Vector3,
} from "realms-engine";
import { AnimatedPlaneTextureGameObject } from "../../utils/animatedPlaneTexture.gameObject";
import { getSlashEffectFrames } from "./getSlashEffectFrames";

export enum SlashEffectColor {
  Yellow = "yellow",
  Purple = "purple",
  Blue = "blue",
  Orange = "orange",
}

export enum SlashEffectType {
  ArcLarge = "arcLarge",
  ArcMedium = "arcMedium",
  ArcSmall = "arcSmall",
  Bolt = "bolt",
  Straight = "straight",
}

export class SlashEffectGameObject extends GameObject {
  private animatedPlaneTexture: AnimatedPlaneTextureGameObject;
  private delayTimer: TimerGameObject;
  private delay: number;

  constructor({
    type,
    color,
    width,
    height,
    duration,
    delay,
    reverse,
  }: {
    type: SlashEffectType;
    color: SlashEffectColor;
    width: number;
    height: number;
    duration: number;
    delay?: number;
    reverse?: boolean;
  }) {
    super();
    this.delay = delay ?? 0;
    const frames = getSlashEffectFrames({
      color,
      type,
      frameDuration: duration / 6.0,
    });
    if (reverse) {
      frames.reverse();
    }
    this.animatedPlaneTexture = new AnimatedPlaneTextureGameObject({
      width,
      height,
      frames,
    });
    this.delayTimer = this.addChild(new TimerGameObject());
  }

  getPosition() {
    return this.animatedPlaneTexture.getPosition();
  }

  setPosition(v: Vector3) {
    this.animatedPlaneTexture.setPosition(v);
  }

  getRotation() {
    return this.animatedPlaneTexture.getRotation();
  }

  setRotation(r: Vector3) {
    this.animatedPlaneTexture.setRotation(r);
  }

  getQuaternion() {
    return this.animatedPlaneTexture.getQuaternion();
  }

  setQuaternion(q: Quat) {
    this.animatedPlaneTexture.setQuaternion(q);
  }

  private onDelayEnd = () => {
    this.addChild(this.animatedPlaneTexture);
    this.animatedPlaneTexture.play();
  };

  private onEnd = () => {
    this.queueRemoveFromParent();
  };

  override onInit(scene: Scene): void {
    this.delayTimer.addListener("onFinish", this.onDelayEnd);
    this.animatedPlaneTexture.addListener("onEnd", this.onEnd);
    this.delayTimer.start(this.delay);
  }

  onRemove(scene: Scene): void {
    this.delayTimer.removeListener("onFinish", this.onDelayEnd);
    this.animatedPlaneTexture.removeListener("onEnd", this.onEnd);
  }
}
