"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Glow = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@chakra-ui/react");
var react_2 = require("react");
var realms_utils_1 = require("realms-utils");
var Glow = function (_a) {
    var _b = _a.color, color = _b === void 0 ? "gray.50" : _b, props = __rest(_a, ["color"]);
    var _c = __read((0, react_1.useToken)("colors", [color]), 1), hexColor = _c[0];
    var rgb = (0, react_2.useMemo)(function () { return (0, realms_utils_1.hexToRgb)(hexColor); }, [color]);
    return ((0, jsx_runtime_1.jsx)(react_1.Box, __assign({ borderRadius: "full", background: "radial-gradient(circle, rgba(".concat(rgb.r, ",").concat(rgb.g, ", ").concat(rgb.b, ",1) 0%, rgba(").concat(rgb.r, ",").concat(rgb.g, ", ").concat(rgb.b, ",0) 100%") }, props)));
};
exports.Glow = Glow;
