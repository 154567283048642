"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatureType = exports.CreatureAnimation = void 0;
var CreatureAnimation;
(function (CreatureAnimation) {
    CreatureAnimation["Walk"] = "walk";
    CreatureAnimation["Idle"] = "idle";
    CreatureAnimation["Jump"] = "jump";
    CreatureAnimation["Death"] = "death";
    CreatureAnimation["NormalAttack1"] = "attack1";
    CreatureAnimation["NormalAttack2"] = "attack2";
    CreatureAnimation["NormalAttack3"] = "attack3";
})(CreatureAnimation || (exports.CreatureAnimation = CreatureAnimation = {}));
var CreatureType;
(function (CreatureType) {
    CreatureType["Character"] = "character";
    CreatureType["Player"] = "player";
    CreatureType["Goblin"] = "goblin";
})(CreatureType || (exports.CreatureType = CreatureType = {}));
