"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hexToRgb = exports.hexStringToNum = void 0;
function hexStringToNum(hex) {
    return parseInt(hex.replace(/^#/, ""), 16);
}
exports.hexStringToNum = hexStringToNum;
/*
 * Converts hex to rgb
 */
function hexToRgb(hex) {
    hex = hex.replace("#", "");
    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);
    return { r: r, g: g, b: b };
}
exports.hexToRgb = hexToRgb;
