"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getParamDefinitionConstraints = exports.isLessEqualThan = exports.isGreaterEqualThan = exports.isIn = exports.matches = exports.isNullable = exports.isOptional = exports.isLength = void 0;
var models_1 = require("./models");
function isLength(constraint) {
    return new models_1.ValidationParamConstraint(models_1.ValidationParamConstraintType.IsLength, constraint);
}
exports.isLength = isLength;
function isOptional() {
    return new models_1.ValidationParamConstraint(models_1.ValidationParamConstraintType.IsOptional, undefined);
}
exports.isOptional = isOptional;
function isNullable() {
    return new models_1.ValidationParamConstraint(models_1.ValidationParamConstraintType.IsNullable, undefined);
}
exports.isNullable = isNullable;
// Enforces it matches the given regex
function matches(regex) {
    return new models_1.ValidationParamConstraint(models_1.ValidationParamConstraintType.Matches, regex);
}
exports.matches = matches;
// Enforces the value to be one of the given values
function isIn(values) {
    return new models_1.ValidationParamConstraint(models_1.ValidationParamConstraintType.IsIn, values);
}
exports.isIn = isIn;
function isGreaterEqualThan(num) {
    return new models_1.ValidationParamConstraint(models_1.ValidationParamConstraintType.IsGreaterEqualThan, num);
}
exports.isGreaterEqualThan = isGreaterEqualThan;
function isLessEqualThan(num) {
    return new models_1.ValidationParamConstraint(models_1.ValidationParamConstraintType.IsLessEqualThan, num);
}
exports.isLessEqualThan = isLessEqualThan;
function getParamDefinitionConstraints(def) {
    var _a;
    if ("constraints" in def) {
        return (_a = def.constraints) !== null && _a !== void 0 ? _a : [];
    }
    return [];
}
exports.getParamDefinitionConstraints = getParamDefinitionConstraints;
