"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.prettyName = void 0;
/**
 * Prettifies the name
 */
function prettyName(name) {
    if (name.length <= 1) {
        return name.toUpperCase();
    }
    var firstLetter = name[0];
    if (!firstLetter) {
        return name;
    }
    return firstLetter.toUpperCase() + name.slice(1).toLowerCase();
}
exports.prettyName = prettyName;
