"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthErrorType = exports.DefaultRole = exports.isSessionToken = void 0;
/**
 * Verifies something is a session token
 */
function isSessionToken(o) {
    if (typeof o !== "object") {
        return false;
    }
    if (o === null) {
        return false;
    }
    if (Array.isArray(o)) {
        return false;
    }
    if (typeof o["accountId"] !== "number") {
        return false;
    }
    if (typeof o["token"] !== "string") {
        return false;
    }
    if (typeof o["expiry"] !== "number") {
        return false;
    }
    return true;
}
exports.isSessionToken = isSessionToken;
var DefaultRole;
(function (DefaultRole) {
    DefaultRole["Admin"] = "admin";
})(DefaultRole = exports.DefaultRole || (exports.DefaultRole = {}));
var AuthErrorType;
(function (AuthErrorType) {
    AuthErrorType["InvalidEmail"] = "invalid email";
    AuthErrorType["InvalidPassword"] = "invalid password";
    AuthErrorType["InvalidToken"] = "invalid token";
    AuthErrorType["EmailAlreadyExists"] = "email already exists";
    AuthErrorType["ExpiredToken"] = "expired token";
    AuthErrorType["InvalidRole"] = "invalid role";
})(AuthErrorType = exports.AuthErrorType || (exports.AuthErrorType = {}));
