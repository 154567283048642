import { MMONetSchema, MMOServerEvent } from "mmo-common";
import { MMOGameClientService } from "../mmo-game-client";
import { EventEmitter, EventManager } from "realms-utils";
import { BehaviorSubject } from "rxjs";

interface ChatServiceEventsMap {
  [MMOServerEvent.ChatMessage]: MMONetSchema["NMap"][MMOServerEvent.ChatMessage];
}

export class ChatService implements EventEmitter<ChatServiceEventsMap> {
  private eventManager = new EventManager<ChatServiceEventsMap>();
  addListener = this.eventManager.addListener;
  removeListener = this.eventManager.removeListener;
  once = this.eventManager.once;
  private chatIsFocusedSubject$ = new BehaviorSubject(false);
  readonly chatIsFocused$ = this.chatIsFocusedSubject$.asObservable();

  constructor(private mmoClientService: MMOGameClientService) {
    this.mmoClientService.addListener(MMOServerEvent.ChatMessage, (e) =>
      this.eventManager.dispatch(MMOServerEvent.ChatMessage, e)
    );
  }

  setChatIsFocused(focused: boolean) {
    this.chatIsFocusedSubject$.next(focused);
  }
}
