"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAssetFileName = void 0;
/**
 * Gets the generated asset filename
 */
var getAssetFileName = function (asset) {
    var _a;
    var ext = "." + ((_a = asset.type.split("/")[1]) !== null && _a !== void 0 ? _a : "");
    return asset.name.replace(ext, "") + ext;
};
exports.getAssetFileName = getAssetFileName;
