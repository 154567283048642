"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractDataObjectBuilder = void 0;
var DataObjectBuilderUtils_1 = require("./DataObjectBuilderUtils");
var AbstractDataObjectBuilder = /** @class */ (function () {
    function AbstractDataObjectBuilder() {
        this.utils = new DataObjectBuilderUtils_1.DataObjectBuilderUtils();
    }
    return AbstractDataObjectBuilder;
}());
exports.AbstractDataObjectBuilder = AbstractDataObjectBuilder;
