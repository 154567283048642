"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Physics3DSystem = void 0;
var core_1 = require("../core");
var cannon_1 = require("./cannon");
var errors_1 = require("./errors");
var physics3DBodyGameObject_1 = require("./physics3DBodyGameObject");
var Physics3DSystem = /** @class */ (function (_super) {
    __extends(Physics3DSystem, _super);
    function Physics3DSystem(_a) {
        var _this = this;
        var gravity = _a.gravity, options = __rest(_a, ["gravity"]);
        _this = _super.call(this, [physics3DBodyGameObject_1.Physics3DBodyGameObject]) || this;
        _this.cannonBodyToGameObject = new Map();
        _this.gameStepEndCallbacks = [];
        var cannonGravity = gravity
            ? new cannon_1.CANNON.Vec3(gravity.x, gravity.y, gravity.z)
            : undefined;
        _this.world = new cannon_1.CANNON.World(__assign(__assign({}, options), (cannonGravity ? { gravity: cannonGravity } : {})));
        return _this;
    }
    Physics3DSystem.prototype.getCANNONWorld = function () {
        return this.world;
    };
    Physics3DSystem.prototype.getCannonBodyGameObject = function (body) {
        var gameObject = this.cannonBodyToGameObject.get(body);
        if (gameObject === undefined) {
            throw new errors_1.UntrackedCannonBodyError("Untracked cannon body found: ".concat(JSON.stringify(body)));
        }
        return gameObject;
    };
    Physics3DSystem.prototype.onTargetGameObjectEnter = function (gameObject) {
        if (gameObject instanceof physics3DBodyGameObject_1.Physics3DBodyGameObject) {
            var cannonBody = gameObject.getCANNONBody();
            this.world.addBody(cannonBody);
            this.cannonBodyToGameObject.set(cannonBody, gameObject);
        }
    };
    Physics3DSystem.prototype.onTargetGameObjectExit = function (gameObject) {
        var _this = this;
        if (gameObject instanceof physics3DBodyGameObject_1.Physics3DBodyGameObject) {
            var cannonBody_1 = gameObject.getCANNONBody();
            // Delete at end of game step
            this.gameStepEndCallbacks.push(function () {
                _this.world.removeBody(cannonBody_1);
                _this.cannonBodyToGameObject.delete(cannonBody_1);
            });
        }
    };
    Physics3DSystem.prototype.processBeforeStep = function (delta, realm) {
        _super.prototype.processBeforeStep.call(this, delta, realm);
        // Cannon js delta is in seconds
        this.world.step(1 / 60, delta / 1000);
    };
    Physics3DSystem.prototype.onGameStepEnd = function (delta, realm) {
        var e_1, _a;
        _super.prototype.onGameStepEnd.call(this, delta, realm);
        try {
            for (var _b = __values(this.gameStepEndCallbacks), _c = _b.next(); !_c.done; _c = _b.next()) {
                var callback = _c.value;
                callback();
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.gameStepEndCallbacks = [];
    };
    Physics3DSystem.prototype.step = function (delta, gameObject, realm) {
        _super.prototype.step.call(this, delta, gameObject, realm);
    };
    return Physics3DSystem;
}(core_1.System));
exports.Physics3DSystem = Physics3DSystem;
