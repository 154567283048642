"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSubscribe = void 0;
var react_1 = require("react");
var useSubscribe = function (o, callback) {
    var observableRef = (0, react_1.useRef)(o);
    (0, react_1.useEffect)(function () {
        var o = observableRef.current;
        var subscription = o.subscribe(callback);
        return function () {
            subscription.unsubscribe();
        };
    }, [callback]);
};
exports.useSubscribe = useSubscribe;
