"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.threeGetByName = exports.threeFindByName = exports.threeFind = void 0;
/*
 * Searches recursively for the object
 */
function threeFind(obj, hasFound) {
    if (hasFound(obj)) {
        return obj;
    }
    for (var _i = 0, _a = obj.children; _i < _a.length; _i++) {
        var child = _a[_i];
        var foundObj = threeFind(child, hasFound);
        if (foundObj) {
            return foundObj;
        }
    }
    return null;
}
exports.threeFind = threeFind;
/*
 * Searches recursively for a child with the given name
 */
function threeFindByName(obj, name) {
    return threeFind(obj, function (obj) { return obj.name === name; });
}
exports.threeFindByName = threeFindByName;
/*
 * Same as threeFindByName but throws error if can't be found
 */
function threeGetByName(obj, name) {
    var target = threeFindByName(obj, name);
    if (target === null) {
        throw new Error("Cannot find three object with name ".concat(name));
    }
    return target;
}
exports.threeGetByName = threeGetByName;
