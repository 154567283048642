import { MMONetSchema } from "mmo-common";
import { Realm } from "realms-engine";
import { Render3DSystem, TrackingCameraSystem } from "realms-engine-browser";
import {
  createNetworkPhysicsOutlineFactoryMap,
  NetworkPhysicsOutlineSystem,
  NetworkSlaveSystem,
  RealmsEngineClient,
} from "realms-engine-network-client";
import THREE from "realms-three";
import { setUpScene } from "./setUpScene";
import { getMMOClientFeatureFlags } from "../../mmoClientFeatureFlags";
import {
  PlayerGameObject,
  CharacterGameObject,
  GoblinGameObject,
  SlaveShapeGameObject,
} from "../gameObjects";
import { InteractableSystem, PlayerSystem } from "../systems";
import { SlaveInteractableGameObject } from "../gameObjects/interactable/slaveInteractable.gameObject";

interface CreateRealmProps {
  client: RealmsEngineClient<MMONetSchema>;
  canvas: HTMLCanvasElement;
  width: number;
  height: number;
  characterId: number;
}

export function createRealmsCamera(width: number, height: number) {
  return new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
}

export function createRealm({
  client,
  canvas,
  width,
  height,
  characterId,
}: CreateRealmProps) {
  const camera = createRealmsCamera(width, height);

  return new Realm([
    // Syncs with server
    new NetworkSlaveSystem<MMONetSchema>(client, {
      ...createNetworkPhysicsOutlineFactoryMap(),
      player: (state) => new PlayerGameObject(state),
      character: (state) => new CharacterGameObject(state),
      goblin: (state) => new GoblinGameObject(state),
      shape: (state) => new SlaveShapeGameObject(state),
      interactable: (state) => new SlaveInteractableGameObject(state),
    }),

    // Displays physics outline
    ...(getMMOClientFeatureFlags().physicsOutline
      ? [new NetworkPhysicsOutlineSystem()]
      : []),

    new PlayerSystem(characterId),

    new InteractableSystem(),

    // Follows player
    new TrackingCameraSystem(camera, {
      enableTargetDamping: true,
      targetDampingSmoothTime: 200,
      targetDampingMaxSpeed: 100,
    }).addDependency(PlayerSystem),

    // Renders threejs
    new Render3DSystem({
      camera,
      // Set up the three js scene
      setUpScene,
      canvas,
      width,
      height,
    })
      .addDependency(NetworkSlaveSystem)
      .addDependency(TrackingCameraSystem),
  ]);
}
