"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocketIOServerConnection = void 0;
var realms_utils_1 = require("realms-utils");
var socket_io_client_1 = require("socket.io-client");
var SocketIOServerConnection = /** @class */ (function () {
    function SocketIOServerConnection(url) {
        var _this = this;
        this.eventManager = new realms_utils_1.EventManager();
        this.addListener = this.eventManager.addListener;
        this.removeListener = this.eventManager.removeListener;
        this.once = this.eventManager.once;
        this.addAnyListener = this.eventManager.addAnyListener;
        this.onAny = function (event, data) {
            _this.eventManager.dispatch(event, data);
        };
        this.socket = (0, socket_io_client_1.io)(url, { autoConnect: false });
        this.socket.onAny(this.onAny);
        this.socket.on("connect", function () {
            _this.eventManager.dispatch("connect", undefined);
        });
        this.socket.on("disconnect", function () {
            _this.eventManager.dispatch("disconnect", undefined);
        });
    }
    Object.defineProperty(SocketIOServerConnection.prototype, "id", {
        get: function () {
            return this.socket.id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SocketIOServerConnection.prototype, "connected", {
        get: function () {
            return this.socket.connected;
        },
        enumerable: false,
        configurable: true
    });
    SocketIOServerConnection.prototype.emit = function (event, data) {
        this.socket.emit(event, data);
    };
    SocketIOServerConnection.prototype.connect = function (auth) {
        this.socket.auth = auth;
        this.socket.connect();
    };
    SocketIOServerConnection.prototype.disconnect = function () {
        this.socket.disconnect();
        this.socket.offAny(this.onAny);
    };
    return SocketIOServerConnection;
}());
exports.SocketIOServerConnection = SocketIOServerConnection;
