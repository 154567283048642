import { Box, Spinner } from "@chakra-ui/react";
import { GameScreen } from "../components/GameScreen";

export const LoadingPage = () => {
  return (
    <GameScreen>
      <Box
        display={"flex"}
        height={"100%"}
        width={"100%"}
        alignItems={"center"}
        justifyContent="center"
      >
        <Spinner />
      </Box>
    </GameScreen>
  );
};
