"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DebugPanel = void 0;
// A utility class that can log text
var DebugPanel = /** @class */ (function () {
    function DebugPanel() {
    }
    // Displays text on debug panel
    DebugPanel.log = function (name, text) {
        // Create root element if it doesn't exist
        if (DebugPanel.rootElement === null) {
            DebugPanel.rootElement = document.createElement("div");
            DebugPanel.rootElement.style.display = "flex";
            DebugPanel.rootElement.style.flexDirection = "column";
            DebugPanel.rootElement.style.height = "100vh";
            DebugPanel.rootElement.style.left = "0px";
            DebugPanel.rootElement.style.position = "fixed";
            DebugPanel.rootElement.style.top = "0px";
            document.body.append(DebugPanel.rootElement);
        }
        // Create new entry to debug panel if it doesn exist
        if (!DebugPanel.canvasMap.get(name)) {
            var newCanvas = document.createElement("canvas");
            newCanvas.height = window.innerHeight * 2;
            newCanvas.width = window.innerWidth / 4;
            newCanvas.style.opacity = "0.5";
            var wrapper = document.createElement("div");
            wrapper.style.overflowY = "auto";
            wrapper.appendChild(newCanvas);
            var title = document.createElement("div");
            title.style.backgroundColor = "white";
            title.style.padding = "1rem";
            title.style.textAlign = "center";
            title.textContent = name;
            DebugPanel.rootElement.appendChild(title);
            DebugPanel.rootElement.appendChild(wrapper);
            DebugPanel.canvasMap.set(name, newCanvas);
        }
        var canvas = DebugPanel.canvasMap.get(name);
        var ctx = canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.font = "20px Arial";
        ctx.fillStyle = "black";
        text.split("\n").forEach(function (line, i) {
            ctx.fillText(line, 20, 20 * (i + 1));
        });
    };
    DebugPanel.logObject = function (name, obj) {
        DebugPanel.log(name, JSON.stringify(obj, null, 4));
    };
    DebugPanel.rootElement = null;
    // Maps the name of the canvas to the element
    DebugPanel.canvasMap = new Map();
    return DebugPanel;
}());
exports.DebugPanel = DebugPanel;
