"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatureDialogueConditionType = exports.SpawnerType = void 0;
var SpawnerType;
(function (SpawnerType) {
    SpawnerType["Enemy"] = "enemy";
    SpawnerType["NPC"] = "npc";
})(SpawnerType || (exports.SpawnerType = SpawnerType = {}));
var CreatureDialogueConditionType;
(function (CreatureDialogueConditionType) {
    CreatureDialogueConditionType["QuestState"] = "questState";
    CreatureDialogueConditionType["QuestAvailable"] = "questAvailable";
})(CreatureDialogueConditionType || (exports.CreatureDialogueConditionType = CreatureDialogueConditionType = {}));
