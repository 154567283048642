"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useResolveEffect = void 0;
var react_1 = require("react");
var useResolveEffect = function () {
    var _a = __read((0, react_1.useState)([]), 2), resolveQueue = _a[0], setResolveQueue = _a[1];
    /*
     * Resolve each of the promises
     */
    (0, react_1.useEffect)(function () {
        var e_1, _a;
        if (resolveQueue.length === 0) {
            return;
        }
        try {
            for (var resolveQueue_1 = __values(resolveQueue), resolveQueue_1_1 = resolveQueue_1.next(); !resolveQueue_1_1.done; resolveQueue_1_1 = resolveQueue_1.next()) {
                var resolve = resolveQueue_1_1.value;
                resolve();
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (resolveQueue_1_1 && !resolveQueue_1_1.done && (_a = resolveQueue_1.return)) _a.call(resolveQueue_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        setResolveQueue([]);
    }, []);
    return (0, react_1.useCallback)(function (data) {
        return new Promise(function (resolve) {
            setResolveQueue(function (queue) { return __spreadArray([function () { return resolve(data); }], __read(queue), false); });
        });
    }, []);
};
exports.useResolveEffect = useResolveEffect;
