import THREE from "realms-three";

export function setUpScene(scene: THREE.Scene) {
  // Light
  const ambient = new THREE.AmbientLight(0xaaaaaa, 1);
  scene.add(ambient);

  const directionalLight = new THREE.DirectionalLight(0xffffff);
  scene.add(directionalLight);
}
