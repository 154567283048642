"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhysicsTranslationForce = void 0;
/*
 * A translation to apply on a physics body each step
 */
var PhysicsTranslationForce = /** @class */ (function () {
    function PhysicsTranslationForce(force) {
        this.force = force;
    }
    return PhysicsTranslationForce;
}());
exports.PhysicsTranslationForce = PhysicsTranslationForce;
