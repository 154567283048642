import { MMONetSchema, PlayerState } from "mmo-common";
import { NetworkSlave } from "realms-engine-network-client";
import { CharacterGameObject } from "./character.gameObject";

/*
 * Manages the player state
 */
export class PlayerGameObject
  extends CharacterGameObject
  implements NetworkSlave<MMONetSchema["TMap"]["character"]>
{
  clientId: string;
  characterId: number;
  inRangeInteractableIds: number[];

  constructor(state: PlayerState, options: { shouldHideUI?: boolean } = {}) {
    super(state, options);
    this.clientId = state.clientId;
    this.characterId = state.characterId;
    this.inRangeInteractableIds = state.inRangeInteractableIds;
  }

  getCharacterId() {
    return this.characterId;
  }

  override onSlaveChanges(
    changes: Partial<PlayerState>,
    newState: PlayerState
  ): void {
    if (changes.clientId !== undefined) {
      this.clientId = newState.clientId;
    }

    if (changes.characterId !== undefined) {
      this.characterId = newState.characterId;
    }

    if (changes.inRangeInteractableIds !== undefined) {
      this.inRangeInteractableIds = changes.inRangeInteractableIds;
    }

    super.onSlaveChanges(changes, newState);
  }
}
