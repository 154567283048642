import THREE from "realms-three";
import {
  buildCharacterPartsMap,
  CharacterPartsMap,
} from "./characterMesh.model";
import {
  CharacterBeardType,
  CharacterConfig,
  CharacterGender,
  CharacterHairBackType,
  CharacterHairBaseType,
  CharacterHairFringeType,
  CharacterHairSideType,
  CharacterHairTailType,
  CharacterMoustacheType,
  DEFAULT_CHARACTER_OPTIONS,
} from "mmo-common";
import { setThreeVector3 } from "realms-three-utils";
import {
  smoothGeometry,
  createCanvas,
  processImage,
  buildBonesMap,
} from "./characterUtils";
import { characterConfig } from "./characterObjectConfig";
import { arrayFromThreeObject } from "realms-three-utils";
import { MMOAssetManager } from "../../config";
import { EventEmitter, EventManager, hexToRgb } from "realms-utils";
import { VARIABLE_TEXTURE_COLOR } from "./constants";

interface CharacterObjectEventsMap {
  // Emitted when the character mesh is rebuilt
  onMeshChange: CharacterObject;
}

export class CharacterObject implements EventEmitter<CharacterObjectEventsMap> {
  // Mesh of the character without the group wrapper
  private mesh: THREE.Object3D;
  private baseCharacterMesh: THREE.Object3D;
  private skeletonHelper: THREE.SkeletonHelper;
  private characterPartsMap: CharacterPartsMap;
  private bonesMap: ReturnType<typeof buildBonesMap>;
  private eventManager = new EventManager<CharacterObjectEventsMap>();
  addListener = this.eventManager.addListener;
  removeListener = this.eventManager.removeListener;
  once = this.eventManager.once;

  private beard: THREE.Mesh | null = null;
  private hairBase: THREE.Mesh | null = null;
  private hairFringe: THREE.Mesh | null = null;
  private hairBack: THREE.Mesh | null = null;
  private hairTail: THREE.Object3D | null = null;
  private hairSide: THREE.Object3D | null = null;
  private moustache: THREE.Mesh | null = null;

  constructor(
    private characterOptions: CharacterConfig = DEFAULT_CHARACTER_OPTIONS
  ) {
    // Build Mesh
    this.mesh = new THREE.Group();
    const { baseCharacterMesh, skeletonHelper, characterPartsMap, bonesMap } =
      this.buildCharacterMesh(this.mesh, characterOptions);
    this.baseCharacterMesh = baseCharacterMesh;
    this.skeletonHelper = skeletonHelper;
    this.characterPartsMap = characterPartsMap;
    this.bonesMap = bonesMap;

    // Update mesh based off options
    this.updateCharacterMesh();
  }

  getMesh(): THREE.Object3D {
    return this.mesh;
  }

  getCharacterConfig(): Readonly<CharacterConfig> {
    return this.characterOptions;
  }

  setCharacterConfig(options: CharacterConfig) {
    // Need to rebuild mesh
    if (options.gender !== this.characterOptions.gender) {
      this.mesh.remove(this.baseCharacterMesh);
      this.mesh.remove(this.skeletonHelper);
      const { baseCharacterMesh, skeletonHelper, characterPartsMap, bonesMap } =
        this.buildCharacterMesh(this.mesh, options);
      this.baseCharacterMesh = baseCharacterMesh;
      this.skeletonHelper = skeletonHelper;
      this.characterPartsMap = characterPartsMap;
      this.bonesMap = bonesMap;
    }
    this.characterOptions = options;
    this.updateCharacterMesh();
  }

  /*
   * Gets the character box
   */
  getCharacterBox(): THREE.Box3 {
    return new THREE.Box3().setFromObject(this.baseCharacterMesh);
  }

  private buildCharacterMesh(
    rootGroup: THREE.Object3D,
    options: CharacterConfig
  ) {
    const baseCharacterMesh =
      options.gender === CharacterGender.Male
        ? MMOAssetManager.getObject3D("characterMale", { cloneDeep: true })
        : MMOAssetManager.getObject3D("characterFemale", { cloneDeep: true });

    rootGroup.add(baseCharacterMesh);

    // Add skeleton
    const skeletonHelper = new THREE.SkeletonHelper(baseCharacterMesh);
    rootGroup.add(skeletonHelper);

    // Build character map
    const characterPartsMap = buildCharacterPartsMap(
      baseCharacterMesh,
      options.gender
    );

    // Convert to toon material
    for (const part of Object.values(characterPartsMap)) {
      if (part instanceof THREE.Mesh) {
        part.material = new THREE.MeshPhongMaterial();
      }
    }

    // Get bone data
    const bonesMap = buildBonesMap(characterPartsMap);

    // Add weapon
    const sword = MMOAssetManager.getObject3D("sword", { cloneDeep: true });
    sword.rotateY(Math.PI / 2);
    sword.position.setY(70);
    sword.position.setZ(20);
    bonesMap.bones.rightHand.add(sword);

    return {
      baseCharacterMesh,
      skeletonHelper,
      bonesMap,
      characterPartsMap,
    };
  }

  /*
   * Updates the character mesh
   */
  private updateCharacterMesh() {
    // Add hair
    this.updateBeard();
    this.updateHairBase();
    this.updateHairFringe();
    this.updateHairBack();
    this.updateHairTail();
    this.updateHairSide();
    this.updateMoustache();

    // Update if skeleton is visible
    this.skeletonHelper.visible = false;

    // Scale
    this.updateCharacterMeshScale();

    // Add textures
    this.updateCharacterTextures();
    this.eventManager.dispatch("onMeshChange", this);
  }

  private updateBeard() {
    const { beardType, gender } = this.characterOptions;
    // Remove the old hair base if it exists
    if (this.beard) {
      this.bonesMap.bones.headTop.remove(this.beard);
      this.beard = null;
    }
    // Add new hair type
    if (beardType && beardType !== CharacterBeardType.None) {
      // Add hair
      this.beard = MMOAssetManager.getMesh(beardType, { cloneDeep: true });
      this.bonesMap.bones.headTop.add(this.beard);
      const config = characterConfig["beard"][beardType][gender];
      setThreeVector3(this.beard.position, config.position);
      smoothGeometry(this.beard);
    }
  }

  private updateHairBase() {
    const { hairBaseType, gender } = this.characterOptions;
    // Remove the old hair base if it exists
    if (this.hairBase) {
      this.bonesMap.bones.headTop.remove(this.hairBase);
      this.hairBase = null;
    }
    // Add new hair type
    if (hairBaseType && hairBaseType !== CharacterHairBaseType.Bald) {
      // Add hair
      this.hairBase = MMOAssetManager.getMesh(hairBaseType, {
        cloneDeep: true,
      });
      this.bonesMap.bones.headTop.add(this.hairBase);
      const config = characterConfig["hairBase"][hairBaseType][gender];
      setThreeVector3(this.hairBase.position, config.position);
      smoothGeometry(this.hairBase);
    }
  }

  private updateHairFringe() {
    const { gender, hairFringeType } = this.characterOptions;
    if (this.hairFringe) {
      this.bonesMap.bones.headTop.remove(this.hairFringe);
      this.hairFringe = null;
    }
    if (hairFringeType !== CharacterHairFringeType.None) {
      this.hairFringe = MMOAssetManager.getMesh(hairFringeType, {
        cloneDeep: true,
      });
      const config = characterConfig["hairFringe"][hairFringeType][gender];
      setThreeVector3(this.hairFringe.position, config.position);
      this.bonesMap.bones.headTop.add(this.hairFringe);
      smoothGeometry(this.hairFringe);
    }
  }

  private updateHairBack() {
    const { gender, hairBackType } = this.characterOptions;
    if (this.hairBack) {
      this.bonesMap.bones.headTop.remove(this.hairBack);
      this.hairBack = null;
    }
    if (hairBackType !== CharacterHairBackType.None) {
      this.hairBack = MMOAssetManager.getMesh(hairBackType, {
        cloneDeep: true,
      });
      const config = characterConfig["hairBack"][hairBackType][gender];
      setThreeVector3(this.hairBack.position, config.position);
      this.bonesMap.bones.headTop.add(this.hairBack);
      smoothGeometry(this.hairBack);
    }
  }

  private updateHairTail() {
    const { gender, hairTailType } = this.characterOptions;
    if (this.hairTail) {
      this.bonesMap.bones.headTop.remove(this.hairTail);
      this.hairTail = null;
    }
    if (hairTailType !== CharacterHairTailType.None) {
      this.hairTail = MMOAssetManager.getObject3D(hairTailType, {
        cloneDeep: true,
      });
      const config = characterConfig["hairTail"][hairTailType][gender];
      setThreeVector3(this.hairTail.position, config.position);
      this.bonesMap.bones.headTop.add(this.hairTail);
      for (const obj of arrayFromThreeObject(this.hairTail)) {
        if (obj instanceof THREE.Mesh) {
          smoothGeometry(obj);
        }
      }
    }
  }

  private updateHairSide() {
    const { gender, hairSideType } = this.characterOptions;
    if (this.hairSide) {
      this.bonesMap.bones.headTop.remove(this.hairSide);
      this.hairSide = null;
    }
    if (hairSideType !== CharacterHairSideType.None) {
      this.hairSide = MMOAssetManager.getObject3D(hairSideType, {
        cloneDeep: true,
      });
      const config = characterConfig["hairSide"][hairSideType][gender];
      setThreeVector3(this.hairSide.position, config.position);
      this.bonesMap.bones.headTop.add(this.hairSide);
      for (const obj of arrayFromThreeObject(this.hairSide)) {
        if (obj instanceof THREE.Mesh) {
          smoothGeometry(obj);
        }
      }
    }
  }

  private updateMoustache() {
    const { gender, moustacheType } = this.characterOptions;
    if (this.moustache) {
      this.bonesMap.bones.headTop.remove(this.moustache);
      this.moustache = null;
    }
    if (moustacheType !== CharacterMoustacheType.None) {
      this.moustache = MMOAssetManager.getMesh(moustacheType, {
        cloneDeep: true,
      });
      const config = characterConfig["moustache"][moustacheType][gender];
      setThreeVector3(this.moustache.position, config.position);
      this.bonesMap.bones.headTop.add(this.moustache);
      smoothGeometry(this.moustache);
    }
  }

  /*
   * Scale the character mesh in height and width
   */
  private updateCharacterMeshScale() {
    // Scale height
    const { heightScale, widthScale } = this.characterOptions;
    // Move left leg
    this.bonesMap.bones.leftLeg.position.setY(
      this.bonesMap.originalPositions.leftLegOriginalPosition.y + heightScale
    );
    this.bonesMap.bones.leftLegChild.position.setY(
      this.bonesMap.originalPositions.leftLegChildOriginalPosition.y +
        heightScale
    );

    // Move right leg
    this.bonesMap.bones.rightLeg.position.setY(
      this.bonesMap.originalPositions.rightLegOriginalPosition.y + heightScale
    );
    this.bonesMap.bones.rightLegChild.position.setY(
      this.bonesMap.originalPositions.rightLegChildOriginalPosition.y +
        heightScale
    );

    this.bonesMap.bones.topSpine.position.setY(
      this.bonesMap.originalPositions.topSpineOriginalPosition.y + heightScale
    );

    this.bonesMap.bones.rightShoulder.position.setX(
      this.bonesMap.originalPositions.rightShoulderOriginalPosition.x -
        widthScale
    );

    this.bonesMap.bones.leftShoulder.position.setX(
      this.bonesMap.originalPositions.leftShoulderOriginalPosition.x +
        widthScale
    );
  }

  /*
   * Update the textures of the character
   */
  private updateCharacterTextures() {
    const { skinColor, hairColor, eyesType } = this.characterOptions;

    // Eyes texture
    const eyesTexture = MMOAssetManager.getTexture(eyesType, { clone: true });

    const { canvas: faceCanvas, ctx: faceCtx } = createCanvas(
      eyesTexture.image.width,
      eyesTexture.image.width,
      skinColor
    );

    const faceImage = processImage(eyesTexture.image, ({ r, g, b, a }) => {
      const rgbVariableColor = hexToRgb(VARIABLE_TEXTURE_COLOR);
      if (
        r === rgbVariableColor.r &&
        g === rgbVariableColor.g &&
        b === rgbVariableColor.b
      ) {
        return { ...hexToRgb(this.characterOptions.eyeColor), a };
      }
      return { r, g, b, a };
    });
    faceCtx.drawImage(faceImage, 0, 0);

    // Add textures
    if (this.characterPartsMap.Head.material) {
      (this.characterPartsMap.Head.material as THREE.MeshPhongMaterial).map =
        new THREE.CanvasTexture(faceCanvas);
    }

    this.colorPart(this.characterPartsMap.Nose, skinColor);
    this.colorPart(this.characterPartsMap.EarLeft, skinColor);
    this.colorPart(this.characterPartsMap.EarRight, skinColor);
    this.colorPart(this.characterPartsMap.Neck, skinColor);
    this.colorPart(this.characterPartsMap.UpperArmLeft, skinColor);
    this.colorPart(this.characterPartsMap.UpperArmRight, skinColor);
    this.colorPart(this.characterPartsMap.LowerArmLeft, skinColor);
    this.colorPart(this.characterPartsMap.LowerArmRight, skinColor);
    this.colorPart(this.characterPartsMap.HandLeft, skinColor);
    this.colorPart(this.characterPartsMap.HandRight, skinColor);
    this.colorPart(this.characterPartsMap.LowerLegLeft, skinColor);
    this.colorPart(this.characterPartsMap.LowerLegRight, skinColor);
    this.colorPart(this.characterPartsMap.FeetLeft, skinColor);
    this.colorPart(this.characterPartsMap.FeetRight, skinColor);

    if (this.beard) {
      this.colorPart(this.beard, hairColor);
    }
    if (this.hairBase) {
      this.colorPart(this.hairBase, hairColor, { recursive: true });
    }
    if (this.hairFringe) {
      this.colorPart(this.hairFringe, hairColor);
    }
    if (this.hairBack) {
      this.colorPart(this.hairBack, hairColor);
    }
    if (this.hairTail) {
      for (const obj of arrayFromThreeObject(this.hairTail)) {
        if (obj instanceof THREE.Mesh) {
          this.colorPart(obj as THREE.Mesh, hairColor);
        }
      }
    }
    if (this.hairSide) {
      for (const obj of arrayFromThreeObject(this.hairSide)) {
        if (obj instanceof THREE.Mesh) {
          this.colorPart(obj as THREE.Mesh, hairColor);
        }
      }
    }
    if (this.moustache) {
      this.colorPart(this.moustache, hairColor);
    }
  }

  /*
   * Adds a color to a specific part in the character
   */
  private colorPart(
    mesh: THREE.Object3D,
    color: string,
    options: { recursive?: boolean } = {}
  ) {
    const { canvas: skinCanvas } = createCanvas(1, 1, color);
    if (mesh instanceof THREE.Mesh) {
      (mesh.material as THREE.MeshPhongMaterial).map = new THREE.CanvasTexture(
        skinCanvas
      );
    }
    if (options.recursive) {
      // Recursively add to children
      for (const child of mesh.children) {
        this.colorPart(child, color, { recursive: true });
      }
    }
  }
}
