"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isMMORPCError = exports.MMORPCErrorType = exports.sendMessageRPCParamsSchema = exports.interactRPCParamsSchema = exports.getQuestRPCParamsSchema = exports.getCreatureConfigRPCParamsSchema = exports.completeQuestRPCParamsSchema = exports.acceptQuestRPCParamsSchema = void 0;
var realms_utils_1 = require("realms-utils");
exports.acceptQuestRPCParamsSchema = {
    questId: realms_utils_1.v.string(),
};
exports.completeQuestRPCParamsSchema = {
    questId: realms_utils_1.v.string(),
};
exports.getCreatureConfigRPCParamsSchema = {
    creatureConfigIds: realms_utils_1.v.array(realms_utils_1.v.string()),
};
exports.getQuestRPCParamsSchema = {
    questId: realms_utils_1.v.string(),
};
exports.interactRPCParamsSchema = {
    interactableId: realms_utils_1.v.number(),
    optionId: realms_utils_1.v.string(),
};
exports.sendMessageRPCParamsSchema = {
    message: realms_utils_1.v.string([(0, realms_utils_1.isLength)({ min: 1, max: 200 })]),
};
var MMORPCErrorType;
(function (MMORPCErrorType) {
    MMORPCErrorType["ClientNotInRoom"] = "Client is not in a room";
    MMORPCErrorType["UnableToInteract"] = "Unable to interact";
    MMORPCErrorType["InvalidInteractionOption"] = "The option you are interacting with does not exist";
    MMORPCErrorType["InvalidQuest"] = "The quest is invalid";
    MMORPCErrorType["InvalidParameters"] = "The rpc parameters are invalid";
    MMORPCErrorType["InvalidCharacter"] = "The character specified does not exist";
    MMORPCErrorType["SomethingWentWrong"] = "Oops, something went wrong";
})(MMORPCErrorType || (exports.MMORPCErrorType = MMORPCErrorType = {}));
function isMMORPCError(e) {
    if (typeof e === "object" &&
        e !== null &&
        "error" in e &&
        typeof e.error === "string" &&
        Object.values(MMORPCErrorType).includes(e.error)) {
        return true;
    }
    return false;
}
exports.isMMORPCError = isMMORPCError;
