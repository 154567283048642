"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataObjectBuilder = void 0;
var realmforge_common_1 = require("realmforge-common");
var AmbientLightDataObjectBuilder_1 = require("./AmbientLightDataObjectBuilder");
var BlueprintRefDataObjectBuilder_1 = require("./BlueprintRefDataObjectBuilder");
var BoxDataObjectBuilder_1 = require("./BoxDataObjectBuilder");
var CylinderDataObjectBuilder_1 = require("./CylinderDataObjectBuilder");
var DirectionalLightDataObjectBuilder_1 = require("./DirectionalLightDataObjectBuilder");
var GroupDataObjectBuilder_1 = require("./GroupDataObjectBuilder");
var HemisphereLightDataObjectBuilder_1 = require("./HemisphereLightDataObjectBuilder");
var PluginDataObjectBuilder_1 = require("./PluginDataObjectBuilder");
var SphereDataObjectBuilder_1 = require("./SphereDataObjectBuilder");
var SpriteDataObjectBuilder_1 = require("./SpriteDataObjectBuilder");
/*
 * Builds a data object
 */
var DataObjectBuilder = /** @class */ (function () {
    function DataObjectBuilder(builders) {
        var _a;
        if (builders === void 0) { builders = {}; }
        var _this = this;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        // Maps type to builder
        this.dataObjectBuilderMapping = (_a = {},
            _a[realmforge_common_1.DataObjectType.Box] = (_b = builders[realmforge_common_1.DataObjectType.Box]) !== null && _b !== void 0 ? _b : new BoxDataObjectBuilder_1.BoxDataObjectBuilder(),
            _a[realmforge_common_1.DataObjectType.Group] = (_c = builders[realmforge_common_1.DataObjectType.Group]) !== null && _c !== void 0 ? _c : new GroupDataObjectBuilder_1.GroupDataObjectBuilder(),
            _a[realmforge_common_1.DataObjectType.Sphere] = (_d = builders[realmforge_common_1.DataObjectType.Sphere]) !== null && _d !== void 0 ? _d : new SphereDataObjectBuilder_1.SphereDataObjectBuilder(),
            _a[realmforge_common_1.DataObjectType.Cylinder] = (_e = builders[realmforge_common_1.DataObjectType.Cylinder]) !== null && _e !== void 0 ? _e : new CylinderDataObjectBuilder_1.CylinderDataObjectBuilder(),
            _a[realmforge_common_1.DataObjectType.BlueprintRef] = (_f = builders[realmforge_common_1.DataObjectType.BlueprintRef]) !== null && _f !== void 0 ? _f : new BlueprintRefDataObjectBuilder_1.BlueprintRefDataObjectBuilder(function (dataObject, allDataObjects, getAssetTexture) {
                return _this.buildThreeObject(dataObject, allDataObjects, getAssetTexture);
            }),
            _a[realmforge_common_1.DataObjectType.HemisphereLight] = (_g = builders[realmforge_common_1.DataObjectType.HemisphereLight]) !== null && _g !== void 0 ? _g : new HemisphereLightDataObjectBuilder_1.HemisphereLightDataObjectBuilder(),
            _a[realmforge_common_1.DataObjectType.AmbientLight] = (_h = builders[realmforge_common_1.DataObjectType.AmbientLight]) !== null && _h !== void 0 ? _h : new AmbientLightDataObjectBuilder_1.AmbientLightDataObjectBuilder(),
            _a[realmforge_common_1.DataObjectType.DirectionalLight] = (_j = builders[realmforge_common_1.DataObjectType.DirectionalLight]) !== null && _j !== void 0 ? _j : new DirectionalLightDataObjectBuilder_1.DirectionalLightDataObjectBuilder(),
            _a[realmforge_common_1.DataObjectType.PluginDataObject] = (_k = builders[realmforge_common_1.DataObjectType.PluginDataObject]) !== null && _k !== void 0 ? _k : new PluginDataObjectBuilder_1.PluginDataObjectBuilder(),
            _a[realmforge_common_1.DataObjectType.Sprite] = (_l = builders[realmforge_common_1.DataObjectType.Sprite]) !== null && _l !== void 0 ? _l : new SpriteDataObjectBuilder_1.SpriteDataObjectBuilder(),
            _a);
    }
    DataObjectBuilder.prototype.getBuilder = function (type) {
        return this.dataObjectBuilderMapping[type];
    };
    /*
     * Creates the data object
     */
    DataObjectBuilder.prototype.createDataObject = function (params) {
        return this.dataObjectBuilderMapping[params.type].createDataObject(params);
    };
    /*
     * Creates the three object
     */
    DataObjectBuilder.prototype.buildThreeObject = function (dataObject, allDataObjects, getAssetTexture) {
        return this.dataObjectBuilderMapping[dataObject.type].buildThreeObject(dataObject, allDataObjects, getAssetTexture);
    };
    /*
     * Updates data object
     */
    DataObjectBuilder.prototype.updateDataObject = function (oldDataObject, params) {
        return this.dataObjectBuilderMapping[oldDataObject.type].updateDataObject(oldDataObject, params);
    };
    /*
     * Updates three object
     */
    DataObjectBuilder.prototype.updateThreeObject = function (params, threeObject, getAssetTexture) {
        return this.dataObjectBuilderMapping[params.type].updateThreeObject(params, threeObject, getAssetTexture);
    };
    return DataObjectBuilder;
}());
exports.DataObjectBuilder = DataObjectBuilder;
