import { MMONetSchema, Shape, ShapeType } from "mmo-common";
import { GameObject } from "realms-engine";
import { Render3DGameObject } from "realms-engine-browser";
import { NetworkSlave } from "realms-engine-network-client";
import THREE from "realms-three";

export class SlaveShapeGameObject
  extends GameObject
  implements NetworkSlave<MMONetSchema["TMap"]["shape"]>
{
  private render3D: Render3DGameObject;

  constructor(shape: Shape<ShapeType>) {
    super();

    this.render3D = this.addChild(
      new Render3DGameObject(this.createThreeObject(shape))
    );
    this.render3D.setPosition(shape.position);
    this.render3D.setQuaternion(shape.quaternion);
  }

  private createThreeObject(shape: Shape<ShapeType>): THREE.Object3D {
    switch (shape.type) {
      case ShapeType.Rectangle: {
        return new THREE.Mesh(
          new THREE.BoxGeometry(
            shape.properties.size.x,
            shape.properties.size.y,
            shape.properties.size.z
          ),
          new THREE.MeshToonMaterial({ color: 0x00ff00 })
        );
      }
    }
  }

  onSlaveChanges(
    changes: Partial<Shape<ShapeType>>,
    newState: Shape<ShapeType>,
    oldState: Shape<ShapeType>
  ): void {
    if (changes.type !== undefined) {
      this.removeChild(this.render3D);
      this.render3D = this.addChild(
        new Render3DGameObject(this.createThreeObject(newState))
      );
    }
    if (changes.position !== undefined) {
      this.render3D.setPosition(newState.position);
    }
    if (changes.quaternion !== undefined) {
      this.render3D.setQuaternion(newState.quaternion);
    }
  }
}
