"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CanvasRenderer = void 0;
var types_1 = require("./types");
var realms_utils_1 = require("realms-utils");
var CanvasRenderer = /** @class */ (function () {
    function CanvasRenderer() {
    }
    /**
     * Draws the given UINode on the canvas
     */
    CanvasRenderer.prototype.draw = function (ctx, node, parentProperties) {
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        return this._draw(ctx, node, parentProperties);
    };
    CanvasRenderer.prototype._draw = function (ctx, node, parentProperties) {
        var nodeProperties = this.getNodeProperties(ctx, node, parentProperties);
        var height = nodeProperties.height, width = nodeProperties.width;
        // Draw box
        this.drawBox({ ctx: ctx, nodeProperties: nodeProperties });
        // Draw background image
        this.drawBackgroundImage({ ctx: ctx, nodeProperties: nodeProperties });
        // Writes the text
        this.drawTextContent({ ctx: ctx, node: node, nodeProperties: nodeProperties });
        // Draw children
        this.drawChildren({ ctx: ctx, node: node, nodeProperties: nodeProperties });
        return {
            viewWidth: width,
            viewHeight: height,
        };
    };
    /**
     * Draws the box
     */
    CanvasRenderer.prototype.drawBox = function (_a) {
        var ctx = _a.ctx, nodeProperties = _a.nodeProperties;
        var borderWidth = nodeProperties.borderWidth, rootNodeProps = nodeProperties.rootNodeProps, contentRootX = nodeProperties.contentRootX, contentRootY = nodeProperties.contentRootY, contentWidth = nodeProperties.contentWidth, contentHeight = nodeProperties.contentHeight, borderRadius = nodeProperties.borderRadius;
        ctx.save();
        ctx.beginPath();
        ctx.lineWidth = borderWidth;
        if (rootNodeProps.backgroundColor) {
            ctx.fillStyle = rootNodeProps.backgroundColor;
            ctx.strokeStyle = rootNodeProps.backgroundColor;
        }
        if (rootNodeProps.borderColor && borderWidth) {
            ctx.strokeStyle = rootNodeProps.borderColor;
        }
        // Only draw a rectangle if one of the colors are specified
        if ((rootNodeProps.borderColor || rootNodeProps.backgroundColor) &&
            contentWidth > 0 &&
            contentHeight > 0) {
            ctx.roundRect(contentRootX - 0.5 * borderWidth, contentRootY - 0.5 * borderWidth, contentWidth + borderWidth, contentHeight + borderWidth, borderRadius);
            ctx.fill();
            ctx.stroke();
        }
        ctx.restore();
    };
    /**
     * Draws background image
     */
    CanvasRenderer.prototype.drawBackgroundImage = function (_a) {
        var ctx = _a.ctx, nodeProperties = _a.nodeProperties;
        var rootNodeProps = nodeProperties.rootNodeProps, contentRootX = nodeProperties.contentRootX, contentRootY = nodeProperties.contentRootY, contentWidth = nodeProperties.contentWidth, contentHeight = nodeProperties.contentHeight;
        if (rootNodeProps.backgroundImage) {
            ctx.save();
            ctx.drawImage(rootNodeProps.backgroundImage, contentRootX, contentRootY, contentWidth, contentHeight);
            ctx.restore();
        }
    };
    /**
     * Writes the text content
     */
    CanvasRenderer.prototype.drawTextContent = function (_a) {
        var _b;
        var ctx = _a.ctx, node = _a.node, nodeProperties = _a.nodeProperties;
        var rootNodeProps = nodeProperties.rootNodeProps, childRootX = nodeProperties.childRootX, childMaxWidth = nodeProperties.childMaxWidth, childRootY = nodeProperties.childRootY, childMaxHeight = nodeProperties.childMaxHeight;
        // Write text
        if (node.textContent) {
            ctx.save();
            ctx.fillStyle = rootNodeProps.color;
            if (rootNodeProps.font) {
                ctx.font = rootNodeProps.font;
            }
            ctx.textAlign = rootNodeProps.textAlign;
            var textHeight = this.measureText({
                ctx: ctx,
                text: node.textContent,
                font: rootNodeProps.font,
            }).textHeight;
            var textRootX = childRootX;
            switch (rootNodeProps.textAlign) {
                case "center": {
                    textRootX = childRootX + 0.5 * childMaxWidth;
                    break;
                }
                case "right": {
                    textRootX = childRootX + childMaxWidth;
                    break;
                }
            }
            var textRootY = childRootY + textHeight;
            switch (rootNodeProps.alignItems) {
                case "center": {
                    textRootY = childRootY + 0.5 * childMaxHeight + 0.5 * textHeight;
                    break;
                }
                case "end": {
                    textRootY = childRootY + childMaxHeight;
                    break;
                }
            }
            ctx.fillText(node.textContent, textRootX, textRootY, childMaxWidth);
            if (rootNodeProps.strokeColor) {
                ctx.lineWidth = (_b = rootNodeProps.strokeWidth) !== null && _b !== void 0 ? _b : 1;
                ctx.strokeStyle = rootNodeProps.strokeColor;
                ctx.strokeText(node.textContent, textRootX, textRootY, childMaxWidth);
            }
            ctx.restore();
        }
    };
    CanvasRenderer.prototype.drawChildren = function (_a) {
        var e_1, _b, e_2, _c, e_3, _d;
        var ctx = _a.ctx, node = _a.node, nodeProperties = _a.nodeProperties;
        var childMaxWidth = nodeProperties.childMaxWidth, childMaxHeight = nodeProperties.childMaxHeight, childRootX = nodeProperties.childRootX, childRootY = nodeProperties.childRootY, rootNodeProps = nodeProperties.rootNodeProps;
        var flexDirection = rootNodeProps.flexDirection;
        var justifyContent = rootNodeProps.justifyContent;
        var alignItems = rootNodeProps.alignItems;
        // Calculate the width / height remaining after all the pixel values
        // are removed. This is used to calculate the percentage values
        var remainingWidth = childMaxWidth;
        var remainingHeight = childMaxHeight;
        // The total percentage used by all the children
        var totalPercentageWidth = 0;
        var totalPercentageHeight = 0;
        try {
            for (var _e = __values(node.children), _f = _e.next(); !_f.done; _f = _e.next()) {
                var child = _f.value;
                if (typeof child === "string") {
                    continue;
                }
                var _g = this.getNodeDimensions(ctx, child), childWidth = _g.width, childHeight = _g.height;
                if (typeof childWidth === "string") {
                    if (childWidth.includes("px")) {
                        remainingWidth = Math.max(remainingWidth - this.toPixels(childWidth), 0);
                    }
                    else if (childWidth.includes("%")) {
                        totalPercentageWidth += this.getPercentageValue(childWidth);
                    }
                }
                if (typeof childHeight === "string") {
                    if (childHeight.includes("px")) {
                        remainingHeight = Math.max(remainingHeight - this.toPixels(childHeight), 0);
                    }
                    else if (childHeight.includes("%")) {
                        totalPercentageHeight += this.getPercentageValue(childHeight);
                    }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
            }
            finally { if (e_1) throw e_1.error; }
        }
        // Get total gap space after all children are drawn
        var remainingSpaceWidth = childMaxWidth;
        var remainingSpaceHeight = childMaxHeight;
        try {
            for (var _h = __values(node.children), _j = _h.next(); !_j.done; _j = _h.next()) {
                var child = _j.value;
                var _k = this.getChildSizeInPx({
                    ctx: ctx,
                    child: child,
                    flexDirection: flexDirection,
                    totalPercentageHeight: totalPercentageHeight,
                    totalPercentageWidth: totalPercentageWidth,
                    remainingHeight: remainingHeight,
                    remainingWidth: remainingWidth,
                }), childWidthPx = _k.childWidthPx, childHeightPx = _k.childHeightPx;
                if (flexDirection === "row" && childWidthPx) {
                    remainingSpaceWidth -= this.toPixels(childWidthPx);
                }
                if (flexDirection === "column" && childHeightPx) {
                    remainingSpaceHeight -= this.toPixels(childHeightPx);
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
            }
            finally { if (e_2) throw e_2.error; }
        }
        // Draw children
        var currentChildRootX = childRootX;
        var currentChildRootY = childRootY;
        switch (justifyContent) {
            case "center": {
                if (flexDirection === "row") {
                    currentChildRootX += remainingSpaceWidth * 0.5;
                }
                if (flexDirection === "column") {
                    currentChildRootY += remainingSpaceHeight * 0.5;
                }
                break;
            }
            case "end": {
                if (flexDirection === "row") {
                    currentChildRootX += remainingSpaceWidth;
                }
                if (flexDirection === "column") {
                    currentChildRootY += remainingSpaceHeight;
                }
                break;
            }
            case "space-evenly": {
                if (flexDirection === "row") {
                    currentChildRootX += remainingSpaceWidth / (node.children.length + 1);
                }
                if (flexDirection === "column") {
                    currentChildRootY +=
                        remainingSpaceHeight / (node.children.length + 1);
                }
                break;
            }
            default: {
                // start
            }
        }
        try {
            for (var _l = __values(node.children), _m = _l.next(); !_m.done; _m = _l.next()) {
                var child = _m.value;
                var _o = this.getChildSizeInPx({
                    ctx: ctx,
                    child: child,
                    flexDirection: flexDirection,
                    totalPercentageHeight: totalPercentageHeight,
                    totalPercentageWidth: totalPercentageWidth,
                    remainingHeight: remainingHeight,
                    remainingWidth: remainingWidth,
                }), childWidthPx = _o.childWidthPx, childHeightPx = _o.childHeightPx;
                var alignOffsetWidth = 0;
                var alignOffsetHeight = 0;
                // We are getting width/height of the child when rendered
                var _p = this.getNodeProperties(ctx, child, {
                    widthPixels: childMaxWidth,
                    heightPixels: childMaxHeight,
                }), height = _p.height, width = _p.width;
                switch (alignItems) {
                    case "center": {
                        if (flexDirection === "row") {
                            alignOffsetHeight = childMaxHeight * 0.5 - height * 0.5;
                        }
                        if (flexDirection === "column") {
                            alignOffsetWidth = childMaxWidth * 0.5 - width * 0.5;
                        }
                        break;
                    }
                    case "end": {
                        if (flexDirection === "row") {
                            alignOffsetHeight = childMaxHeight - height;
                        }
                        if (flexDirection === "column") {
                            alignOffsetWidth = childMaxWidth - width;
                        }
                        break;
                    }
                }
                var childParams = this._draw(ctx, __assign(__assign({}, child), { props: __assign(__assign(__assign({}, child.props), (childWidthPx ? { width: childWidthPx } : {})), (childHeightPx ? { height: childHeightPx } : {})) }), {
                    widthPixels: childMaxWidth,
                    heightPixels: childMaxHeight,
                    rootX: currentChildRootX + alignOffsetWidth,
                    rootY: currentChildRootY + alignOffsetHeight,
                });
                // Update next childs dimensions
                if (rootNodeProps.flexDirection === "column" && childParams) {
                    currentChildRootY += childParams.viewHeight;
                    if (justifyContent === "space-evenly") {
                        currentChildRootY +=
                            remainingSpaceHeight / (node.children.length + 1);
                    }
                    if (justifyContent === "space-between" && node.children.length > 1) {
                        currentChildRootY +=
                            remainingSpaceHeight / (node.children.length - 1);
                    }
                }
                if (rootNodeProps.flexDirection === "row" && childParams) {
                    currentChildRootX += childParams.viewWidth;
                    if (justifyContent === "space-evenly") {
                        currentChildRootX += remainingSpaceWidth / (node.children.length + 1);
                    }
                    if (justifyContent === "space-between" && node.children.length > 1) {
                        currentChildRootX += remainingSpaceWidth / (node.children.length - 1);
                    }
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_m && !_m.done && (_d = _l.return)) _d.call(_l);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    CanvasRenderer.prototype.getChildSizeInPx = function (_a) {
        var ctx = _a.ctx, child = _a.child, flexDirection = _a.flexDirection, totalPercentageWidth = _a.totalPercentageWidth, totalPercentageHeight = _a.totalPercentageHeight, remainingWidth = _a.remainingWidth, remainingHeight = _a.remainingHeight;
        var _b = this.getNodeDimensions(ctx, child), childWidth = _b.width, childHeight = _b.height;
        // Convert percentage values into pixel values based off remaining space
        if (flexDirection === "row" &&
            typeof childWidth === "string" &&
            childWidth.includes("%")) {
            if (totalPercentageWidth === 0) {
                childWidth = "0px";
            }
            else {
                childWidth = "".concat((remainingWidth * this.getPercentageValue(childWidth)) /
                    Math.max(totalPercentageWidth, 100), "px");
            }
        }
        if (flexDirection === "column" &&
            typeof childHeight === "string" &&
            childHeight.includes("%")) {
            if (totalPercentageHeight === 0) {
                childHeight = "0px";
            }
            else {
                childHeight = "".concat((remainingHeight * this.getPercentageValue(childHeight)) /
                    Math.max(totalPercentageHeight, 100), "px");
            }
        }
        return {
            childWidthPx: childWidth,
            childHeightPx: childHeight,
        };
    };
    /**
     * See this blog for more info:
     * https://erikonarheim.com/posts/canvas-text-metrics/
     */
    CanvasRenderer.prototype.measureText = function (_a) {
        var ctx = _a.ctx, text = _a.text, font = _a.font;
        ctx.save();
        if (font) {
            ctx.font = font;
        }
        var metrics = ctx.measureText(text);
        ctx.restore();
        return {
            textWidth: Math.abs(metrics.actualBoundingBoxLeft) +
                Math.abs(metrics.actualBoundingBoxRight),
            textHeight: Math.abs(metrics.actualBoundingBoxAscent) +
                Math.abs(metrics.actualBoundingBoxDescent),
        };
    };
    /**
     * Gets the size of a node
     */
    CanvasRenderer.prototype.getNodeDimensions = function (ctx, node) {
        var _a;
        var rootNodeProps = __assign({ borderWidth: "0px", padding: "0px", margin: "0px" }, realms_utils_1.v.validate((_a = node.props) !== null && _a !== void 0 ? _a : {}, types_1.viewNodePropsSchema));
        // Padding
        var padding = this.toPixels(rootNodeProps.padding);
        // Margin
        var margin = this.toPixels(rootNodeProps.margin);
        // Border
        var borderWidth = this.toPixels(rootNodeProps.borderWidth);
        // Max between padding and border width
        var contentPadding = Math.max(padding, borderWidth);
        // Set height to text height if not specified
        if (rootNodeProps.height === undefined) {
            if (node.textContent) {
                var textHeight = this.measureText({
                    ctx: ctx,
                    text: node.textContent,
                    font: rootNodeProps.font,
                }).textHeight;
                rootNodeProps.height = "".concat(textHeight + 2 * contentPadding + 2 * margin, "px");
            }
            else {
                rootNodeProps.height = "0px";
            }
        }
        // Set height to text height if not specified
        if (rootNodeProps.width === undefined) {
            if (node.textContent) {
                var textWidth = this.measureText({
                    ctx: ctx,
                    text: node.textContent,
                    font: rootNodeProps.font,
                }).textWidth;
                rootNodeProps.width = "".concat(textWidth + 2 * contentPadding + 2 * margin, "px");
            }
            else {
                rootNodeProps.width = "0px";
            }
        }
        return {
            height: rootNodeProps.height,
            width: rootNodeProps.width,
            padding: padding,
            contentPadding: contentPadding,
            margin: margin,
            borderWidth: borderWidth,
        };
    };
    /**
     * Calculates node properties
     */
    CanvasRenderer.prototype.getNodeProperties = function (ctx, node, parentPropertiesConfig) {
        var _a;
        var parentProperties = __assign({ rootX: 0, rootY: 0 }, parentPropertiesConfig);
        var rootNodeProps = __assign({ borderWidth: "0px", borderRadius: "0px", padding: "0px", margin: "0px", flexDirection: "column", textAlign: "left", alignItems: "start", color: "#000000" }, realms_utils_1.v.validate((_a = node.props) !== null && _a !== void 0 ? _a : {}, types_1.viewNodePropsSchema));
        var _b = this.getNodeDimensions(ctx, node), nodeHeight = _b.height, nodeWidth = _b.width, padding = _b.padding, contentPadding = _b.contentPadding, borderWidth = _b.borderWidth, margin = _b.margin;
        rootNodeProps.height = nodeHeight;
        rootNodeProps.width = nodeWidth;
        // Height of the whole box
        var height = this.toPixels(rootNodeProps.height, parentProperties.heightPixels);
        // Width of the whole box
        var width = this.toPixels(rootNodeProps.width, parentProperties.widthPixels);
        var borderRadius = this.toPixels(rootNodeProps.borderRadius, Math.min(width, height));
        // The size of the content inside
        var contentWidth = width - 2 * borderWidth - 2 * margin;
        var contentHeight = height - 2 * borderWidth - 2 * margin;
        var contentRootX = parentProperties.rootX + borderWidth + margin;
        var contentRootY = parentProperties.rootY + borderWidth + margin;
        // Child
        var childMaxWidth = width - 2 * contentPadding - 2 * margin;
        var childMaxHeight = height - 2 * contentPadding - 2 * margin;
        var childRootX = parentProperties.rootX + contentPadding + margin;
        var childRootY = parentProperties.rootY + contentPadding + margin;
        return {
            rootNodeProps: rootNodeProps,
            height: height,
            width: width,
            padding: padding,
            margin: margin,
            borderWidth: borderWidth,
            contentPadding: contentPadding,
            borderRadius: borderRadius,
            contentWidth: contentWidth,
            contentHeight: contentHeight,
            contentRootX: contentRootX,
            contentRootY: contentRootY,
            childMaxWidth: childMaxWidth,
            childMaxHeight: childMaxHeight,
            childRootX: childRootX,
            childRootY: childRootY,
        };
    };
    /**
     * Gets the percentage value
     */
    CanvasRenderer.prototype.getPercentageValue = function (value) {
        var percentageValue = value.replace("%", "");
        if (Number.isNaN(Number(percentageValue))) {
            throw new Error("Invalid percentage value: ".concat(value));
        }
        return Number(percentageValue);
    };
    CanvasRenderer.prototype.toPixels = function (value, parentValue) {
        if (value.includes("%")) {
            if (parentValue === undefined) {
                throw new Error("Percentage value not supported");
            }
            var percentageValue = this.getPercentageValue(value);
            return percentageValue * 0.01 * parentValue;
        }
        var pixelValue = value.replace("px", "");
        if (Number.isNaN(Number(pixelValue))) {
            throw new Error("Invalid pixel value: ".concat(value));
        }
        return Number(pixelValue);
    };
    return CanvasRenderer;
}());
exports.CanvasRenderer = CanvasRenderer;
