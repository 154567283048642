import {
  DEV_FEATURE_FLAGS,
  FeatureFlags,
  PROD_FEATURE_FLAGS,
} from "mmo-common";
import { getEnvironment } from "./hooks/useEnvironment";
import { Environment } from "realms-utils";

export const getMMOClientFeatureFlags = (): FeatureFlags => {
  const env = getEnvironment();
  switch (env) {
    case Environment.Development:
      return DEV_FEATURE_FLAGS;
    case Environment.Production:
      return PROD_FEATURE_FLAGS;
  }
};
