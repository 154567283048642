"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RealmModule = void 0;
// A utility class to modularise systems
var RealmModule = /** @class */ (function () {
    function RealmModule() {
        this.systems = new Set();
        this.subModules = new Set();
    }
    RealmModule.prototype.declareSystems = function (systems) {
        var e_1, _a;
        try {
            for (var systems_1 = __values(systems), systems_1_1 = systems_1.next(); !systems_1_1.done; systems_1_1 = systems_1.next()) {
                var s = systems_1_1.value;
                this.systems.add(s);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (systems_1_1 && !systems_1_1.done && (_a = systems_1.return)) _a.call(systems_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    RealmModule.prototype.declareSubModules = function (modules) {
        var e_2, _a;
        try {
            for (var modules_1 = __values(modules), modules_1_1 = modules_1.next(); !modules_1_1.done; modules_1_1 = modules_1.next()) {
                var m = modules_1_1.value;
                this.subModules.add(m);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (modules_1_1 && !modules_1_1.done && (_a = modules_1.return)) _a.call(modules_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    RealmModule.prototype.getSystems = function () {
        return Array.from(this.systems);
    };
    RealmModule.prototype.getSubModules = function () {
        return Array.from(this.subModules);
    };
    return RealmModule;
}());
exports.RealmModule = RealmModule;
