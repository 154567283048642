"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputSystem = exports.defaultInputSystemOptions = void 0;
var realms_engine_1 = require("realms-engine");
exports.defaultInputSystemOptions = {
    captureMouseClicks: false,
    captureMouseMovements: false,
    caseInsensitive: true,
};
var InputSystem = /** @class */ (function (_super) {
    __extends(InputSystem, _super);
    function InputSystem(options) {
        if (options === void 0) { options = exports.defaultInputSystemOptions; }
        var _this = _super.call(this, [], []) || this;
        _this.keyPresses = new Set();
        _this.options = options;
        return _this;
    }
    // Get all currently pressed keys
    InputSystem.prototype.getPressedKeys = function () {
        return Array.from(this.keyPresses);
    };
    InputSystem.prototype.onAdd = function (realm) {
        var _this = this;
        _super.prototype.onAdd.call(this, realm);
        if (window) {
            window.addEventListener("keydown", function (e) {
                var key = _this.options.caseInsensitive ? e.key.toLowerCase() : e.key;
                _this.keyPresses.add(key);
            });
            window.addEventListener("keyup", function (e) {
                var key = _this.options.caseInsensitive ? e.key.toLowerCase() : e.key;
                _this.keyPresses.delete(key);
            });
        }
    };
    return InputSystem;
}(realms_engine_1.System));
exports.InputSystem = InputSystem;
