export const OrangeSlashEffectsAssetMap = {
  slashEffectOrangeArcLarge_frame_1: {
    fileName: "/assets/effects/slash_effects/orange/1/Alternative_3_01.png",
  },
  slashEffectOrangeArcLarge_frame_2: {
    fileName: "/assets/effects/slash_effects/orange/1/Alternative_3_02.png",
  },
  slashEffectOrangeArcLarge_frame_3: {
    fileName: "/assets/effects/slash_effects/orange/1/Alternative_3_03.png",
  },
  slashEffectOrangeArcLarge_frame_4: {
    fileName: "/assets/effects/slash_effects/orange/1/Alternative_3_04.png",
  },
  slashEffectOrangeArcLarge_frame_5: {
    fileName: "/assets/effects/slash_effects/orange/1/Alternative_3_05.png",
  },
  slashEffectOrangeArcLarge_frame_6: {
    fileName: "/assets/effects/slash_effects/orange/1/Alternative_3_06.png",
  },
  slashEffectOrangeArcMedium_frame_1: {
    fileName: "/assets/effects/slash_effects/orange/2/Alternative_3_07.png",
  },
  slashEffectOrangeArcMedium_frame_2: {
    fileName: "/assets/effects/slash_effects/orange/2/Alternative_3_08.png",
  },
  slashEffectOrangeArcMedium_frame_3: {
    fileName: "/assets/effects/slash_effects/orange/2/Alternative_3_09.png",
  },
  slashEffectOrangeArcMedium_frame_4: {
    fileName: "/assets/effects/slash_effects/orange/2/Alternative_3_10.png",
  },
  slashEffectOrangeArcMedium_frame_5: {
    fileName: "/assets/effects/slash_effects/orange/2/Alternative_3_11.png",
  },
  slashEffectOrangeArcMedium_frame_6: {
    fileName: "/assets/effects/slash_effects/orange/2/Alternative_3_12.png",
  },
  slashEffectOrangeArcSmall_frame_1: {
    fileName: "/assets/effects/slash_effects/orange/3/Alternative_3_13.png",
  },
  slashEffectOrangeArcSmall_frame_2: {
    fileName: "/assets/effects/slash_effects/orange/3/Alternative_3_14.png",
  },
  slashEffectOrangeArcSmall_frame_3: {
    fileName: "/assets/effects/slash_effects/orange/3/Alternative_3_15.png",
  },
  slashEffectOrangeArcSmall_frame_4: {
    fileName: "/assets/effects/slash_effects/orange/3/Alternative_3_16.png",
  },
  slashEffectOrangeArcSmall_frame_5: {
    fileName: "/assets/effects/slash_effects/orange/3/Alternative_3_17.png",
  },
  slashEffectOrangeArcSmall_frame_6: {
    fileName: "/assets/effects/slash_effects/orange/3/Alternative_3_18.png",
  },
  slashEffectOrangeBolt_frame_1: {
    fileName: "/assets/effects/slash_effects/orange/4/Alternative_3_19.png",
  },
  slashEffectOrangeBolt_frame_2: {
    fileName: "/assets/effects/slash_effects/orange/4/Alternative_3_20.png",
  },
  slashEffectOrangeBolt_frame_3: {
    fileName: "/assets/effects/slash_effects/orange/4/Alternative_3_21.png",
  },
  slashEffectOrangeBolt_frame_4: {
    fileName: "/assets/effects/slash_effects/orange/4/Alternative_3_22.png",
  },
  slashEffectOrangeBolt_frame_5: {
    fileName: "/assets/effects/slash_effects/orange/4/Alternative_3_23.png",
  },
  slashEffectOrangeBolt_frame_6: {
    fileName: "/assets/effects/slash_effects/orange/4/Alternative_3_24.png",
  },
  slashEffectOrangeStraight_frame_1: {
    fileName: "/assets/effects/slash_effects/orange/5/Alternative_3_25.png",
  },
  slashEffectOrangeStraight_frame_2: {
    fileName: "/assets/effects/slash_effects/orange/5/Alternative_3_26.png",
  },
  slashEffectOrangeStraight_frame_3: {
    fileName: "/assets/effects/slash_effects/orange/5/Alternative_3_27.png",
  },
  slashEffectOrangeStraight_frame_4: {
    fileName: "/assets/effects/slash_effects/orange/5/Alternative_3_28.png",
  },
  slashEffectOrangeStraight_frame_5: {
    fileName: "/assets/effects/slash_effects/orange/5/Alternative_3_29.png",
  },
  slashEffectOrangeStraight_frame_6: {
    fileName: "/assets/effects/slash_effects/orange/5/Alternative_3_30.png",
  },
} as const;
