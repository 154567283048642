import { MMONetSchema } from "mmo-common";
import {
  networkPhysicsOutlineSyncSchemaMap,
  SyncMethod,
  SyncSchemaMap,
} from "realms-engine-network-client";

const creatureSyncSchemaMap = {
  name: SyncMethod.DiscreteStart,
  position: {
    x: SyncMethod.Linear,
    y: SyncMethod.Linear,
    z: SyncMethod.Linear,
  },
  quaternion: SyncMethod.Linear,
  health: SyncMethod.DiscreteStart,
  maxHealth: SyncMethod.DiscreteStart,
  animation: SyncMethod.DiscreteStart,
};

const characterSyncSchemaMap = {
  id: SyncMethod.DiscreteStart,
  username: SyncMethod.DiscreteStart,
  gender: SyncMethod.DiscreteStart,
  skinColor: SyncMethod.DiscreteStart,
  eyeColor: SyncMethod.DiscreteStart,
  eyesType: SyncMethod.DiscreteStart,
  heightScale: SyncMethod.DiscreteStart,
  widthScale: SyncMethod.DiscreteStart,
  hairBaseType: SyncMethod.DiscreteStart,
  hairColor: SyncMethod.DiscreteStart,
  hairFringeType: SyncMethod.DiscreteStart,
  hairBackType: SyncMethod.DiscreteStart,
  hairSideType: SyncMethod.DiscreteStart,
  hairTailType: SyncMethod.DiscreteStart,
  beardType: SyncMethod.DiscreteStart,
  moustacheType: SyncMethod.DiscreteStart,
};

const shapeSyncSchemaMap = {
  type: SyncMethod.DiscreteStart,
  position: {
    x: SyncMethod.Linear,
    y: SyncMethod.Linear,
    z: SyncMethod.Linear,
  },
  quaternion: SyncMethod.Linear,
  properties: SyncMethod.DiscreteStart,
};

export const mmoSyncSchemaMap: SyncSchemaMap<MMONetSchema> = {
  ...networkPhysicsOutlineSyncSchemaMap,
  character: {
    character: characterSyncSchemaMap,
    creature: creatureSyncSchemaMap,
  },
  player: {
    clientId: SyncMethod.DiscreteStart,
    character: characterSyncSchemaMap,
    characterId: SyncMethod.DiscreteStart,
    creature: creatureSyncSchemaMap,
    inRangeInteractableIds: SyncMethod.DiscreteStart,
  },
  goblin: creatureSyncSchemaMap,
  shape: shapeSyncSchemaMap,
  interactable: {
    interactableId: SyncMethod.DiscreteStart,
    options: SyncMethod.DiscreteStart,
    position: {
      x: SyncMethod.Linear,
      y: SyncMethod.Linear,
      z: SyncMethod.Linear,
    },
  },
};
