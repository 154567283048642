var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AuthErrorType, } from "realmsbase-common";
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../providers";
import { useErrorToast } from "./useErrorToast";
var URL = process.env.NODE_ENV !== "production"
    ? "http://localhost:5555/api/"
    : "https://arcadian-realms-server.fly.dev/api/";
export var APICallStatus;
(function (APICallStatus) {
    APICallStatus[APICallStatus["Idle"] = 0] = "Idle";
    APICallStatus[APICallStatus["Loading"] = 1] = "Loading";
    APICallStatus[APICallStatus["Error"] = 2] = "Error";
})(APICallStatus || (APICallStatus = {}));
export function isAPICallError(e) {
    if (typeof e !== "object" || e === null) {
        return false;
    }
    if (typeof e["statusCode"] !== "number") {
        return false;
    }
    if (typeof e["message"] !== "string") {
        return false;
    }
    return true;
}
// Stores the useAPI Cache
var useAPICache = {};
/**
 * A use hook to call the api
 *
 */
export var useAPI = function (endpoint, method, options) {
    var _a = useState(APICallStatus.Idle), status = _a[0], setStatus = _a[1];
    var _b = useState(), data = _b[0], setData = _b[1];
    var _c = useState(), error = _c[0], setError = _c[1];
    var _d = useState(false), hasAutoFetched = _d[0], setHasAutoFetched = _d[1];
    var clearSessionToken = useContext(AuthContext).clearSessionToken;
    var httpAPI = useCallback(function (params, auth) { return __awaiter(void 0, void 0, void 0, function () {
        var queryParams, fetchOptions, response, errorMessage, e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    // Set Headers
                    auth = auth !== null && auth !== void 0 ? auth : options === null || options === void 0 ? void 0 : options.auth;
                    queryParams = "";
                    fetchOptions = {
                        method: method.toUpperCase(),
                    };
                    if (method === "get") {
                        queryParams = new URLSearchParams(params).toString();
                    }
                    else {
                        fetchOptions.headers = { "Content-Type": "application/json" };
                        fetchOptions.body = JSON.stringify(params);
                    }
                    if (auth) {
                        fetchOptions.headers = __assign(__assign({}, fetchOptions.headers), { Authorization: "Bearer ".concat(auth.token) });
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    return [4 /*yield*/, fetch("".concat((_a = options === null || options === void 0 ? void 0 : options.url) !== null && _a !== void 0 ? _a : URL).concat(endpoint) +
                            (queryParams.length !== 0 ? "?" : "") +
                            queryParams, fetchOptions)];
                case 2:
                    response = _b.sent();
                    // Success
                    if (response.status === 200) {
                        return [2 /*return*/, response.json()];
                    }
                    return [4 /*yield*/, response.text()];
                case 3:
                    errorMessage = _b.sent();
                    // Clear session token
                    if (errorMessage === AuthErrorType.ExpiredToken ||
                        errorMessage === AuthErrorType.InvalidToken) {
                        clearSessionToken();
                    }
                    return [2 /*return*/, {
                            statusCode: response.status,
                            message: errorMessage,
                        }];
                case 4:
                    e_1 = _b.sent();
                    return [2 /*return*/, {
                            statusCode: 400,
                            message: e_1.message,
                        }];
                case 5: return [2 /*return*/];
            }
        });
    }); }, [endpoint, method, options === null || options === void 0 ? void 0 : options.auth]);
    var callApi = useCallback(function (params, auth) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    // Use cache if we can
                    if ((options === null || options === void 0 ? void 0 : options.cache) && useAPICache[endpoint] !== undefined) {
                        setData(useAPICache[endpoint]);
                        return [2 /*return*/];
                    }
                    // Ignore if already loading
                    if (status === APICallStatus.Loading) {
                        return [2 /*return*/];
                    }
                    setStatus(APICallStatus.Loading);
                    return [4 /*yield*/, httpAPI(params, auth)];
                case 1:
                    response = _a.sent();
                    // Error
                    if (isAPICallError(response)) {
                        setStatus(APICallStatus.Error);
                        setError(response);
                        return [2 /*return*/];
                    }
                    // Success
                    setData(response);
                    if (options === null || options === void 0 ? void 0 : options.cache) {
                        useAPICache[endpoint] = response;
                    }
                    setStatus(APICallStatus.Idle);
                    return [2 /*return*/];
            }
        });
    }); }, [endpoint, method, options === null || options === void 0 ? void 0 : options.auth, status, httpAPI]);
    useEffect(function () {
        if ((options === null || options === void 0 ? void 0 : options.autoFetch) && !hasAutoFetched) {
            callApi(options.autoFetchParams);
            setHasAutoFetched(true);
        }
    }, [options, callApi]);
    useErrorToast((options === null || options === void 0 ? void 0 : options.toastOnError) ? error : undefined);
    return {
        // Fetches the data
        fetch: callApi,
        // Fetches the data without using the hook
        httpAPI: httpAPI,
        // The status of the fetch
        status: status,
        // The data of the request.
        data: data,
        // Manually sets the data from the fetch
        setData: setData,
        // Clears the data
        clearData: function () {
            setData(undefined);
        },
        // The error returned from the fetch
        error: error,
        // Clears the stored cache for the endpoint
        clearCache: function () {
            useAPICache[endpoint] = undefined;
        },
    };
};
