"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TweenGameObject = void 0;
var core_1 = require("../core");
var utils_1 = require("../utils");
var TweenGameObject = /** @class */ (function (_super) {
    __extends(TweenGameObject, _super);
    function TweenGameObject(getValue, setValue) {
        var _this = _super.call(this) || this;
        _this.getValue = getValue;
        _this.setValue = setValue;
        _this.startTweenValue = null;
        _this.endTweenValue = null;
        _this.timePassed = null;
        _this.tweenDuration = null;
        return _this;
    }
    /*
     * Tweens the target value with the duration in MS.
     * If a tween is already running, it gets resetted
     */
    TweenGameObject.prototype.tween = function (target, duration) {
        this.startTweenValue = this.getValue();
        this.endTweenValue = target;
        this.timePassed = 0;
        this.tweenDuration = duration;
    };
    TweenGameObject.prototype.step = function (delta) {
        if (this.timePassed === null ||
            this.tweenDuration === null ||
            this.startTweenValue === null ||
            this.endTweenValue === null) {
            return;
        }
        // Step
        this.timePassed = Math.min(this.timePassed + delta, this.tweenDuration);
        // Check if finished
        if (this.timePassed === this.tweenDuration) {
            this.setValue(this.endTweenValue);
            this.timePassed = null;
            this.startTweenValue = null;
            this.endTweenValue = null;
            this.tweenDuration = null;
            return;
        }
        // Tween
        var alpha = this.timePassed / this.tweenDuration;
        this.setValue(tweenValue(this.startTweenValue, this.endTweenValue, alpha));
    };
    return TweenGameObject;
}(core_1.ProcessGameObject));
exports.TweenGameObject = TweenGameObject;
function tweenValue(start, end, alpha) {
    if (typeof start === "number" && typeof end === "number") {
        return (0, utils_1.lerp)(start, end, alpha);
    }
    else if ((0, utils_1.isQuat)(start) && (0, utils_1.isQuat)(end)) {
        return (0, utils_1.quatSlerp)(start, end, alpha);
    }
    else if ((0, utils_1.isVector3)(start) && (0, utils_1.isVector3)(end)) {
        return (0, utils_1.lerpVector3)(start, end, alpha);
    }
    console.error({
        start: start,
        end: end,
    });
    throw Error("Start and End are not the same type or is an unsupported type");
}
