"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDataChanges = void 0;
var lodash_1 = require("lodash");
/*
 * Returns the changes between two data
 */
function getDataChanges(oldData, newData) {
    var e_1, _a;
    // Handle null cases
    if (oldData === null || newData === null) {
        // Both a null so no changes
        if (oldData === newData) {
            return {};
        }
        return newData;
    }
    var changes = {};
    var _loop_1 = function (k, newValue) {
        var oldValue = oldData[k];
        if ((0, lodash_1.isPlainObject)(newValue) && (0, lodash_1.isPlainObject)(oldValue)) {
            // Plain object so recursively add changes
            var subChanges = getDataChanges(oldValue, newValue);
            if (subChanges === null || Object.keys(subChanges).length !== 0) {
                changes[k] = subChanges;
            }
        }
        else if ((0, lodash_1.isArray)(newValue) && (0, lodash_1.isArray)(oldValue)) {
            // If array is not exactly the same then just set as new array
            if (newValue.length !== oldValue.length ||
                newValue.some(function (v, i) {
                    var changes = getDataChanges(oldValue[i], v);
                    return changes === null || Object.keys(changes).length !== 0;
                })) {
                changes[k] = newValue;
            }
        }
        else if (newValue !== oldValue) {
            changes[k] = newValue;
        }
    };
    try {
        for (var _b = __values(Object.entries(newData)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var _d = __read(_c.value, 2), k = _d[0], newValue = _d[1];
            _loop_1(k, newValue);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return changes;
}
exports.getDataChanges = getDataChanges;
