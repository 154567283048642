import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from "react-router-dom";
import { AppProvider } from "./AppProvider";
import { LoginPage } from "./pages/LoginPage";
import { RegisterPage } from "./pages/RegisterPage";
import { ScriptPage } from "./pages/scriptPage/ScriptPage";
import { ScriptsSearchPage } from "./pages/ScriptsSearchPage";
export function createRealmsbaseRoutes(rootPath) {
    return [
        {
            path: rootPath + "register",
            element: (_jsx(AppProvider, { children: _jsx(RegisterPage, {}) })),
        },
        {
            path: rootPath + "login",
            element: (_jsx(AppProvider, { children: _jsx(LoginPage, {}) })),
        },
        {
            path: rootPath + "scripts/search",
            element: (_jsx(AppProvider, { children: _jsx(ScriptsSearchPage, {}) })),
        },
        {
            path: rootPath + "script/:scriptId",
            element: (_jsx(AppProvider, { children: _jsx(ScriptPage, {}) })),
        },
        {
            path: rootPath + "",
            element: _jsx(Navigate, { to: rootPath + "login" }),
        },
    ];
}
