/**
 * Selects a three object via a query
 *
 * Children are separated via "."
 * Example: body.hand (Selecting the "hand" child of the "body" object)
 *
 * The "*" wildcard means all children
 * Example: body.* (Select all children of body)
 */
export function threeQuerySelector(
  query: string,
  target: THREE.Object3D
): THREE.Object3D | null {
  const children = query.split(".");
  const firstChild = children[0];
  for (const c of target.children) {
    if (c.name === firstChild || firstChild === "*") {
      if (children.length === 1) {
        return c;
      }
      return threeQuerySelector(children.slice(1).join("."), c);
    }
  }
  return null;
}

export function threeQuerySelectorAll(
  query: string,
  target: THREE.Object3D
): THREE.Object3D[] {
  const children = query.split(".");
  const firstChild = children[0];
  const matches = [];
  for (const c of target.children) {
    if (c.name === firstChild || firstChild === "*") {
      if (children.length === 1) {
        matches.push(c);
      } else {
        matches.push(...threeQuerySelectorAll(children.slice(1).join("."), c));
      }
    }
  }
  return matches;
}
