"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROD_FEATURE_FLAGS = exports.DEV_FEATURE_FLAGS = void 0;
exports.DEV_FEATURE_FLAGS = {
    physicsOutline: true,
    devTools: true,
};
exports.PROD_FEATURE_FLAGS = {
    physicsOutline: false,
    devTools: false,
};
