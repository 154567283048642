"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RpgCard = exports.RpgCardVariant = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@chakra-ui/react");
var GradientOverlay_1 = require("./GradientOverlay");
var Overlay_1 = require("./Overlay");
var RpgCardVariant;
(function (RpgCardVariant) {
    RpgCardVariant["Normal"] = "normal";
    RpgCardVariant["Highlighted"] = "highlighted";
    RpgCardVariant["Ghost"] = "ghost";
})(RpgCardVariant || (exports.RpgCardVariant = RpgCardVariant = {}));
var RpgCard = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? RpgCardVariant.Normal : _b, children = _a.children, padding = _a.padding, contentProps = _a.contentProps, props = __rest(_a, ["variant", "children", "padding", "contentProps"]);
    var borderSize = 0.5;
    return ((0, jsx_runtime_1.jsxs)(react_1.Box, __assign({ position: "relative", padding: padding !== null && padding !== void 0 ? padding : 0 }, props, { children: [(0, jsx_runtime_1.jsx)(Overlay_1.Overlay, { zIndex: 0, opacity: variant === RpgCardVariant.Ghost ? 0 : 1, transition: "opacity 0.25s" }), (0, jsx_runtime_1.jsx)(react_1.Box, { opacity: variant === RpgCardVariant.Ghost ? 0 : 1, transition: "opacity 0.25s", position: "absolute", top: 0, right: 0, left: 0, height: borderSize, backgroundColor: "white" }), (0, jsx_runtime_1.jsx)(react_1.Box, __assign({ zIndex: 1, position: "relative", paddingTop: borderSize, paddingBottom: borderSize }, contentProps, { children: children })), (0, jsx_runtime_1.jsx)(react_1.Box, { opacity: variant === RpgCardVariant.Ghost ? 0 : 1, transition: "opacity 0.25s", position: "absolute", height: borderSize, bottom: 0, right: 0, left: 0, backgroundColor: "white" }), (0, jsx_runtime_1.jsx)(GradientOverlay_1.GradientOverlay, { bgGradient: "linear(to-r, blackAlpha.800, secondaryAlpha.500, blackAlpha.800)", opacity: variant === RpgCardVariant.Highlighted ? 1 : 0 }), (0, jsx_runtime_1.jsx)(GradientOverlay_1.GradientOverlay, { bgGradient: "linear(to-r, blackAlpha.800, blackAlpha.50, blackAlpha.800)", opacity: variant === RpgCardVariant.Normal ? 1 : 0 })] })));
};
exports.RpgCard = RpgCard;
