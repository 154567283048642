"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UWebsocketsServerConnection = void 0;
var realms_utils_1 = require("realms-utils");
var UWebsocketsServerConnection = /** @class */ (function () {
    function UWebsocketsServerConnection(url, options) {
        if (options === void 0) { options = {}; }
        this.options = options;
        this.eventManager = new realms_utils_1.EventManager();
        this.addListener = this.eventManager.addListener;
        this.removeListener = this.eventManager.removeListener;
        this.once = this.eventManager.once;
        this.addAnyListener = this.eventManager.addAnyListener;
        this.state = "close";
        this.websocket = null;
        this.messageBuffer = [];
        var urlObject = new URL(url);
        var port = urlObject.port;
        this.hostnameWithPort =
            urlObject.hostname + (port.length > 0 ? ":".concat(port) : "");
    }
    Object.defineProperty(UWebsocketsServerConnection.prototype, "connected", {
        get: function () {
            return !!this.websocket && this.state === "open";
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Connects to a server
     */
    UWebsocketsServerConnection.prototype.connect = function (auth) {
        var _this = this;
        var url = "".concat(this.options.ssl ? "wss" : "ws", "://").concat(this.hostnameWithPort, "?auth=").concat(JSON.stringify(auth));
        this.websocket = new WebSocket(url);
        this.websocket.onmessage = function (e) {
            var _a = __read(JSON.parse(e.data), 2), event = _a[0], data = _a[1];
            _this.eventManager.dispatch(event, data);
        };
        this.websocket.onopen = function () {
            _this.state = "open";
            // Clear buffer
            while (_this.messageBuffer.length > 0) {
                var message = _this.messageBuffer.shift();
                if (message) {
                    _this.emit(message[0], message[1]);
                }
            }
            _this.eventManager.dispatch("connect", undefined);
        };
        this.websocket.onclose = function () {
            _this.state = "close";
            _this.eventManager.dispatch("disconnect", undefined);
        };
    };
    UWebsocketsServerConnection.prototype.disconnect = function () {
        var _a;
        (_a = this.websocket) === null || _a === void 0 ? void 0 : _a.close();
    };
    UWebsocketsServerConnection.prototype.emit = function (event, data) {
        if (!this.websocket) {
            throw new Error("Attempted to call emit before connection");
        }
        // Store in buffer
        if (this.state !== "open") {
            this.messageBuffer.push([event, data]);
            return;
        }
        this.websocket.send(JSON.stringify([event, data]));
    };
    return UWebsocketsServerConnection;
}());
exports.UWebsocketsServerConnection = UWebsocketsServerConnection;
