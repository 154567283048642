"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCannonBox = void 0;
var utils_1 = require("../utils");
var cannon_1 = require("./cannon");
/*
 * Creates a CANNON Box
 */
function createCannonBox(size) {
    // Divide by two since CANNON uses half extends
    return new cannon_1.CANNON.Box((0, utils_1.toCannonVec3)((0, utils_1.vector3Multiply)(size, 0.5)));
}
exports.createCannonBox = createCannonBox;
