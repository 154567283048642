"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.interpolateState = void 0;
var realms_engine_1 = require("realms-engine");
var sync_1 = require("./sync");
function interpolateState(oldState, newState, alpha, syncSchema) {
    var e_1, _a;
    var lerpedState = {};
    try {
        for (var _b = __values(Object.entries(syncSchema)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var _d = __read(_c.value, 2), field = _d[0], fieldSchema = _d[1];
            if ((0, sync_1.isSyncMethod)(fieldSchema)) {
                var start = oldState[field];
                var end = newState[field];
                lerpedState[field] = interpolateField(start, end, alpha, fieldSchema);
            }
            else if ((0, sync_1.isSyncSchema)(fieldSchema)) {
                var start = oldState[field];
                var end = newState[field];
                lerpedState[field] = interpolateState(start, end, alpha, fieldSchema);
            }
            else {
                throw new Error("Invalid sync schema for field: ".concat(field, ". The schema is ").concat(JSON.stringify(syncSchema)));
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return lerpedState;
}
exports.interpolateState = interpolateState;
function interpolateField(start, end, alpha, method) {
    switch (method) {
        case sync_1.SyncMethod.Linear: {
            if (typeof start === "number" && typeof end === "number") {
                return (0, realms_engine_1.lerp)(start, end, alpha);
            }
            else if ((0, realms_engine_1.isVector3)(start) && (0, realms_engine_1.isVector3)(end)) {
                return (0, realms_engine_1.lerpVector3)(start, end, alpha);
            }
            else if ((0, realms_engine_1.isVector2)(start) && (0, realms_engine_1.isVector2)(end)) {
                return (0, realms_engine_1.lerpVector2)(start, end, alpha);
            }
            else if ((0, realms_engine_1.isQuat)(start) && (0, realms_engine_1.isQuat)(end)) {
                return (0, realms_engine_1.quatSlerp)(start, end, alpha);
            }
            throw new Error("SyncMethod.Linear is used for an unsupported type: ".concat(JSON.stringify({
                start: start,
                end: end,
            })));
        }
        case sync_1.SyncMethod.LinearWhole: {
            if (typeof start === "number" && typeof end === "number") {
                return Math.round((0, realms_engine_1.lerp)(start, end, alpha));
            }
            else if ((0, realms_engine_1.isVector3)(start) && (0, realms_engine_1.isVector3)(end)) {
                var _a = (0, realms_engine_1.lerpVector3)(start, end, alpha), x = _a.x, y = _a.y, z = _a.z;
                return {
                    x: Math.round(x),
                    y: Math.round(y),
                    z: Math.round(z),
                };
            }
            else if ((0, realms_engine_1.isVector2)(start) && (0, realms_engine_1.isVector2)(end)) {
                var _b = (0, realms_engine_1.lerpVector2)(start, end, alpha), x = _b.x, y = _b.y;
                return {
                    x: Math.round(x),
                    y: Math.round(y),
                };
            }
            throw new Error("SyncMethod.LinearWhole is used for an unsupported type: ".concat(JSON.stringify({
                start: start,
                end: end,
            })));
        }
        case sync_1.SyncMethod.DiscreteMiddle: {
            if (alpha >= 0.5) {
                return end;
            }
            return start;
        }
        case sync_1.SyncMethod.DiscreteStart: {
            if (alpha > 0) {
                return end;
            }
            return start;
        }
        case sync_1.SyncMethod.DiscreteEnd: {
            if (alpha < 1) {
                return start;
            }
            return end;
        }
        default: {
            throw new Error("Unsupported sync method ".concat(method, " for field. ").concat(JSON.stringify({
                start: start,
                end: end,
            })));
        }
    }
}
