"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPositionable = exports.positionableSchema = void 0;
var realms_utils_1 = require("realms-utils");
exports.positionableSchema = {
    getPosition: "function",
    setPosition: "function",
};
/**
 * Checks if it is a positionable
 */
exports.isPositionable = (0, realms_utils_1.isInterface)(exports.positionableSchema);
