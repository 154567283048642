"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupSystem = void 0;
var gameObject_1 = require("./gameObject");
var system_1 = require("./system");
var GroupSystem = /** @class */ (function (_super) {
    __extends(GroupSystem, _super);
    function GroupSystem() {
        var _this = _super.call(this, [gameObject_1.GameObject]) || this;
        /*
         * Maps the group to the set of objects in that group
         */
        _this.groupGameObjects = {};
        return _this;
    }
    /*
     * Get all the game objects in the given group
     */
    GroupSystem.prototype.getGameObjectsInGroup = function (group) {
        var _a;
        return Array.from((_a = this.groupGameObjects[group]) !== null && _a !== void 0 ? _a : []);
    };
    /*
     * Used internally by game object - do not call manually
     */
    GroupSystem.prototype._addGameObjectToGroup = function (gameObject, group) {
        var groupSet = this.groupGameObjects[group];
        if (!groupSet) {
            groupSet = new Set();
            this.groupGameObjects[group] = groupSet;
        }
        groupSet.add(gameObject);
    };
    /*
     * Used internally by game object - do not call manually
     */
    GroupSystem.prototype._removeGameObjectFromGroup = function (gameObject, group) {
        var groupSet = this.groupGameObjects[group];
        if (groupSet) {
            groupSet.delete(gameObject);
        }
    };
    GroupSystem.prototype.onTargetGameObjectEnter = function (gameObject) {
        var e_1, _a;
        try {
            // Add each of the groups of the game object
            // to the mapping
            for (var _b = __values(gameObject.getGroups()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var group = _c.value;
                this._addGameObjectToGroup(gameObject, group);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    GroupSystem.prototype.onTargetGameObjectExit = function (gameObject) {
        var e_2, _a;
        try {
            // Remove each of the groups of the game object
            // from the mapping
            for (var _b = __values(gameObject.getGroups()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var group = _c.value;
                this._removeGameObjectFromGroup(gameObject, group);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    return GroupSystem;
}(system_1.System));
exports.GroupSystem = GroupSystem;
