import { Box, Heading, Progress } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AssetManagerLoadingState } from "realms-three-utils";
import { GameScreen } from "../components/GameScreen";
import loginImg from "../assets/login.jpg";
import { MMOAssetManager } from "../game";
import { Overlay } from "mmo-ui";

interface AssetGuardProps {
  children: any;
}

export const AssetGuard = ({ children }: AssetGuardProps) => {
  const [loadingState, setLoadingState] =
    useState<AssetManagerLoadingState | null>(null);

  useEffect(() => {
    if (!MMOAssetManager.hasLoaded()) {
      MMOAssetManager.loadAssets((state) => {
        setLoadingState({ ...state });
      });
    }
  }, []);

  if (MMOAssetManager.hasLoaded()) {
    return children;
  }

  const assetsLoaded = loadingState?.assetsLoaded ?? 0;
  const assetsToLoad = loadingState?.assetsToLoad ?? 100;
  return (
    <GameScreen>
      <Box
        alignItems={"center"}
        backgroundImage={loginImg}
        display={"flex"}
        height={"100%"}
        justifyContent={"center"}
        position={"relative"}
      >
        <Overlay color="blackAlpha.700" zIndex={0} />
        <Box
          zIndex={1}
          display="flex"
          flexDirection={"column"}
          alignItems="center"
        >
          <Heading color={"gray.50"} marginBottom={4}>
            Loading Assets ...
          </Heading>
          <Progress
            width="100%"
            colorScheme={"primary"}
            min={0}
            max={assetsToLoad}
            value={assetsLoaded}
            height="32px"
          />
          <Box
            marginTop={4}
            fontWeight={"bold"}
            fontSize="md"
            color={"gray.500"}
          >{`${assetsLoaded} / ${assetsToLoad} assets loaded`}</Box>
        </Box>
      </Box>
    </GameScreen>
  );
};
