"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.cannonToQuat = exports.toCannonQuaternion = exports.quatSlerp = exports.vector2DToQuat = exports.quatToVector2D = exports.quatConjugate = exports.quatDistanceTo = exports.quatDot = exports.quatNormalize = exports.quatFromAxisAngle = exports.quatDifferences = exports.quatMultiply = exports.quatSubtract = exports.quatAdd = exports.quatZero = exports.isQuat = void 0;
var CANNON = __importStar(require("cannon-es"));
var vector2_1 = require("./vector2");
function isQuat(v) {
    if (typeof v === "object" &&
        v !== null &&
        Object.keys(v).length === 4 &&
        typeof v.x === "number" &&
        typeof v.y === "number" &&
        typeof v.z === "number" &&
        typeof v.w === "number") {
        return true;
    }
    return false;
}
exports.isQuat = isQuat;
function quatZero() {
    return {
        x: 0,
        y: 0,
        z: 0,
        w: 1,
    };
}
exports.quatZero = quatZero;
function quatAdd(q1, q2) {
    return {
        x: q1.x + q2.x,
        y: q1.y + q2.y,
        z: q1.z + q2.z,
        w: q1.w + q2.w,
    };
}
exports.quatAdd = quatAdd;
function quatSubtract(q1, q2) {
    return {
        x: q1.x - q2.x,
        y: q1.y - q2.y,
        z: q1.z - q2.z,
        w: q1.w - q2.w,
    };
}
exports.quatSubtract = quatSubtract;
/**
 * Multiplies two quaternions together
 */
function quatMultiply(q1, q2) {
    return {
        x: q1.w * q2.x + q1.x * q2.w + q1.y * q2.z - q1.z * q2.y,
        y: q1.w * q2.y - q1.x * q2.z + q1.y * q2.w + q1.z * q2.x,
        z: q1.w * q2.z + q1.x * q2.y - q1.y * q2.x + q1.z * q2.w,
        w: q1.w * q2.w - q1.x * q2.x - q1.y * q2.y - q1.z * q2.z,
    };
}
exports.quatMultiply = quatMultiply;
function quatDifferences(q1, q2) {
    var differences = {};
    if (q1.x !== q2.x) {
        differences.x = q2.x;
    }
    if (q1.y !== q2.y) {
        differences.y = q2.y;
    }
    if (q1.z !== q2.z) {
        differences.z = q2.z;
    }
    if (q1.w !== q2.w) {
        differences.w = q2.w;
    }
    return differences;
}
exports.quatDifferences = quatDifferences;
function quatFromAxisAngle(axis, angle) {
    return {
        x: axis.x * Math.sin(angle / 2),
        y: axis.y * Math.sin(angle / 2),
        z: axis.z * Math.sin(angle / 2),
        w: Math.cos(angle / 2),
    };
}
exports.quatFromAxisAngle = quatFromAxisAngle;
function quatNormalize(q) {
    var x = q.x, y = q.y, z = q.z, w = q.w;
    var d = Math.sqrt(x * x + y * y + z * z + w * w);
    return {
        x: x / d,
        y: y / d,
        z: z / d,
        w: w / d,
    };
}
exports.quatNormalize = quatNormalize;
function quatDot(q1, q2) {
    return q1.x * q2.x + q1.y * q2.y + q1.z * q2.z + q1.w * q2.w;
}
exports.quatDot = quatDot;
function quatDistanceTo(q1, q2) {
    return Math.sqrt(Math.pow(q1.x - q2.x, 2) +
        Math.pow(q1.y - q2.y, 2) +
        Math.pow(q1.z - q2.z, 2) +
        Math.pow(q1.w - q2.w, 2));
}
exports.quatDistanceTo = quatDistanceTo;
function quatConjugate(q) {
    var cannonQ = toCannonQuaternion(q);
    return cannonToQuat(cannonQ.conjugate());
}
exports.quatConjugate = quatConjugate;
/*
 * Gets the rotation of a quaternion around a 2d plane as a unit vector
 */
function quatToVector2D(q) {
    var _a = __read(toCannonQuaternion(q).toAxisAngle(new CANNON.Vec3(0, 1, 0)), 2), _ = _a[0], angle = _a[1];
    return (0, vector2_1.vector2FlipOverY)((0, vector2_1.vector2FromAngle)(angle));
}
exports.quatToVector2D = quatToVector2D;
function vector2DToQuat(v) {
    return cannonToQuat(new CANNON.Quaternion().setFromAxisAngle(new CANNON.Vec3(0, 1, 0), (0, vector2_1.vector2Angle)((0, vector2_1.vector2FlipOverY)(v))));
}
exports.vector2DToQuat = vector2DToQuat;
/*
 * Slerps the two quats.
 * Copied off the threejs quaternion code here:
 * https://github.com/mrdoob/three.js/blob/dev/src/math/Quaternion.js#L16
 */
function quatSlerp(q1, q2, alpha) {
    if (alpha === 0) {
        return q1;
    }
    else if (alpha === 1) {
        return q2;
    }
    var thisx = q1.x;
    var thisy = q1.y;
    var thisz = q1.z;
    var thisw = q1.w;
    var cosHalfTheta = quatDot(q1, q2);
    if (cosHalfTheta < 0) {
        thisw = -1 * q2.w;
        thisx = -1 * q2.x;
        thisy = -1 * q2.y;
        thisz = -1 * q2.z;
        cosHalfTheta = -1 * cosHalfTheta;
    }
    else {
        thisw = q2.w;
        thisx = q2.x;
        thisy = q2.y;
        thisz = q2.z;
    }
    if (cosHalfTheta >= 1) {
        return q1;
    }
    var sqrSinHalfTheta = 1 - cosHalfTheta * cosHalfTheta;
    if (sqrSinHalfTheta <= Number.EPSILON) {
        var s = 1 - alpha;
        thisw = s * q1.w + alpha * thisw;
        thisx = s * q1.x + alpha * thisx;
        thisy = s * q1.y + alpha * thisy;
        thisz = s * q1.z + alpha * thisz;
        return quatNormalize({
            w: thisw,
            x: thisx,
            y: thisy,
            z: thisz,
        });
    }
    var sinHalfTheta = Math.sqrt(sqrSinHalfTheta);
    var halfTheta = Math.atan2(sinHalfTheta, cosHalfTheta);
    var ratioA = Math.sin((1 - alpha) * halfTheta) / sinHalfTheta;
    var ratioB = Math.sin(alpha * halfTheta) / sinHalfTheta;
    thisw = q1.w * ratioA + thisw * ratioB;
    thisx = q1.x * ratioA + thisx * ratioB;
    thisy = q1.y * ratioA + thisy * ratioB;
    thisz = q1.z * ratioA + thisz * ratioB;
    return quatNormalize({
        w: thisw,
        x: thisx,
        y: thisy,
        z: thisz,
    });
}
exports.quatSlerp = quatSlerp;
// Utility function to transform to Cannon Quaternion
function toCannonQuaternion(q) {
    return new CANNON.Quaternion(q.x, q.y, q.z, q.w);
}
exports.toCannonQuaternion = toCannonQuaternion;
// Utility function to transform from Cannon Quaternion
function cannonToQuat(q) {
    return {
        x: q.x,
        y: q.y,
        z: q.z,
        w: q.w,
    };
}
exports.cannonToQuat = cannonToQuat;
