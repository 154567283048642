"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Hooks = void 0;
var Hooks = /** @class */ (function () {
    function Hooks(oldNode, onSetState) {
        this.oldNode = oldNode;
        this.onSetState = onSetState;
        this.useEffectStates = [];
        this.states = [];
        this.useStateIndex = -1;
    }
    Hooks.prototype.getUseEffectStates = function () {
        return this.useEffectStates;
    };
    Hooks.prototype.getStates = function () {
        return this.states;
    };
    /**
     * Use state hook
     */
    Hooks.prototype.useState = function (defaultState) {
        var _this = this;
        this.useStateIndex += 1;
        var currentIndex = this.useStateIndex;
        var oldState = this.oldNode
            ? this.oldNode._useStates[currentIndex]
            : null;
        var currentState = this.oldNode ? oldState === null || oldState === void 0 ? void 0 : oldState.state : defaultState;
        this.states.push({ state: currentState });
        return [currentState, function (state) { return _this.onSetState(currentIndex, state); }];
    };
    /**
     * Runs the effect callback whenever the dependency list changes.
     * The callback can return a cleanup function that runs when the use effect ends
     */
    Hooks.prototype.useEffect = function (effect, dependencies) {
        this.useEffectStates.push({
            effect: effect,
            dependencies: dependencies,
        });
    };
    return Hooks;
}());
exports.Hooks = Hooks;
