"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateObject = exports.validate = void 0;
var models_1 = require("./models");
var utils_1 = require("./utils");
/**
 * Validates an object. Throws an error if not valid
 */
function validate(obj, paramDefinitions, options) {
    var e_1, _a;
    if (options === void 0) { options = {}; }
    if (typeof obj !== "object" || Array.isArray(obj) || obj === null) {
        throw new models_1.ValidationError("Expected an object but did not receive one: ".concat(obj));
    }
    try {
        for (var _b = __values(Object.entries(paramDefinitions)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var _d = __read(_c.value, 2), param = _d[0], paramDef = _d[1];
            // Non existant field
            var value = obj[param];
            if (!paramDef) {
                continue;
            }
            // Check field type is correct
            if (value === undefined) {
                // If the constraint is not optional throw error
                if (!(0, utils_1.getParamDefinitionConstraints)(paramDef).find(function (c) { return c.type === models_1.ValidationParamConstraintType.IsOptional; })) {
                    throw new models_1.ValidationError("Parameter ".concat(param, " is missing"));
                }
            }
            else if (value === null) {
                // If the constraint is not nulllable throw error
                if (!(0, utils_1.getParamDefinitionConstraints)(paramDef).find(function (c) { return c.type === models_1.ValidationParamConstraintType.IsNullable; })) {
                    throw new models_1.ValidationError("Parameter ".concat(param, " is null"));
                }
            }
            else {
                validateType(paramDef, param, value, options);
            }
            // Check constraints
            validateConstraints(paramDef, param, value);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
}
exports.validate = validate;
/**
 * Validate that the type is correct for a given parameter
 */
function validateType(paramDef, param, value, options) {
    var e_2, _a;
    // Not undefined so check is valid type
    switch (paramDef.type) {
        case models_1.ValidationParamType.String: {
            if (typeof value !== "string") {
                throw new models_1.ValidationError("Parameter ".concat(param, " is not a string"));
            }
            break;
        }
        case models_1.ValidationParamType.Number: {
            if (typeof value === "string") {
                if (!options.allowStringToNumberConversion || isNaN(Number(value))) {
                    throw new models_1.ValidationError("Parameter ".concat(param, " is not a number"));
                }
            }
            else if (typeof value === "number") {
                if (isNaN(value)) {
                    throw new models_1.ValidationError("Parameter ".concat(param, " is not a number"));
                }
            }
            else {
                throw new models_1.ValidationError("Parameter ".concat(param, " is not a number"));
            }
            break;
        }
        case models_1.ValidationParamType.Email: {
            var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (typeof value !== "string" || !value.match(emailRegex)) {
                throw new models_1.ValidationError("Parameter ".concat(param, " is not a valid email"));
            }
            break;
        }
        case models_1.ValidationParamType.Object: {
            if (typeof value !== "object" || value === null || Array.isArray(value)) {
                throw new models_1.ValidationError("Parameter ".concat(param, " is not an object"));
            }
            validate(value, paramDef.fields, options);
            break;
        }
        case models_1.ValidationParamType.Array: {
            if (!Array.isArray(value)) {
                throw new models_1.ValidationError("Parameter ".concat(param, " is not an array"));
            }
            var i = 0;
            try {
                for (var value_1 = __values(value), value_1_1 = value_1.next(); !value_1_1.done; value_1_1 = value_1.next()) {
                    var item = value_1_1.value;
                    validateType(paramDef.items, "".concat(param, "[").concat(i, "]"), item, options);
                    i += 1;
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (value_1_1 && !value_1_1.done && (_a = value_1.return)) _a.call(value_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
            break;
        }
    }
}
/**
 * Check all constraints for a given parameter is adhered. Otherwise throws ValidationError
 */
function validateConstraints(paramDef, param, value) {
    var e_3, _a;
    var constraints = "constraints" in paramDef ? paramDef.constraints : [];
    try {
        for (var constraints_1 = __values(constraints), constraints_1_1 = constraints_1.next(); !constraints_1_1.done; constraints_1_1 = constraints_1.next()) {
            var constraint = constraints_1_1.value;
            switch (constraint.type) {
                case models_1.ValidationParamConstraintType.IsLength: {
                    var data = constraint.data;
                    if (data.length !== undefined &&
                        typeof value === "string" &&
                        value.length !== data.length) {
                        throw new models_1.ValidationError("Parameter ".concat(param, " is not of length ").concat(data.length));
                    }
                    if (data.max !== undefined &&
                        typeof value === "string" &&
                        value.length > data.max) {
                        throw new models_1.ValidationError("Parameter ".concat(param, " is greater than max length ").concat(data.max));
                    }
                    if (data.min !== undefined &&
                        typeof value === "string" &&
                        value.length < data.min) {
                        if (value.length === 0) {
                            throw new models_1.ValidationError("Parameter ".concat(param, " cannot be empty"));
                        }
                        else {
                            throw new models_1.ValidationError("Parameter ".concat(param, " is less than min length ").concat(data.min));
                        }
                    }
                    break;
                }
                case models_1.ValidationParamConstraintType.IsOptional: {
                    // Do nothing since already checked earlier
                    break;
                }
                case models_1.ValidationParamConstraintType.Matches: {
                    var regex = constraint.data;
                    if (typeof value !== "string") {
                        throw new models_1.ValidationError("Parameter ".concat(param, " must be a string to match defined regex"));
                    }
                    if (!regex.test(value)) {
                        throw new models_1.ValidationError("Parameter ".concat(param, " does not match defined regex"));
                    }
                    break;
                }
                case models_1.ValidationParamConstraintType.IsIn: {
                    var values = constraint.data;
                    if (!values.includes(value)) {
                        // If optional then ignore
                        if (value === undefined &&
                            constraints.some(function (c) { return c.type === models_1.ValidationParamConstraintType.IsOptional; })) {
                            break;
                        }
                        throw new models_1.ValidationError("Parameter ".concat(param, " is not a valid value. Accepted values are: ").concat(values
                            .map(function (v) { return String(v); })
                            .join(",")));
                    }
                    break;
                }
                case models_1.ValidationParamConstraintType.IsGreaterEqualThan: {
                    var num = constraint.data;
                    if (typeof value !== "number") {
                        throw new models_1.ValidationError("Parameter ".concat(param, " must be a number to be greater equal than"));
                    }
                    if (value < num) {
                        throw new models_1.ValidationError("Parameter ".concat(param, " is less than ").concat(num));
                    }
                    break;
                }
                case models_1.ValidationParamConstraintType.IsLessEqualThan: {
                    var num = constraint.data;
                    if (typeof value !== "number") {
                        throw new models_1.ValidationError("Parameter ".concat(param, " must be a number to be less equal than"));
                    }
                    if (value > num) {
                        throw new models_1.ValidationError("Parameter ".concat(param, " is greater than ").concat(num));
                    }
                    break;
                }
            }
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (constraints_1_1 && !constraints_1_1.done && (_a = constraints_1.return)) _a.call(constraints_1);
        }
        finally { if (e_3) throw e_3.error; }
    }
}
/**
 * Validates an object given a definition. If it doesn't throws an error
 */
function validateObject(obj, paramDefinitions, options) {
    if (options === void 0) { options = {}; }
    validate(obj, paramDefinitions, options);
    return obj;
}
exports.validateObject = validateObject;
