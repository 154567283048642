var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAPI, useAuth, useErrorToast } from "realmsbase-sdk";
import { useState } from "react";
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Input, Text, } from "@chakra-ui/react";
import { IoMdRefresh } from "react-icons/io";
export var CollectionsList = function () {
    var sessionToken = useAuth({ redirectIfLoggedOut: true }).sessionToken;
    var _a = useState(""), searchTerm = _a[0], setSearchTerm = _a[1];
    var _b = useAPI("collections", "get", {
        autoFetch: true,
        auth: sessionToken,
    }), data = _b.data, fetch = _b.fetch, error = _b.error;
    useErrorToast(error);
    return (_jsxs(Box, __assign({ display: "flex", flexDirection: "column" }, { children: [_jsxs(Box, __assign({ display: "flex", padding: 4 }, { children: [_jsx(Input, { placeholder: "Search Collection...", value: searchTerm, onChange: function (e) { return setSearchTerm(e.target.value); }, marginRight: 4 }), _jsx(Button, __assign({ variant: "solid", onClick: function () { return fetch({}, sessionToken); } }, { children: _jsx(IoMdRefresh, {}) }))] })), _jsx(Box, __assign({ width: "100%" }, { children: data
                    ? Object.entries(data)
                        .filter(function (_a) {
                        var name = _a[0];
                        return name.includes(searchTerm);
                    })
                        .map(function (_a) {
                        var name = _a[0], fields = _a[1];
                        return (_jsx(Accordion, __assign({ width: "100%", allowToggle: true }, { children: _jsxs(AccordionItem, { children: [_jsxs(AccordionButton, { children: [_jsx(Box, __assign({ as: "span", flex: "1" }, { children: name })), _jsx(AccordionIcon, {})] }), _jsx(AccordionPanel, { children: fields.map(function (field) { return (_jsx(Text, { children: field }, field)); }) })] }) }), name));
                    })
                    : null }))] })));
};
