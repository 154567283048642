import {
  CharacterBeardType,
  CharacterHairBackType,
  CharacterHairBaseType,
  CharacterHairFringeType,
  CharacterHairSideType,
  CharacterHairTailType,
  CharacterMoustacheType,
} from "mmo-common";

const CHARACTER_SCALE = 0.01;

export const ModelAssetMap = {
  characterFemale: {
    fileName: "/assets/base_character/female.fbx",
    scale: CHARACTER_SCALE,
  },
  [CharacterBeardType.Small]: {
    fileName: "/assets/base_character/beard/beard_small.fbx",
    unwrap: true,
  },
  [CharacterHairBackType.Short]: {
    fileName: "/assets/base_character/hair_back/hair_back_short.fbx",
    unwrap: true,
  },
  [CharacterHairBackType.Tied]: {
    fileName: "/assets/base_character/hair_back/hair_back_tied.fbx",
    unwrap: true,
  },
  [CharacterHairBaseType.Basic]: {
    fileName: "/assets/base_character/hair_base/hair_base_basic.fbx",
    unwrap: true,
  },
  [CharacterHairBaseType.Spiky]: {
    fileName: "/assets/base_character/hair_base/hair_base_spiky.fbx",
    unwrap: true,
  },
  [CharacterHairBaseType.Braided]: {
    fileName: "/assets/base_character/hair_base/hair_base_braided.fbx",
    unwrap: true,
  },
  [CharacterHairFringeType.Side]: {
    fileName: "/assets/base_character/hair_fringe/hair_fringe_side.fbx",
    unwrap: true,
  },
  [CharacterHairFringeType.CombedUp]: {
    fileName: "/assets/base_character/hair_fringe/hair_fringe_combed_up.fbx",
    unwrap: true,
  },
  [CharacterHairFringeType.MidPart]: {
    fileName: "/assets/base_character/hair_fringe/hair_fringe_mid_part.fbx",
    unwrap: true,
  },
  [CharacterHairFringeType.SideLong]: {
    fileName: "/assets/base_character/hair_fringe/hair_fringe_side_long.fbx",
    unwrap: true,
  },
  [CharacterHairFringeType.Straight]: {
    fileName: "/assets/base_character/hair_fringe/hair_fringe_straight.fbx",
    unwrap: true,
  },
  [CharacterHairSideType.Short]: {
    fileName: "/assets/base_character/hair_side/hair_side_short.fbx",
  },
  [CharacterHairTailType.Pony]: {
    fileName: "/assets/base_character/hair_tail/hair_tail_pony.fbx",
    unwrap: true,
  },
  [CharacterHairTailType.Spiky]: {
    fileName: "/assets/base_character/hair_tail/hair_tail_spiky.fbx",
    unwrap: true,
  },
  [CharacterHairTailType.Twin]: {
    fileName: "/assets/base_character/hair_tail/hair_tail_twin.fbx",
    unwrap: true,
  },
  [CharacterMoustacheType.Small]: {
    fileName: "/assets/base_character/moustache/moustache_small.fbx",
    unwrap: true,
  },
  characterMale: {
    fileName: "/assets/base_character/male.fbx",
    scale: CHARACTER_SCALE,
  },
  goblin: {
    fileName: "/assets/creatures/goblin.fbx",
    scale: CHARACTER_SCALE,
  },
  sword: {
    fileName: "/assets/weapons/sword.fbx",
    unwrap: true,
  },
} as const;
