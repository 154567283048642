import { Dialogue } from "mmo-common";
import { BehaviorSubject, Observable, map } from "rxjs";
import { requestPointerLock } from "../../utils/requestPointerLock";
import { ScreenSize, ScreenSizeListener } from "realms-react-utils";

export interface DialogueConfig {
  dialogue: Dialogue;
  speakerName: string;
}

export class DialogueService {
  private screenSizeListener = new ScreenSizeListener();
  private dialogueConfigSubject$ = new BehaviorSubject<null | DialogueConfig>(
    null
  );

  readonly dialogueConfig$: Observable<DialogueConfig | null> =
    this.dialogueConfigSubject$.asObservable();
  readonly inDialogue$: Observable<boolean> = this.dialogueConfig$.pipe(
    map((dialogue) => dialogue !== null)
  );

  /**
   * Starts a dialogue
   */
  startDialogue(config: DialogueConfig) {
    this.dialogueConfigSubject$.next(config);
    if (this.screenSizeListener.getScreenSize() === ScreenSize.Desktop) {
      window.document.exitPointerLock();
    }
  }

  /**
   * Ends dialogue
   */
  endDialogue() {
    this.dialogueConfigSubject$.next(null);
    if (this.screenSizeListener.getScreenSize() === ScreenSize.Desktop) {
      requestPointerLock();
    }
  }
}
