import { Box, IconButton } from "@chakra-ui/react";
import { MMONetSchema } from "mmo-common";
import { TouchEventHandler, useCallback, useRef, useState } from "react";
import {
  Vector2,
  vector2Angle,
  vector2Equal,
  vector2IsZero,
  vector2Normalize,
  vector2Rotate,
  vector2Subtract,
} from "realms-engine";
import { TrackingCameraSystem } from "realms-engine-browser";
import { NetworkSlaveSystem } from "realms-engine-network-client";
import { Joystick } from "../../components/Joystick";
import { useClosestTouch } from "../../hooks/useClosestTouch";
import { GameControllerProps } from "./GameController";
import { useWindowSize } from "realms-react-utils";
import { LuSwords } from "react-icons/lu";
import { GiWalkingBoot } from "react-icons/gi";
import { useService } from "../../services";

export function MobileController({ realm }: GameControllerProps) {
  const { height } = useWindowSize();
  const lastMoveVectorRef = useRef<Vector2>({ x: 0, y: 0 });
  const lastTouchVectorRef = useRef<Vector2 | null>(null);
  const scrollAreaRef = useRef<HTMLDivElement | null>(null);
  const { getClosestTouch } = useClosestTouch(scrollAreaRef.current);
  const [currentTouchId, setCurrentTouchId] = useState<number | null>();
  const dialogueService = useService("dialogue");

  const onPositionChange = (position: Vector2) => {
    const moveVector: Vector2 = vector2IsZero(position)
      ? position
      : vector2Rotate(vector2Normalize(position), -Math.PI / 2);
    if (!vector2Equal(moveVector, lastMoveVectorRef.current)) {
      lastMoveVectorRef.current = moveVector;

      const cameraSystem = realm.systems.get(TrackingCameraSystem);

      const move = vector2IsZero(moveVector)
        ? moveVector
        : vector2Rotate(cameraSystem.getHVector(), vector2Angle(moveVector));

      dialogueService.endDialogue();
      realm.systems
        .get(NetworkSlaveSystem<MMONetSchema>)
        .getClient()
        .getConnection()
        .emit("input", { move });
    }
  };

  const onTouchStart: TouchEventHandler<HTMLDivElement> = (e) => {
    if (currentTouchId !== null) {
      return;
    }
    const closestTouch = getClosestTouch(e.touches);
    if (!closestTouch) {
      return;
    }
    lastTouchVectorRef.current = {
      x: closestTouch.clientX,
      y: closestTouch.clientY,
    };
    setCurrentTouchId(closestTouch.identifier);
  };

  const onTouchEnd: TouchEventHandler<HTMLDivElement> = (e) => {
    lastTouchVectorRef.current = null;
    // Remove current touch
    if (
      setCurrentTouchId !== null &&
      !Array.from(e.touches).find(
        (touch) => touch.identifier === currentTouchId
      )
    ) {
      setCurrentTouchId(null);
    }
  };

  const onTouchMove: TouchEventHandler<HTMLDivElement> = (e) => {
    const touch = Array.from(e.touches).find(
      (touch) => touch.identifier === currentTouchId
    );
    if (!touch) {
      return;
    }
    const currTouch = { x: touch.clientX, y: touch.clientY };
    if (lastTouchVectorRef.current !== null) {
      const vectorDiff = vector2Subtract(currTouch, lastTouchVectorRef.current);
      const cameraSystem = realm.systems.get(TrackingCameraSystem);
      const hSensitivity = 0.005;
      const vSensitivity = 0.0005;
      cameraSystem.rotateHAngle(vectorDiff.x * hSensitivity);
      cameraSystem.rotateVAngle(vectorDiff.y * vSensitivity);
    }
    lastTouchVectorRef.current = currTouch;
  };

  const onAttack = useCallback(() => {
    dialogueService.endDialogue();
    realm.systems
      .get(NetworkSlaveSystem<MMONetSchema>)
      .getClient()
      .getConnection()
      .emit("input", { attack: true });
  }, [dialogueService, realm.systems]);

  const onJump = useCallback(() => {
    dialogueService.endDialogue();
    realm.systems
      .get(NetworkSlaveSystem<MMONetSchema>)
      .getClient()
      .getConnection()
      .emit("input", { jump: true });
  }, [dialogueService, realm.systems]);

  return (
    <Box position={"fixed"} width="100%" height={"100%"} zIndex={3}>
      <Box
        width="50%"
        height={"100%"}
        marginLeft="50%"
        ref={scrollAreaRef}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onTouchMove={onTouchMove}
      />
      <Box position="absolute" bottom={4} left={4}>
        <Joystick onPositionChange={onPositionChange} size={height * 0.3} />
      </Box>
      <Box
        position={"absolute"}
        bottom={8}
        right={16}
        display={"flex"}
        flexDir={"column"}
      >
        <IconButton
          aria-label="attack"
          icon={<LuSwords size={30} />}
          padding={8}
          isRound={true}
          onClick={onAttack}
          marginBottom={2}
        />
        <IconButton
          aria-label="jump"
          icon={<GiWalkingBoot size={30} />}
          padding={8}
          isRound={true}
          onClick={onJump}
        />
      </Box>
    </Box>
  );
}
