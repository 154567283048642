export const BlueSlashEffectsAssetMap = {
  slashEffectBlueArcLarge_frame_1: {
    fileName: "/assets/effects/slash_effects/blue/1/Alternative_2_01.png",
  },
  slashEffectBlueArcLarge_frame_2: {
    fileName: "/assets/effects/slash_effects/blue/1/Alternative_2_02.png",
  },
  slashEffectBlueArcLarge_frame_3: {
    fileName: "/assets/effects/slash_effects/blue/1/Alternative_2_03.png",
  },
  slashEffectBlueArcLarge_frame_4: {
    fileName: "/assets/effects/slash_effects/blue/1/Alternative_2_04.png",
  },
  slashEffectBlueArcLarge_frame_5: {
    fileName: "/assets/effects/slash_effects/blue/1/Alternative_2_05.png",
  },
  slashEffectBlueArcLarge_frame_6: {
    fileName: "/assets/effects/slash_effects/blue/1/Alternative_2_06.png",
  },
  slashEffectBlueArcMedium_frame_1: {
    fileName: "/assets/effects/slash_effects/blue/2/Alternative_2_07.png",
  },
  slashEffectBlueArcMedium_frame_2: {
    fileName: "/assets/effects/slash_effects/blue/2/Alternative_2_08.png",
  },
  slashEffectBlueArcMedium_frame_3: {
    fileName: "/assets/effects/slash_effects/blue/2/Alternative_2_09.png",
  },
  slashEffectBlueArcMedium_frame_4: {
    fileName: "/assets/effects/slash_effects/blue/2/Alternative_2_10.png",
  },
  slashEffectBlueArcMedium_frame_5: {
    fileName: "/assets/effects/slash_effects/blue/2/Alternative_2_11.png",
  },
  slashEffectBlueArcMedium_frame_6: {
    fileName: "/assets/effects/slash_effects/blue/2/Alternative_2_12.png",
  },
  slashEffectBlueArcSmall_frame_1: {
    fileName: "/assets/effects/slash_effects/blue/3/Alternative_2_13.png",
  },
  slashEffectBlueArcSmall_frame_2: {
    fileName: "/assets/effects/slash_effects/blue/3/Alternative_2_14.png",
  },
  slashEffectBlueArcSmall_frame_3: {
    fileName: "/assets/effects/slash_effects/blue/3/Alternative_2_15.png",
  },
  slashEffectBlueArcSmall_frame_4: {
    fileName: "/assets/effects/slash_effects/blue/3/Alternative_2_16.png",
  },
  slashEffectBlueArcSmall_frame_5: {
    fileName: "/assets/effects/slash_effects/blue/3/Alternative_2_17.png",
  },
  slashEffectBlueArcSmall_frame_6: {
    fileName: "/assets/effects/slash_effects/blue/3/Alternative_2_18.png",
  },
  slashEffectBlueBolt_frame_1: {
    fileName: "/assets/effects/slash_effects/blue/4/Alternative_2_19.png",
  },
  slashEffectBlueBolt_frame_2: {
    fileName: "/assets/effects/slash_effects/blue/4/Alternative_2_20.png",
  },
  slashEffectBlueBolt_frame_3: {
    fileName: "/assets/effects/slash_effects/blue/4/Alternative_2_21.png",
  },
  slashEffectBlueBolt_frame_4: {
    fileName: "/assets/effects/slash_effects/blue/4/Alternative_2_22.png",
  },
  slashEffectBlueBolt_frame_5: {
    fileName: "/assets/effects/slash_effects/blue/4/Alternative_2_23.png",
  },
  slashEffectBlueBolt_frame_6: {
    fileName: "/assets/effects/slash_effects/blue/4/Alternative_2_24.png",
  },
  slashEffectBlueStraight_frame_1: {
    fileName: "/assets/effects/slash_effects/blue/5/Alternative_2_25.png",
  },
  slashEffectBlueStraight_frame_2: {
    fileName: "/assets/effects/slash_effects/blue/5/Alternative_2_26.png",
  },
  slashEffectBlueStraight_frame_3: {
    fileName: "/assets/effects/slash_effects/blue/5/Alternative_2_27.png",
  },
  slashEffectBlueStraight_frame_4: {
    fileName: "/assets/effects/slash_effects/blue/5/Alternative_2_28.png",
  },
  slashEffectBlueStraight_frame_5: {
    fileName: "/assets/effects/slash_effects/blue/5/Alternative_2_29.png",
  },
  slashEffectBlueStraight_frame_6: {
    fileName: "/assets/effects/slash_effects/blue/5/Alternative_2_30.png",
  },
} as const;
