"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FPSMonitor = void 0;
var buffer_list_1 = require("./buffer-list");
var events_1 = require("./events");
var FPSMonitor = /** @class */ (function () {
    function FPSMonitor(bufferSize) {
        this.eventManager = new events_1.EventManager();
        this.event = this.eventManager.asEventEmitter();
        this.bufferList = new buffer_list_1.BufferList(bufferSize);
    }
    /**
     * Add an fps value
     */
    FPSMonitor.prototype.addFPS = function (fps) {
        this.bufferList.push(fps);
        this.eventManager.dispatch("fpsChange", this.getAverageFPS());
    };
    /**
     * Gets the average fps
     */
    FPSMonitor.prototype.getAverageFPS = function () {
        var values = this.bufferList.getItems();
        if (values.length === 0) {
            return 0;
        }
        var sum = values.reduce(function (a, b) { return a + b; }, 0);
        return sum / values.length;
    };
    return FPSMonitor;
}());
exports.FPSMonitor = FPSMonitor;
