"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MMODivider = exports.MMODividerVariant = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@chakra-ui/react");
var react_2 = require("react");
var bs_1 = require("react-icons/bs");
var fa6_1 = require("react-icons/fa6");
var gi_1 = require("react-icons/gi");
var realms_utils_1 = require("realms-utils");
var MMODividerVariant;
(function (MMODividerVariant) {
    MMODividerVariant["Winged"] = "winged";
    MMODividerVariant["Centered"] = "centered";
})(MMODividerVariant || (exports.MMODividerVariant = MMODividerVariant = {}));
var MMODivider = function (_a) {
    var _b = _a.color, color = _b === void 0 ? "yellow.500" : _b, _c = _a.variant, variant = _c === void 0 ? MMODividerVariant.Winged : _c, children = _a.children, props = __rest(_a, ["color", "variant", "children"]);
    var _d = __read((0, react_1.useToken)("colors", [color]), 1), hexColor = _d[0];
    var rgb = (0, react_2.useMemo)(function () { return (0, realms_utils_1.hexToRgb)(hexColor); }, [color]);
    switch (variant) {
        case MMODividerVariant.Winged: {
            return ((0, jsx_runtime_1.jsxs)(react_1.Box, __assign({ width: "100%", display: "flex", alignItems: "center", color: color }, props, { children: [(0, jsx_runtime_1.jsxs)(react_1.Box, { display: "flex", alignItems: "center", minWidth: 8, children: [(0, jsx_runtime_1.jsx)(fa6_1.FaCircle, { size: 10 }), (0, jsx_runtime_1.jsx)(bs_1.BsXDiamondFill, { size: 20 })] }), (0, jsx_runtime_1.jsx)(react_1.Divider, { borderColor: color, borderWidth: 1 }), children ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_1.Box, { display: "flex", alignItems: "center", minWidth: 8, justifyContent: "flex-start", position: "relative", right: 2, children: (0, jsx_runtime_1.jsx)(gi_1.GiCurledLeaf, { size: 40 }) }), (0, jsx_runtime_1.jsx)(react_1.Text, { textAlign: "center", fontSize: "large", fontFamily: "\"Times New Roman\", Times, serif", width: "50%", children: children }), (0, jsx_runtime_1.jsx)(react_1.Box, { display: "flex", alignItems: "center", minWidth: 8, justifyContent: "flex-start", position: "relative", left: 2, transform: "scaleX(-1)", children: (0, jsx_runtime_1.jsx)(gi_1.GiCurledLeaf, { size: 40 }) }), (0, jsx_runtime_1.jsx)(react_1.Divider, { borderColor: color, borderWidth: 1 })] })) : null, (0, jsx_runtime_1.jsxs)(react_1.Box, { display: "flex", alignItems: "center", minWidth: 8, children: [(0, jsx_runtime_1.jsx)(bs_1.BsXDiamondFill, { size: 20 }), (0, jsx_runtime_1.jsx)(fa6_1.FaCircle, { size: 10 })] })] })));
        }
        case MMODividerVariant.Centered: {
            return ((0, jsx_runtime_1.jsxs)(react_1.Box, __assign({ width: "100%", display: "flex", alignItems: "center", color: color }, props, { children: [(0, jsx_runtime_1.jsx)(react_1.Box, { width: "100%", height: 1, background: "linear-gradient(90deg, rgba(".concat(rgb.r, ",").concat(rgb.g, ", ").concat(rgb.b, ",0) 0%, rgba(").concat(rgb.r, ",").concat(rgb.g, ",").concat(rgb.b, ",1) 100%)") }), children, (0, jsx_runtime_1.jsx)(react_1.Box, { width: "100%", height: 1, background: "linear-gradient(90deg, rgba(".concat(rgb.r, ",").concat(rgb.g, ", ").concat(rgb.b, ",1) 0%, rgba(").concat(rgb.r, ",").concat(rgb.g, ",").concat(rgb.b, ",0) 100%)") })] })));
        }
    }
    return null;
};
exports.MMODivider = MMODivider;
