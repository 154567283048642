import { useWindowSize } from "realms-react-utils";
import { Box, BoxProps } from "@chakra-ui/react";

interface GameScreenProps extends BoxProps {
  children: any;
}

// Full screen landscape
export const GameScreen = ({ children, ...props }: GameScreenProps) => {
  const { width, height } = useWindowSize();

  return (
    <Box position={"fixed"} width={width} height={height} {...props}>
      {children}
    </Box>
  );
};
