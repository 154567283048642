"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isEventEmitter = exports.eventEmitterSchema = void 0;
var isInterface_1 = require("../isInterface");
exports.eventEmitterSchema = {
    addListener: "function",
    removeListener: "function",
    once: "function",
};
exports.isEventEmitter = (0, isInterface_1.isInterface)(exports.eventEmitterSchema);
