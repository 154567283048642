"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lerpVector3 = exports.lerpVector2 = exports.lerp = exports.fastNegExp = exports.exactDamp = exports.exactDampVector3 = exports.exactDampQuat = exports.SmoothDamp = exports.SmoothDampVector3 = void 0;
var quat_1 = require("./quat");
var SmoothDampVector3 = /** @class */ (function () {
    function SmoothDampVector3(getCurrent, maxSpeed) {
        this.x = new SmoothDamp(function () { return getCurrent().x; }, maxSpeed);
        this.y = new SmoothDamp(function () { return getCurrent().y; }, maxSpeed);
        this.z = new SmoothDamp(function () { return getCurrent().z; }, maxSpeed);
    }
    SmoothDampVector3.prototype.damp = function (target, smoothTime, delta) {
        return {
            x: this.x.damp(target.x, smoothTime, delta),
            y: this.y.damp(target.y, smoothTime, delta),
            z: this.z.damp(target.z, smoothTime, delta),
        };
    };
    return SmoothDampVector3;
}());
exports.SmoothDampVector3 = SmoothDampVector3;
/*
 * Implements Unity's SmoothDamp code from here:
 * https://www.alexisbacot.com/blog/the-art-of-damping
 */
var SmoothDamp = /** @class */ (function () {
    function SmoothDamp(getCurrent, maxSpeed) {
        this.getCurrent = getCurrent;
        this.maxSpeed = maxSpeed;
        this.currentVelocity = 0;
    }
    SmoothDamp.prototype.damp = function (target, smoothTime, delta) {
        // Make sure smooth time is non zero
        smoothTime = Math.max(smoothTime, 0.0001);
        var omega = 2 / smoothTime;
        var x = omega * delta;
        var exp = fastNegExp(x);
        var current = this.getCurrent();
        var originalTo = target;
        // Clamp maximum speed
        var maxChange = this.maxSpeed * smoothTime;
        var change = Math.max(-maxChange, Math.min(current - target, maxChange));
        var newTarget = current - change;
        var temp = (this.currentVelocity + omega * change) * delta;
        this.currentVelocity = (this.currentVelocity - omega * temp) * exp;
        var output = newTarget + (change + temp) * exp;
        // Prevent overshooting
        if (originalTo - current > 0 === output > originalTo) {
            output = originalTo;
            this.currentVelocity = (output - originalTo) / delta;
        }
        return output;
    };
    return SmoothDamp;
}());
exports.SmoothDamp = SmoothDamp;
function exactDampQuat(current, target, halflife, delta) {
    // Minimum step to prevent never reaching target
    var INTERPOLATION_EPSILON = 0.001;
    // If close enough just return target
    if ((0, quat_1.quatDistanceTo)(current, target) < INTERPOLATION_EPSILON) {
        return target;
    }
    var ln2 = 0.69314718056;
    var quatS = (0, quat_1.quatSlerp)(current, target, 1 - Math.max(fastNegExp((ln2 * delta) / (halflife + INTERPOLATION_EPSILON))));
    return quatS;
}
exports.exactDampQuat = exactDampQuat;
function exactDampVector3(current, target, halflife, delta) {
    return {
        x: exactDamp(current.x, target.x, halflife, delta),
        y: exactDamp(current.y, target.y, halflife, delta),
        z: exactDamp(current.z, target.z, halflife, delta),
    };
}
exports.exactDampVector3 = exactDampVector3;
/*
 * Moves towards a target but damps it
 * Based off the implementation of damper_exact here:
 * https://theorangeduck.com/page/spring-roll-call
 */
function exactDamp(current, target, halflife, delta) {
    var ln2 = 0.69314718056;
    // Minimum step to prevent never reaching target
    var INTERPOLATION_EPSILON = 0.00001;
    return lerp(current, target, 1 - Math.max(fastNegExp((ln2 * delta) / halflife), INTERPOLATION_EPSILON));
}
exports.exactDamp = exactDamp;
/*
 * Efficiently approximates the negative exponential of the given number
 * ie: e^-n
 * Base off the implementation here:
 * https://theorangeduck.com/page/spring-roll-call
 */
function fastNegExp(n) {
    return 1 / (1 + n + 0.48 * n * n + 0.235 * n * n * n);
}
exports.fastNegExp = fastNegExp;
/*
 * Lerps between two values. Alpha is a number from 0 to 1
 */
function lerp(start, end, alpha) {
    return (end - start) * alpha + start;
}
exports.lerp = lerp;
function lerpVector2(start, end, alpha) {
    return {
        x: lerp(start.x, end.x, alpha),
        y: lerp(start.y, end.y, alpha),
    };
}
exports.lerpVector2 = lerpVector2;
function lerpVector3(start, end, alpha) {
    return {
        x: lerp(start.x, end.x, alpha),
        y: lerp(start.y, end.y, alpha),
        z: lerp(start.z, end.z, alpha),
    };
}
exports.lerpVector3 = lerpVector3;
