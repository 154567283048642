import {
  CharacterConfig,
  CreatureAnimation,
  CreatureAnimationData,
} from "mmo-common";
import { Quat } from "realms-engine";
import {
  Render3DGameObject,
  threeToQuat,
  toThreeQuaternion,
} from "realms-engine-browser";
import { CharacterObject } from "./characterObject";
import THREE from "realms-three";
import { MMOAssetManager } from "../../config";
import { ThreeAnimationGameObject } from "../utils";

/*
 * Manages the character state
 */
export class RenderCharacterGameObject extends Render3DGameObject {
  private characterObject: CharacterObject;
  private animationGameObject: ThreeAnimationGameObject;

  constructor(
    private character: CharacterConfig,
    private animationData: CreatureAnimationData
  ) {
    const characterObject = new CharacterObject({
      ...character,
    });
    super(characterObject.getMesh());
    this.characterObject = characterObject;
    this.characterObject.addListener("onMeshChange", (obj) => {
      this.setThreeObject(obj.getMesh());
    });

    // Animates the character
    this.animationGameObject = this.addChildWithName(
      "characterAnimation",
      new ThreeAnimationGameObject({
        [CreatureAnimation.Idle]: {
          clip: MMOAssetManager.getObject3D("baseCharacterIdleAnimation", {
            clone: true,
          }).animations[0],
        },
        [CreatureAnimation.Walk]: {
          clip: MMOAssetManager.getObject3D("baseCharacterWalkAnimation", {
            clone: true,
          }).animations[0],
          timeScale: 0.9,
        },
        [CreatureAnimation.Jump]: {
          clip: MMOAssetManager.getObject3D("baseCharacterJumpAnimation", {
            clone: true,
          }).animations[0],
        },
        [CreatureAnimation.Death]: {
          clip: MMOAssetManager.getObject3D("baseCharacterDeathAnimation", {
            clone: true,
          }).animations[0],
          clampWhenFinished: true,
        },
        [CreatureAnimation.NormalAttack1]: {
          clip: MMOAssetManager.getObject3D("horizontalSlashAnimation", {
            clone: true,
          }).animations[0],
          clampWhenFinished: true,
        },
        [CreatureAnimation.NormalAttack2]: {
          clip: MMOAssetManager.getObject3D("slash360Animation", {
            clone: true,
          }).animations[0],
          clampWhenFinished: true,
        },
        [CreatureAnimation.NormalAttack3]: {
          clip: MMOAssetManager.getObject3D("verticalSlashAnimation")
            .animations[0],
          clampWhenFinished: true,
        },
      })
    );
  }

  getCharacterBox() {
    return this.characterObject.getCharacterBox();
  }

  getCharacter() {
    return this.character;
  }

  setCharacter(character: CharacterConfig) {
    this.character = character;
    this.characterObject.setCharacterConfig({
      ...character,
    });
  }

  getAnimationData() {
    return this.animationData;
  }

  setAnimationData(data: CreatureAnimationData) {
    this.animationGameObject.play(data.name, {
      duration: data.duration,
    });
    this.animationData = data;
  }

  getQuaternion() {
    return threeToQuat(
      toThreeQuaternion(super.getQuaternion()).multiply(
        new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 1, 0),
          (-3 * Math.PI) / 2
        )
      )
    );
  }

  setQuaternion(q: Quat) {
    // We have to rotate because the character is in the wrong rotation
    super.setQuaternion(
      toThreeQuaternion(q).multiply(
        new THREE.Quaternion().setFromAxisAngle(
          new THREE.Vector3(0, 1, 0),
          (3 * Math.PI) / 2
        )
      )
    );
  }
}
