"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BufferList = void 0;
/**
 * Stores a list of items of fixed size. The oldest is pushed off when its full
 */
var BufferList = /** @class */ (function () {
    function BufferList(maxSize) {
        this.maxSize = maxSize;
        this.items = [];
    }
    /**
     * Pushes item on to buffer list
     */
    BufferList.prototype.push = function (item) {
        this.items.push(item);
        if (this.items.length > this.maxSize) {
            this.items.shift();
        }
    };
    /**
     * Get all items in array
     */
    BufferList.prototype.getItems = function () {
        return __spreadArray([], __read(this.items), false);
    };
    return BufferList;
}());
exports.BufferList = BufferList;
