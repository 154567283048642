"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWindowSize = void 0;
var react_1 = require("react");
var react_rxjs_toolkit_1 = require("react-rxjs-toolkit");
var utils_1 = require("../utils");
var useWindowSize = function () {
    var windowSizeRef = (0, react_1.useRef)(new utils_1.WindowSize());
    (0, react_1.useEffect)(function () {
        var windowSize = windowSizeRef.current;
        windowSize.subscribe();
        return function () {
            windowSize.unsubscribe();
        };
    }, []);
    var _a = __read((0, react_rxjs_toolkit_1.useObservable)(windowSizeRef.current.width$, window.innerWidth), 1), width = _a[0];
    var _b = __read((0, react_rxjs_toolkit_1.useObservable)(windowSizeRef.current.height$, window.innerHeight), 1), height = _b[0];
    return {
        width: width,
        height: height,
    };
};
exports.useWindowSize = useWindowSize;
