import { Realm } from "realms-engine";
import { DesktopController } from "./DesktopController";
import { MobileController } from "./MobileController";
import { ScreenSize, useScreenSize } from "realms-react-utils";

// Camera should follow player
// On left click, it should move the camera
// Scrolling left should turn the camera left

export interface GameControllerProps {
  realm: Realm;
}

export function GameController({ realm }: GameControllerProps) {
  const { screenSize } = useScreenSize();
  if (screenSize === ScreenSize.Desktop) {
    return <DesktopController realm={realm} />;
  }
  return <MobileController realm={realm} />;
}
