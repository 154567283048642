import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { createRealmsbaseRoutes } from "./CreateRealmsbaseRoutes";
export * from "./CreateRealmsbaseRoutes";
if (window.RUN_AS_APP) {
    var router = createHashRouter(createRealmsbaseRoutes(""));
    var root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(_jsx(React.StrictMode, { children: _jsx(RouterProvider, { router: router }) }));
}
