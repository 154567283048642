"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_WINDOW_NAME = exports.CameraType = exports.EditorWindowType = void 0;
var EditorWindowType;
(function (EditorWindowType) {
    EditorWindowType["Blueprint"] = "blueprint";
    EditorWindowType["Webview"] = "webview";
    EditorWindowType["Settings"] = "settings";
})(EditorWindowType = exports.EditorWindowType || (exports.EditorWindowType = {}));
var CameraType;
(function (CameraType) {
    CameraType["PerspectiveCamera"] = "perspectiveCamera";
    CameraType["OrthographicCamera"] = "orthographicCamera";
})(CameraType = exports.CameraType || (exports.CameraType = {}));
exports.DEFAULT_WINDOW_NAME = (_a = {},
    _a[EditorWindowType.Settings] = "Settings",
    _a);
