"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFirstDialogueChoiceNode = void 0;
var dialogue_model_1 = require("../dialogue.model");
/**
 * Gets the first dialogue choice node
 */
var getFirstDialogueChoiceNode = function (dialogue) {
    var _a;
    var startNode = dialogue.nodes.find(function (n) { return n.type === dialogue_model_1.DialogueNodeType.Start; });
    if (!startNode) {
        return null;
    }
    var firstEdge = dialogue.edges.find(function (edge) { return edge.source === startNode.id; });
    if (!firstEdge) {
        return null;
    }
    return (_a = dialogue.nodes.find(function (node) { return node.id === firstEdge.target; })) !== null && _a !== void 0 ? _a : null;
};
exports.getFirstDialogueChoiceNode = getFirstDialogueChoiceNode;
