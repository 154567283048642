"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Scene = void 0;
var realms_utils_1 = require("realms-utils");
var Scene = /** @class */ (function () {
    function Scene(gameObjectSystemManager, realm) {
        this.gameObjectSystemManager = gameObjectSystemManager;
        this.realm = realm;
        this.gameObjects = new Map();
        this.eventManager = new realms_utils_1.EventManager();
        this.addListener = this.eventManager.addListener;
        this.removeListener = this.eventManager.removeListener;
        this.once = this.eventManager.once;
    }
    Scene.prototype.add = function (gameObject) {
        var e_1, _a;
        try {
            // Add the gameObject and all sub gameObjects
            for (var _b = __values(gameObject.getGameObjectFamily()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var c = _c.value;
                c._setScene(this);
                this.gameObjects.set(c.id, c);
                this.gameObjectSystemManager.addGameObject(c);
                this.eventManager.dispatch("gameObjectEnter", c);
                c.onInit(this);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    Scene.prototype.get = function (id) {
        var _a;
        return (_a = this.gameObjects.get(id)) !== null && _a !== void 0 ? _a : null;
    };
    Scene.prototype.remove = function (gameObject) {
        var e_2, _a;
        try {
            // Remove this gameObject and all sub gameObjects
            for (var _b = __values(gameObject.getGameObjectFamily()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var c = _c.value;
                // Already no longer in scene so ignore
                if (!c.getScene()) {
                    continue;
                }
                this.gameObjects.delete(c.id);
                this.gameObjectSystemManager.removeGameObject(c);
                this.eventManager.dispatch("gameObjectExit", gameObject);
                c.onRemove(this);
                c._setScene(null);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    /*
     * Get all gameObjects in the scene
     */
    Scene.prototype.getAll = function () {
        return Array.from(this.gameObjects.values());
    };
    return Scene;
}());
exports.Scene = Scene;
