var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useAPI } from "./useAPI";
/**
 * A typed wrapper to extend the useAPI hook with custom types
 */
export var extendUseAPI = function (defaultOptions) {
    if (defaultOptions === void 0) { defaultOptions = {}; }
    var wrapped = function (endpoint, method, options) {
        return useAPI(endpoint, method, __assign(__assign({}, defaultOptions), options));
    };
    return wrapped;
};
