import { MMONetSchema } from "mmo-common";
import { InteractableData } from "mmo-common/src/game/models/interactable.model";
import { GameObject } from "realms-engine";
import { NetworkSlave } from "realms-engine-network-client";

export class SlaveInteractableGameObject
  extends GameObject
  implements NetworkSlave<MMONetSchema["TMap"]["interactable"]>
{
  private state: InteractableData;

  constructor(state: InteractableData) {
    super();
    this.state = state;
  }

  getState(): InteractableData {
    return this.state;
  }

  onSlaveChanges(
    changes: Partial<InteractableData>,
    newState: InteractableData,
    oldState: InteractableData
  ): void {
    this.state = newState;
  }
}
