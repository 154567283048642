import {
  Render3DGameObject,
  Render3DGameObjectEventsMap,
  UISpriteGameObject,
} from "realms-engine-browser";
import { CreatureUI } from "./CreatureUI";
import { html } from "canvas-ui";
import { Scene, TweenGameObject } from "realms-engine";
import { RenderCharacterGameObject } from "../player";
import { ChatMessage } from "mmo-common";

interface CreatureUIGameObjectEventsMap extends Render3DGameObjectEventsMap {
  healthChange: number;
  maxHealthChange: number;
  lagHealthChange: number;
  creatureNameChange: string;
  showHealthChange: boolean;
  showNameChange: boolean;
  onChatMessage: ChatMessage;
}

export class CreatureUIGameObject extends UISpriteGameObject<CreatureUIGameObjectEventsMap> {
  private lagHealth: number;
  private lagHealthTween: TweenGameObject<number>;
  private health: number;
  private maxHealth: number;
  private creatureName: string;
  private showHealth: boolean;
  private showName: boolean;

  constructor(options: {
    health: number;
    maxHealth: number;
    name: string;
    showHealth?: boolean;
    showName?: boolean;
  }) {
    const canvasHeight = 350;
    const canvasWidth = 250;
    const scale = 0.05;
    super(
      { creatureUI: CreatureUI },
      {
        canvasHeight,
        canvasWidth,
        scale,
      }
    );
    this.render(html`<creatureUI gameObject=${this} />`);
    this.health = options.health;
    this.maxHealth = options.maxHealth;
    this.lagHealth = this.health;
    this.showHealth =
      options.showHealth !== undefined ? options.showHealth : true;
    this.showName = options.showName !== undefined ? options.showName : true;
    this.lagHealthTween = this.addChild(
      new TweenGameObject(
        () => this.lagHealth,
        (v) => this.setLagHealth(v)
      )
    );
    this.creatureName = options.name;
  }

  /**
   * Shows a chat message on the UI
   */
  showChatMessage(chatMessage: ChatMessage) {
    this.dispatch("onChatMessage", chatMessage);
  }

  getShowName() {
    return this.showName;
  }

  setShowName(v: boolean) {
    this.showName = v;
    this.dispatch("showNameChange", v);
  }

  getShowHealth() {
    return this.showHealth;
  }

  setShowHealth(v: boolean) {
    this.showHealth = v;
    this.dispatch("showHealthChange", v);
  }

  getCreatureName() {
    return this.creatureName;
  }

  setCreatureName(name: string) {
    this.creatureName = name;
    this.dispatch("creatureNameChange", this.creatureName);
  }

  getLagHealth() {
    return this.lagHealth;
  }

  setLagHealth(value: number) {
    if (value !== this.lagHealth) {
      this.dispatch("lagHealthChange", value);
    }
    this.lagHealth = value;
  }

  getHealth() {
    return this.health;
  }

  setHealth(value: number) {
    if (value !== this.health) {
      this.dispatch("healthChange", value);
    }
    // Only lag health if its a decrease
    if (value < this.health) {
      this.lagHealthTween.tween(value, 1000);
    }
    this.health = value;
  }

  getMaxHealth() {
    return this.maxHealth;
  }

  setMaxHealth(value: number) {
    if (value !== this.maxHealth) {
      this.dispatch("maxHealthChange", value);
    }
    this.maxHealth = value;
  }

  override onInit(scene: Scene): void {
    super.onInit(scene);
    const parent = this.getParent();
    let bbox: null | THREE.Box3 = null;
    if (parent instanceof RenderCharacterGameObject) {
      // Set above the parent gameObject
      bbox = parent.getCharacterBox();
    } else if (parent instanceof Render3DGameObject) {
      bbox = parent.getBBox();
    }
    if (bbox) {
      this.setPosition({ x: 0, y: bbox.max.y + 8, z: 0 });
    }
  }
}
