"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagLinkSourceType = exports.DataObjectMaterialWrappingMode = exports.DataObjectMaterialType = exports.DataObjectGeometryType = exports.SkyboxType = exports.PhysicsType = exports.DataObjectType = exports.isRemoteRealmAsset = exports.isLocalRealmAsset = exports.RealmAssetLocationType = void 0;
var RealmAssetLocationType;
(function (RealmAssetLocationType) {
    // Stored locally in the form of a dataurl
    RealmAssetLocationType["Local"] = "local";
    // Stored remotely in the form of the url to the asset
    RealmAssetLocationType["Remote"] = "remote";
})(RealmAssetLocationType = exports.RealmAssetLocationType || (exports.RealmAssetLocationType = {}));
/*
 * Returns whether the asset is local
 */
function isLocalRealmAsset(asset) {
    return asset.location === RealmAssetLocationType.Local;
}
exports.isLocalRealmAsset = isLocalRealmAsset;
/*
 * Returns whether the asset is remote
 */
function isRemoteRealmAsset(asset) {
    return asset.location === RealmAssetLocationType.Remote;
}
exports.isRemoteRealmAsset = isRemoteRealmAsset;
/*
 * The type of a dataobject
 */
var DataObjectType;
(function (DataObjectType) {
    // Contains no visual elements but used to group
    DataObjectType["Group"] = "group";
    // A simple box
    DataObjectType["Box"] = "box";
    // A sphere
    DataObjectType["Sphere"] = "sphere";
    // A cylinder
    DataObjectType["Cylinder"] = "cylinder";
    // References another blueprint
    DataObjectType["BlueprintRef"] = "blueprintRef";
    // Hemisphere Light
    DataObjectType["HemisphereLight"] = "hemisphereLight";
    // Ambient light (illuminates globally)
    DataObjectType["AmbientLight"] = "ambientLight";
    // DirectionalLight (illuminates in a direction)
    DataObjectType["DirectionalLight"] = "directionalLight";
    // A custom plugin data object
    DataObjectType["PluginDataObject"] = "pluginDataObject";
    // Displays a sprite
    DataObjectType["Sprite"] = "sprite";
})(DataObjectType = exports.DataObjectType || (exports.DataObjectType = {}));
/*
 * The type of physics body a shape can have
 */
var PhysicsType;
(function (PhysicsType) {
    // No physics on the given body
    PhysicsType["None"] = "none";
    // The body has physics
    PhysicsType["Body"] = "body";
    // The body has physics but no render
    PhysicsType["Invisible"] = "invisible";
})(PhysicsType = exports.PhysicsType || (exports.PhysicsType = {}));
var SkyboxType;
(function (SkyboxType) {
    SkyboxType["Cube"] = "cube";
    SkyboxType["Color"] = "color";
    SkyboxType["Texture"] = "texture";
})(SkyboxType = exports.SkyboxType || (exports.SkyboxType = {}));
/*
 * Is a geometry
 */
var DataObjectGeometryType;
(function (DataObjectGeometryType) {
    DataObjectGeometryType["Box"] = "box";
    DataObjectGeometryType["Sphere"] = "sphere";
    DataObjectGeometryType["Cylinder"] = "cylinder";
})(DataObjectGeometryType = exports.DataObjectGeometryType || (exports.DataObjectGeometryType = {}));
var DataObjectMaterialType;
(function (DataObjectMaterialType) {
    DataObjectMaterialType["MeshPhongMaterial"] = "meshPhongMaterial";
    DataObjectMaterialType["MeshToonMaterial"] = "meshToonMaterial";
})(DataObjectMaterialType = exports.DataObjectMaterialType || (exports.DataObjectMaterialType = {}));
var DataObjectMaterialWrappingMode;
(function (DataObjectMaterialWrappingMode) {
    DataObjectMaterialWrappingMode["RepeatWrapping"] = "repeatWrapping";
    DataObjectMaterialWrappingMode["ClampToEdgeWrapping"] = "clampToEdgeWrapping";
    DataObjectMaterialWrappingMode["MirroredRepeatWrapping"] = "mirroredRepeatWrapping";
})(DataObjectMaterialWrappingMode = exports.DataObjectMaterialWrappingMode || (exports.DataObjectMaterialWrappingMode = {}));
/*
 * Types of sources
 */
var TagLinkSourceType;
(function (TagLinkSourceType) {
    TagLinkSourceType["Blueprint"] = "blueprint";
    TagLinkSourceType["RealmAsset"] = "realmAsset";
})(TagLinkSourceType = exports.TagLinkSourceType || (exports.TagLinkSourceType = {}));
