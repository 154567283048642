"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.arrayFromThreeObject = void 0;
/*
 * Converts an object to an array of objects containing itself
 * and all sub objects
 */
function arrayFromThreeObject(object) {
    var arr = [];
    var traverse = function (o) {
        arr.push(o);
        for (var _i = 0, _a = o.children; _i < _a.length; _i++) {
            var child = _a[_i];
            traverse(child);
        }
    };
    traverse(object);
    return arr;
}
exports.arrayFromThreeObject = arrayFromThreeObject;
