import { MMONetSchema } from "mmo-common";
import { useRef, useEffect } from "react";
import {
  Vector2,
  vector2Angle,
  vector2Equal,
  vector2IsZero,
  vector2Rotate,
} from "realms-engine";
import { TrackingCameraSystem } from "realms-engine-browser";
import { NetworkSlaveSystem } from "realms-engine-network-client";
import { useDesktopControls } from "../../hooks/useDesktopControls";
import { GameControllerProps } from "./GameController";
import { useService } from "../../services";
import { PointerLock } from "./PointerLock";
import { usePointerLock } from "../../utils/PointerLockProvider";
import { useObservable } from "react-rxjs-toolkit";

export function DesktopController({ realm }: GameControllerProps) {
  const lastMoveVectorRef = useRef<Vector2>({ x: 0, y: 0 });
  const lastRightMouseDownRef = useRef(false);
  const dialogueService = useService("dialogue");
  const chatService = useService("chat");

  const [chatIsFocused] = useObservable(chatService.chatIsFocused$, false);

  const { isLocked } = usePointerLock();

  const { pressedKeys, leftMouseDown } = useDesktopControls({
    pressedKeys: {},
    leftMouseDown: {},
  });

  useEffect(() => {
    if (isLocked) {
      const mouseMoveListener = (e: MouseEvent) => {
        const cameraSystem = realm.systems.getSafely(TrackingCameraSystem);
        const hSensitivity = 0.005;
        const vSensitivity = 0.0005;
        cameraSystem?.rotateHAngle(e.movementX * hSensitivity);
        cameraSystem?.rotateVAngle(e.movementY * vSensitivity);
      };
      window.addEventListener("mousemove", mouseMoveListener);
      return () => {
        window.removeEventListener("mousemove", mouseMoveListener);
      };
    }
  }, [isLocked, realm.systems]);

  useEffect(() => {
    if (!isLocked) {
      return;
    }

    if (chatIsFocused) {
      return;
    }

    const playerInput: MMONetSchema["IMap"]["input"] = {};

    // Process move
    const moveVector: Vector2 = {
      y: pressedKeys["d"] ? -1 : pressedKeys["a"] ? 1 : 0,
      x: pressedKeys["w"] ? -1 : pressedKeys["s"] ? 1 : 0,
    };
    if (!vector2Equal(moveVector, lastMoveVectorRef.current)) {
      lastMoveVectorRef.current = moveVector;
      // Update move vector to be relative to the camera
      const cameraSystem = realm.systems.get(TrackingCameraSystem);

      const move = vector2IsZero(moveVector)
        ? moveVector
        : vector2Rotate(cameraSystem.getHVector(), vector2Angle(moveVector));

      playerInput["move"] = move;
    }

    // Process jump
    if (pressedKeys[" "]) {
      playerInput["jump"] = true;
    }

    // Process attack
    if (lastRightMouseDownRef.current !== leftMouseDown) {
      lastRightMouseDownRef.current = leftMouseDown;
      if (leftMouseDown) {
        playerInput["attack"] = true;
      }
    }

    // Send player input
    if (Object.keys(playerInput).length !== 0) {
      dialogueService.endDialogue();
      realm.systems
        .get(NetworkSlaveSystem<MMONetSchema>)
        .getClient()
        .getConnection()
        .emit("input", playerInput);
    }
  }, [
    dialogueService,
    isLocked,
    pressedKeys,
    realm.systems,
    leftMouseDown,
    chatIsFocused,
  ]);
  return <PointerLock />;
}
