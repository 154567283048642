export const PurpleSlashEffectsAssetMap = {
  slashEffectPurpleArcLarge_frame_1: {
    fileName: "/assets/effects/slash_effects/purple/1/Alternative_1_01.png",
  },
  slashEffectPurpleArcLarge_frame_2: {
    fileName: "/assets/effects/slash_effects/purple/1/Alternative_1_02.png",
  },
  slashEffectPurpleArcLarge_frame_3: {
    fileName: "/assets/effects/slash_effects/purple/1/Alternative_1_03.png",
  },
  slashEffectPurpleArcLarge_frame_4: {
    fileName: "/assets/effects/slash_effects/purple/1/Alternative_1_04.png",
  },
  slashEffectPurpleArcLarge_frame_5: {
    fileName: "/assets/effects/slash_effects/purple/1/Alternative_1_05.png",
  },
  slashEffectPurpleArcLarge_frame_6: {
    fileName: "/assets/effects/slash_effects/purple/1/Alternative_1_06.png",
  },
  slashEffectPurpleArcMedium_frame_1: {
    fileName: "/assets/effects/slash_effects/purple/2/Alternative_1_07.png",
  },
  slashEffectPurpleArcMedium_frame_2: {
    fileName: "/assets/effects/slash_effects/purple/2/Alternative_1_08.png",
  },
  slashEffectPurpleArcMedium_frame_3: {
    fileName: "/assets/effects/slash_effects/purple/2/Alternative_1_09.png",
  },
  slashEffectPurpleArcMedium_frame_4: {
    fileName: "/assets/effects/slash_effects/purple/2/Alternative_1_10.png",
  },
  slashEffectPurpleArcMedium_frame_5: {
    fileName: "/assets/effects/slash_effects/purple/2/Alternative_1_11.png",
  },
  slashEffectPurpleArcMedium_frame_6: {
    fileName: "/assets/effects/slash_effects/purple/2/Alternative_1_12.png",
  },
  slashEffectPurpleArcSmall_frame_1: {
    fileName: "/assets/effects/slash_effects/purple/3/Alternative_1_13.png",
  },
  slashEffectPurpleArcSmall_frame_2: {
    fileName: "/assets/effects/slash_effects/purple/3/Alternative_1_14.png",
  },
  slashEffectPurpleArcSmall_frame_3: {
    fileName: "/assets/effects/slash_effects/purple/3/Alternative_1_15.png",
  },
  slashEffectPurpleArcSmall_frame_4: {
    fileName: "/assets/effects/slash_effects/purple/3/Alternative_1_16.png",
  },
  slashEffectPurpleArcSmall_frame_5: {
    fileName: "/assets/effects/slash_effects/purple/3/Alternative_1_17.png",
  },
  slashEffectPurpleArcSmall_frame_6: {
    fileName: "/assets/effects/slash_effects/purple/3/Alternative_1_18.png",
  },
  slashEffectPurpleBolt_frame_1: {
    fileName: "/assets/effects/slash_effects/purple/4/Alternative_1_19.png",
  },
  slashEffectPurpleBolt_frame_2: {
    fileName: "/assets/effects/slash_effects/purple/4/Alternative_1_20.png",
  },
  slashEffectPurpleBolt_frame_3: {
    fileName: "/assets/effects/slash_effects/purple/4/Alternative_1_21.png",
  },
  slashEffectPurpleBolt_frame_4: {
    fileName: "/assets/effects/slash_effects/purple/4/Alternative_1_22.png",
  },
  slashEffectPurpleBolt_frame_5: {
    fileName: "/assets/effects/slash_effects/purple/4/Alternative_1_23.png",
  },
  slashEffectPurpleBolt_frame_6: {
    fileName: "/assets/effects/slash_effects/purple/4/Alternative_1_24.png",
  },
  slashEffectPurpleStraight_frame_1: {
    fileName: "/assets/effects/slash_effects/purple/5/Alternative_1_25.png",
  },
  slashEffectPurpleStraight_frame_2: {
    fileName: "/assets/effects/slash_effects/purple/5/Alternative_1_26.png",
  },
  slashEffectPurpleStraight_frame_3: {
    fileName: "/assets/effects/slash_effects/purple/5/Alternative_1_27.png",
  },
  slashEffectPurpleStraight_frame_4: {
    fileName: "/assets/effects/slash_effects/purple/5/Alternative_1_28.png",
  },
  slashEffectPurpleStraight_frame_5: {
    fileName: "/assets/effects/slash_effects/purple/5/Alternative_1_29.png",
  },
  slashEffectPurpleStraight_frame_6: {
    fileName: "/assets/effects/slash_effects/purple/5/Alternative_1_30.png",
  },
} as const;
