import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LOGGED_OUT_DEFAULT_ROUTE, useAuth } from "realmsbase-sdk";
import { LoadingPage } from "../pages/LoadingPage";

interface AdminGuardProps {
  children: any;
}

export const AdminGuard = ({ children }: AdminGuardProps) => {
  const { account } = useAuth({ redirectIfLoggedOut: true });
  const navigate = useNavigate();

  useEffect(() => {
    if (account && !account.isAdmin) {
      navigate(LOGGED_OUT_DEFAULT_ROUTE);
    }
  }, [account, navigate]);

  if (!account || !account.isAdmin) {
    return <LoadingPage />;
  }

  return <>{children}</>;
};
