"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GradientOverlay = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@chakra-ui/react");
var GradientOverlay = function (_a) {
    var opacity = _a.opacity, bgGradient = _a.bgGradient;
    return ((0, jsx_runtime_1.jsx)(react_1.Box, { transition: "opacity 0.25s", position: "absolute", zIndex: 0, opacity: opacity !== null && opacity !== void 0 ? opacity : 1, top: 0, bottom: 0, right: 0, left: 0, bgGradient: bgGradient }));
};
exports.GradientOverlay = GradientOverlay;
