"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Physics3DBodyGameObject = void 0;
var core_1 = require("../core");
var cannon_1 = require("./cannon");
var utils_1 = require("../utils");
var physics3DSystem_1 = require("./physics3DSystem");
var errors_1 = require("./errors");
var Physics3DBodyGameObject = /** @class */ (function (_super) {
    __extends(Physics3DBodyGameObject, _super);
    function Physics3DBodyGameObject(body, shapes) {
        if (shapes === void 0) { shapes = []; }
        var _this = _super.call(this) || this;
        _this.body = body;
        // Maps the shape id to the physics shape itself
        _this.shapesMap = new Map();
        _this.translationForces = new Set();
        _this.collisionListener = null;
        _this.beginContactListener = null;
        _this.endContactListener = null;
        _this.touchingGround = false;
        /**
         * All the bodies that this body is touching that are considered its "ground"
         */
        _this.groundBodies = new Set();
        shapes.forEach(function (shape) { return _this.addShape(shape); });
        // Add to ground body if it is
        _this.addListener("collide", function (e) {
            if (_this.isCollidingWithGround(e)) {
                _this.updateIsTouchingGround();
                _this.groundBodies.add(e.target);
            }
        });
        // Remove ground body
        _this.addListener("endContact", function (e) {
            _this.groundBodies.delete(e.target);
            _this.updateIsTouchingGround();
        });
        return _this;
    }
    /**
     * Adds a shape to the cannon body
     */
    Physics3DBodyGameObject.prototype.addShape = function (shape) {
        var cannonPosition = shape.position
            ? (0, utils_1.toCannonVec3)(shape.position)
            : undefined;
        var cannonQuaternion = shape.quaternion
            ? (0, utils_1.toCannonQuaternion)(shape.quaternion)
            : undefined;
        this.body.addShape(shape.shape, cannonPosition, cannonQuaternion);
        this.shapesMap.set(shape.shape.id, shape);
    };
    /**
     * Get all the shapes that make up this body
     */
    Physics3DBodyGameObject.prototype.getShapes = function () {
        return Array.from(this.shapesMap.values());
    };
    /*
     * Removes shape from body
     */
    Physics3DBodyGameObject.prototype.removeShape = function (shape) {
        this.body.removeShape(shape.shape);
        this.shapesMap.delete(shape.shape.id);
    };
    Physics3DBodyGameObject.prototype.onInit = function (scene) {
        var _this = this;
        // Body collision listener
        this.collisionListener = function (e) {
            var collidedGameObject = scene.realm.systems
                .get(physics3DSystem_1.Physics3DSystem)
                .getCannonBodyGameObject(e.body);
            _this.dispatch("collide", {
                target: collidedGameObject,
                source: _this,
                contact: e.contact,
            });
        };
        this.body.addEventListener("collide", this.collisionListener);
        // Add world listeners
        var world = scene.realm.systems.get(physics3DSystem_1.Physics3DSystem).getCANNONWorld();
        this.beginContactListener = function (e) {
            try {
                var gameObjectA = scene.realm.systems
                    .get(physics3DSystem_1.Physics3DSystem)
                    .getCannonBodyGameObject(e.bodyA);
                var gameObjectB = scene.realm.systems
                    .get(physics3DSystem_1.Physics3DSystem)
                    .getCannonBodyGameObject(e.bodyB);
                if (gameObjectA === _this) {
                    _this.dispatch("beginContact", {
                        target: gameObjectB,
                        source: gameObjectA,
                    });
                }
                else if (gameObjectB === _this) {
                    _this.dispatch("beginContact", {
                        target: gameObjectA,
                        source: gameObjectB,
                    });
                }
            }
            catch (e) {
                // Handle gracefully
                if (e instanceof errors_1.UntrackedCannonBodyError) {
                    return;
                }
                throw e;
            }
        };
        world.addEventListener("beginContact", this.beginContactListener);
        this.endContactListener = function (e) {
            try {
                var gameObjectA = scene.realm.systems
                    .get(physics3DSystem_1.Physics3DSystem)
                    .getCannonBodyGameObject(e.bodyA);
                var gameObjectB = scene.realm.systems
                    .get(physics3DSystem_1.Physics3DSystem)
                    .getCannonBodyGameObject(e.bodyB);
                if (gameObjectA === _this) {
                    _this.dispatch("endContact", {
                        target: gameObjectB,
                        source: gameObjectA,
                    });
                }
                else if (gameObjectB === _this) {
                    _this.dispatch("endContact", {
                        target: gameObjectA,
                        source: gameObjectB,
                    });
                }
            }
            catch (e) {
                // Handle gracefully
                if (e instanceof errors_1.UntrackedCannonBodyError) {
                    return;
                }
                throw e;
            }
        };
        world.addEventListener("endContact", this.endContactListener);
    };
    Physics3DBodyGameObject.prototype.onRemove = function (scene) {
        if (this.collisionListener) {
            this.body.removeEventListener("collide", this.collisionListener);
        }
        // Remove listeners
        var world = scene.realm.systems.get(physics3DSystem_1.Physics3DSystem).getCANNONWorld();
        if (this.beginContactListener) {
            world.removeEventListener("beginContact", this.beginContactListener);
        }
        if (this.endContactListener) {
            world.removeEventListener("endContact", this.endContactListener);
        }
    };
    Physics3DBodyGameObject.prototype.getCANNONBody = function () {
        return this.body;
    };
    Physics3DBodyGameObject.prototype.addTranslationForce = function (f) {
        this.translationForces.add(f);
    };
    Physics3DBodyGameObject.prototype.removeTranslationForce = function (f) {
        this.translationForces.delete(f);
    };
    Physics3DBodyGameObject.prototype.step = function (delta) {
        var e_1, _a;
        // Get total translation force
        var finalTranslationForce = (0, utils_1.vector3Zero)();
        try {
            for (var _b = __values(this.translationForces.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var f = _c.value;
                finalTranslationForce = (0, utils_1.vector3Add)(finalTranslationForce, f.force);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        if (!(0, utils_1.vector3IsZero)(finalTranslationForce)) {
            this.setPosition({
                x: this.getPosition().x + (finalTranslationForce.x * delta) / 1000,
                y: this.getPosition().y + (finalTranslationForce.y * delta) / 1000,
                z: this.getPosition().z + (finalTranslationForce.z * delta) / 1000,
            });
        }
    };
    Physics3DBodyGameObject.prototype.getPosition = function () {
        return (0, utils_1.cannonToVector3)(this.body.position);
    };
    Physics3DBodyGameObject.prototype.setPosition = function (v) {
        this.body.position.set(v.x, v.y, v.z);
    };
    Physics3DBodyGameObject.prototype.getQuaternion = function () {
        return (0, utils_1.cannonToQuat)(this.body.quaternion);
    };
    Physics3DBodyGameObject.prototype.setQuaternion = function (q) {
        this.body.quaternion.set(q.x, q.y, q.z, q.w);
    };
    Physics3DBodyGameObject.prototype.getVelocity = function () {
        return (0, utils_1.cannonToVector3)(this.body.velocity);
    };
    Physics3DBodyGameObject.prototype.setVelocity = function (v) {
        this.body.velocity.set(v.x, v.y, v.z);
    };
    Physics3DBodyGameObject.prototype.addVelocity = function (v) {
        var _a = this.getVelocity(), x = _a.x, y = _a.y, z = _a.z;
        this.body.velocity.set(x + v.x, y + v.y, z + v.z);
    };
    Physics3DBodyGameObject.prototype.getBoundingRadius = function () {
        return this.body.boundingRadius;
    };
    Physics3DBodyGameObject.prototype.getAABB = function () {
        return this.body.aabb;
    };
    /**
     * Get height of body
     */
    Physics3DBodyGameObject.prototype.getHeight = function () {
        return this.body.aabb.upperBound.y - this.body.aabb.lowerBound.y;
    };
    /**
     * Whether the body is currently touching the ground
     */
    Physics3DBodyGameObject.prototype.isTouchingGround = function () {
        return this.touchingGround;
    };
    /**
     * This handles most cases of touching ground safely. The edge case it misses is if a ground is
     * a composite geometry, and it starts of being a valid ground, then as the body
     * moves across the ground it is no longer the ground. eg, a hemisphere
     */
    Physics3DBodyGameObject.prototype.updateIsTouchingGround = function () {
        var newTouchingGroundValue = this.groundBodies.size > 0;
        if (newTouchingGroundValue !== this.touchingGround) {
            this.touchingGround = newTouchingGroundValue;
            this.dispatch("isTouchingGround", this.touchingGround);
        }
    };
    /**
     * Checks if the game object is touching the ground when colliding with something
     * Note: I have no idea how to following code works but it was taken from the example
     * projects on CANNONJS website found here:
     * https://github.com/schteppe/cannon.js/blob/master/examples/js/PointerLockControls.js
     */
    Physics3DBodyGameObject.prototype.isCollidingWithGround = function (e) {
        var contact = e.contact;
        var contactNormal = new cannon_1.CANNON.Vec3();
        var upAxis = new cannon_1.CANNON.Vec3(0, 1, 0);
        // contact.bi and contact.bj are the colliding bodies, and contact.ni is the collision normal.
        // We do not yet know which one is which! Let's check.
        if (contact.bi.id === this.getCANNONBody().id) {
            // bi is the player body, flip the contact normal
            contact.ni.negate(contactNormal);
        }
        else {
            contactNormal.copy(contact.ni); // bi is something else. Keep the normal as it is
        }
        // If contactNormal.dot(upAxis) is between 0 and 1, we know that the contact normal is somewhat in the up direction.
        // Use a "good" threshold value between 0 and 1 here!
        if (contactNormal.dot(upAxis) > 0.5) {
            return true;
        }
        return false;
    };
    return Physics3DBodyGameObject;
}(core_1.ProcessGameObject));
exports.Physics3DBodyGameObject = Physics3DBodyGameObject;
