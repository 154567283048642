import { CreatureAnimation, CreatureData, MMONetSchema } from "mmo-common";
import { GameObject } from "realms-engine";
import { Render3DGameObject, toThreeQuaternion } from "realms-engine-browser";
import { NetworkSlave } from "realms-engine-network-client";
import THREE from "realms-three";
import { buildMixamoBonesMap } from "../utils/mixamo.utils";
import { MMOAssetManager } from "../../config";
import { ThreeAnimationGameObject } from "../utils";
import { CreatureUIGameObject } from "../ui/creatureUI.gameObject";

export class GoblinGameObject
  extends GameObject
  implements NetworkSlave<MMONetSchema["TMap"]["goblin"]>
{
  private render3D: Render3DGameObject;
  private animation: ThreeAnimationGameObject;
  private ui: CreatureUIGameObject;

  constructor(data: CreatureData) {
    super();

    this.render3D = this.addChild(
      new Render3DGameObject(
        // We need to wrap in object 3d since the goblin is scaled
        new THREE.Object3D().add(
          MMOAssetManager.getObject3D("goblin", { cloneDeep: true })
        )
      )
    );

    const mixamoBonesMap = buildMixamoBonesMap(this.render3D.getThreeObject());

    const weapon = MMOAssetManager.getObject3D("sword", { cloneDeep: true });
    weapon.rotateY((1 * Math.PI) / 4);
    mixamoBonesMap.rightHand.add(weapon);

    this.animation = this.render3D.addChild(
      new ThreeAnimationGameObject({
        [CreatureAnimation.Idle]: {
          clip: MMOAssetManager.getObject3D("goblinIdleAnimation", {
            clone: true,
          }).animations[0],
        },
        [CreatureAnimation.Walk]: {
          clip: MMOAssetManager.getObject3D("goblinWalkAnimation", {
            clone: true,
          }).animations[0],
        },
        [CreatureAnimation.Jump]: {
          clip: MMOAssetManager.getObject3D("baseCharacterJumpAnimation", {
            clone: true,
          }).animations[0],
        },
        [CreatureAnimation.Death]: {
          clip: MMOAssetManager.getObject3D("goblinDeathAnimation", {
            clone: true,
          }).animations[0],
          clampWhenFinished: true,
        },
        [CreatureAnimation.NormalAttack1]: {
          clip: MMOAssetManager.getObject3D("goblinTwoHandSlashAnimation", {
            clone: true,
          }).animations[0],
          clampWhenFinished: true,
        },
      })
    );

    this.ui = this.render3D.addChild(
      new CreatureUIGameObject({
        health: data.health,
        maxHealth: data.maxHealth,
        name: data.name,
      })
    );
  }

  onSlaveChanges(changes: Partial<CreatureData>, newState: CreatureData): void {
    if (changes.position !== undefined) {
      this.render3D.setPosition(newState.position);
    }
    if (changes.quaternion !== undefined) {
      this.render3D.setQuaternion(
        toThreeQuaternion(newState.quaternion).multiply(
          new THREE.Quaternion().setFromAxisAngle(
            new THREE.Vector3(0, 1, 0),
            (3 * Math.PI) / 2
          )
        )
      );
    }

    if (changes.health !== undefined) {
      this.ui.setHealth(newState.health);
    }

    if (changes.maxHealth !== undefined) {
      this.ui.setMaxHealth(newState.maxHealth);
    }

    if (changes.animation !== undefined) {
      this.animation.play(newState.animation.name);
    }

    if (changes.name !== undefined) {
      this.ui.setCreatureName(newState.name);
    }
  }
}
