"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.v = void 0;
var models_1 = require("./models");
var validate_1 = require("./validate");
/**
 * All the shorthands for validation
 */
exports.v = {
    /**
     * String type
     */
    string: function (constraints) {
        if (constraints === void 0) { constraints = []; }
        return ({
            type: models_1.ValidationParamType.String,
            constraints: constraints,
        });
    },
    /**
     * Number type
     */
    number: function (constraints) {
        if (constraints === void 0) { constraints = []; }
        return ({
            type: models_1.ValidationParamType.Number,
            constraints: constraints,
        });
    },
    /**
     * Email type
     */
    email: function (constraints) {
        if (constraints === void 0) { constraints = []; }
        return ({
            type: models_1.ValidationParamType.Email,
            constraints: constraints,
        });
    },
    /**
     * Creates an object type
     */
    object: function (fields, constraints) {
        if (constraints === void 0) { constraints = []; }
        return ({
            type: models_1.ValidationParamType.Object,
            fields: fields,
            constraints: constraints,
        });
    },
    /**
     * Array type
     */
    array: function (items, constraints) {
        if (constraints === void 0) { constraints = []; }
        return ({
            type: models_1.ValidationParamType.Array,
            items: items,
            constraints: constraints,
        });
    },
    /**
     * Validates the object
     */
    validate: validate_1.validateObject,
};
