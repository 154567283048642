"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcessSystem = void 0;
var processGameObject_1 = require("./processGameObject");
var system_1 = require("./system");
var ProcessSystem = /** @class */ (function (_super) {
    __extends(ProcessSystem, _super);
    function ProcessSystem() {
        return _super.call(this, [processGameObject_1.ProcessGameObject]) || this;
    }
    ProcessSystem.prototype.step = function (delta, gameObject, realm) {
        _super.prototype.step.call(this, delta, gameObject, realm);
        if (gameObject instanceof processGameObject_1.ProcessGameObject) {
            gameObject.step(delta);
        }
    };
    return ProcessSystem;
}(system_1.System));
exports.ProcessSystem = ProcessSystem;
