"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Realm = void 0;
var gameObjectSystemManager_1 = require("./gameObjectSystemManager");
var groupSystem_1 = require("./groupSystem");
var processSystem_1 = require("./processSystem");
var realmModule_1 = require("./realmModule");
var scene_1 = require("./scene");
var systemManager_1 = require("./systemManager");
var utilsSystem_1 = require("./utilsSystem");
var Realm = /** @class */ (function () {
    function Realm(systemsAndModules) {
        if (systemsAndModules === void 0) { systemsAndModules = []; }
        var _this = this;
        // Time in milliseconds since the realm first started
        this.realmTime = 0;
        var csm = new gameObjectSystemManager_1.GameObjectSystemManager();
        this.systems = new systemManager_1.SystemManager(this, csm);
        this.scene = new scene_1.Scene(csm, this);
        // Systems enabled by default
        this.systems.add(new processSystem_1.ProcessSystem());
        this.systems.add(new utilsSystem_1.UtilsSystem());
        this.systems.add(new groupSystem_1.GroupSystem());
        // Recursively adds all systems in a module
        var addModule = function (module) {
            var e_1, _a, e_2, _b;
            try {
                for (var _c = __values(module.getSystems()), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var system = _d.value;
                    _this.systems.add(system);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_1) throw e_1.error; }
            }
            try {
                for (var _e = __values(module.getSubModules()), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var subModule = _f.value;
                    addModule(subModule);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                }
                finally { if (e_2) throw e_2.error; }
            }
        };
        // Add all systems and modules
        systemsAndModules.forEach(function (m) {
            if (m instanceof realmModule_1.RealmModule) {
                addModule(m);
            }
            else {
                _this.systems.add(m);
            }
        });
    }
    // Get the time of the realm since it first started
    // in milliseconds
    Realm.prototype.getRealmTime = function () {
        return this.realmTime;
    };
    // Runs through one game loop. Delta is in milliseconds
    Realm.prototype.step = function (delta) {
        this.realmTime += delta;
        this.systems.step(delta);
    };
    /*
     * Clean up the realm
     */
    Realm.prototype.clean = function () {
        this.systems.clean();
    };
    return Realm;
}());
exports.Realm = Realm;
