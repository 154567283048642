import { createContext, useContext } from "react";
import { MMOGameClientService } from "./mmo-game-client";
import { DialogueService } from "./dialogue";
import { ChatService } from "./chat";

const mmoGameClient = new MMOGameClientService();
const dialogue = new DialogueService();
const chat = new ChatService(mmoGameClient);

const SERVICES_MAP = {
  mmoGameClient,
  dialogue,
  chat,
} as const;

const ServicesContext = createContext(SERVICES_MAP);

/**
 * A provider for all app services
 */
export const ServiceProvider = ({ children }: { children: any }) => {
  return (
    <ServicesContext.Provider value={SERVICES_MAP}>
      {children}
    </ServicesContext.Provider>
  );
};

/**
 * Returns the requested service
 */
export const useService = <T extends keyof typeof SERVICES_MAP>(service: T) => {
  const serviceMap = useContext(ServicesContext);
  return serviceMap[service];
};
