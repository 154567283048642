"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.idx = void 0;
/*
 * Validation for ids to make sure they are non null | undefined
 */
var idx = function (id) {
    if (id === null) {
        throw new Error("Id cannot be null");
    }
    if (id === undefined) {
        throw new Error("Id cannot be undefined");
    }
    return id;
};
exports.idx = idx;
