import { GameObject, Realm, System } from "realms-engine";
import { TrackingCameraSystem } from "realms-engine-browser";
import { PlayerGameObject } from "../gameObjects/player";
import { ChatMessage } from "mmo-common";

export class PlayerSystem extends System<{
  healthChange: {
    health: number;
    maxHealth: number;
  };
}> {
  private playerHealth: number | null = null;
  private playerMaxHealth: number | null = null;
  private playerGameObject: PlayerGameObject | null = null;
  private foundPlayer = false;

  private allPlayerGameObjects = new Set<PlayerGameObject>();

  constructor(private characterId: number) {
    super([PlayerGameObject]);
  }

  handleChatMessage(chatMessage: ChatMessage) {
    for (const player of Array.from(this.allPlayerGameObjects.values())) {
      if (player.getCharacterId() === chatMessage.sender.characterId) {
        player.getUI().showChatMessage(chatMessage);
        break;
      }
    }
  }

  onTargetGameObjectEnter(gameObject: GameObject<any>): void {
    if (gameObject instanceof PlayerGameObject) {
      this.allPlayerGameObjects.add(gameObject);
    }
  }

  onTargetGameObjectExit(gameObject: GameObject<any>): void {
    if (gameObject instanceof PlayerGameObject) {
      this.allPlayerGameObjects.delete(gameObject);
    }
  }

  /**
   * Gets the current players game object
   */
  getPlayerGameObject() {
    return this.playerGameObject;
  }

  processBeforeStep(delta: number, realm: Realm): void {
    this.foundPlayer = false;
  }

  step(delta: number, gameObject: GameObject, realm: Realm): void {
    if (gameObject instanceof PlayerGameObject) {
      if (gameObject.characterId === this.characterId) {
        this.playerGameObject = gameObject;
        this.foundPlayer = true;

        realm.systems
          .get(TrackingCameraSystem)
          .setTarget(gameObject.getPosition())
          .setLookOffset({ x: 0, y: 10, z: 0 })
          .setCameraOffset({ x: 0, y: 10, z: 0 });

        // Hide ui if it is us
        gameObject.setShouldHideUI(true);

        // Emit health changed event
        const newPlayerHealth = gameObject.getHealth();
        const newPlayerMaxHealth = gameObject.getMaxHealth();
        if (
          this.playerHealth !== newPlayerHealth ||
          this.playerMaxHealth !== newPlayerMaxHealth
        ) {
          this.dispatch("healthChange", {
            health: newPlayerHealth,
            maxHealth: newPlayerMaxHealth,
          });
        }
        this.playerHealth = newPlayerHealth;
        this.playerMaxHealth = newPlayerMaxHealth;
      }
    }
  }

  processAfterStep(delta: number, realm: Realm): void {
    if (!this.foundPlayer) {
      this.playerGameObject = null;
    }
  }
}
