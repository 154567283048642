"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_EDITOR_DARK_THEME = exports.DEFAULT_EDITOR_THEME = void 0;
/**
 * Light theme
 */
exports.DEFAULT_EDITOR_THEME = {
    primaryColor: "gray",
    borderRadiusSize: 8,
    colorMode: "light",
    fontColor: "#000000",
    backgroundColor: "#FFFFFF",
    backgroundMiddleGrayColor: "#DFE1E7",
    backgroundGrayColor: "#ebebeb",
};
/**
 * Dark theme
 */
exports.DEFAULT_EDITOR_DARK_THEME = __assign(__assign({}, exports.DEFAULT_EDITOR_THEME), { fontColor: "#FFFFFF", backgroundColor: "#171717", backgroundMiddleGrayColor: "#363636", backgroundGrayColor: "#222222" });
