"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WindowSize = void 0;
var rxjs_1 = require("rxjs");
var WindowSize = /** @class */ (function () {
    function WindowSize() {
        var _this = this;
        this.widthSubject$ = new rxjs_1.BehaviorSubject(window.innerWidth);
        this.heightSubject$ = new rxjs_1.BehaviorSubject(window.innerHeight);
        /**
         * Window width observable
         */
        this.width$ = this.widthSubject$
            .asObservable()
            .pipe((0, rxjs_1.distinctUntilChanged)());
        /**
         * Window height observable
         */
        this.height$ = this.heightSubject$
            .asObservable()
            .pipe((0, rxjs_1.distinctUntilChanged)());
        this.resizeListener = function () {
            _this.widthSubject$.next(window.innerWidth);
            _this.heightSubject$.next(window.innerHeight);
        };
    }
    /**
     * Subscribes to window events
     */
    WindowSize.prototype.subscribe = function () {
        // Update size on subscribe
        this.widthSubject$.next(window.innerWidth);
        this.heightSubject$.next(window.innerHeight);
        // Add listeners
        window.addEventListener("resize", this.resizeListener);
        window.addEventListener("orientationchange", this.resizeListener);
        window.addEventListener("fullscreenchange", this.resizeListener);
        window.addEventListener("mozfullscreenchange", this.resizeListener);
        window.addEventListener("webkitfullscreenchange", this.resizeListener);
        if (screen.orientation) {
            screen.orientation.addEventListener("change", this.resizeListener);
        }
    };
    /**
     * Unsubscribes from window events
     */
    WindowSize.prototype.unsubscribe = function () {
        window.removeEventListener("resize", this.resizeListener);
        window.removeEventListener("orientationchange", this.resizeListener);
        window.removeEventListener("fullscreenchange", this.resizeListener);
        window.removeEventListener("mozfullscreenchange", this.resizeListener);
        window.removeEventListener("webkitfullscreenchange", this.resizeListener);
        if (screen.orientation) {
            screen.orientation.removeEventListener("change", this.resizeListener);
        }
    };
    return WindowSize;
}());
exports.WindowSize = WindowSize;
