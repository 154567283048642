"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KEY_DELIMITER = exports.EditorHotkeyCommand = void 0;
var EditorHotkeyCommand;
(function (EditorHotkeyCommand) {
    EditorHotkeyCommand["CloseCurrentWindow"] = "closeCurrentWindow";
    EditorHotkeyCommand["CycleToLeftWindow"] = "cycleToLeftWindow";
    EditorHotkeyCommand["CycleToRightWindow"] = "cycleToRightWindow";
    EditorHotkeyCommand["Undo"] = "undo";
    EditorHotkeyCommand["Redo"] = "redo";
    EditorHotkeyCommand["ToggleShowPrimarySidebar"] = "toggleShowPrimarySidebar";
    EditorHotkeyCommand["Search"] = "search";
})(EditorHotkeyCommand = exports.EditorHotkeyCommand || (exports.EditorHotkeyCommand = {}));
/**
 * This is 9999 IQ move
 */
exports.KEY_DELIMITER = "🔥";
