import { useEffect, useState } from "react";
export function useSubscribeToObservable(observable$) {
    var _a = useState(), state = _a[0], setState = _a[1];
    useEffect(function () {
        var subscription = observable$.subscribe(function (next) {
            setState(next);
        });
        return function () {
            subscription.unsubscribe();
        };
    }, []);
    return state;
}
